<template>
    <div class="inline-thread">
        <div class="thread-header">
            <sh-filled-button
                class="close-thread-button"
                borderRadius=".2"
                padding=".2rem 2"
                @click="$emit('hideThread')"
            >
                {{ $t('component.inline-thread.hide-thread')}}
            </sh-filled-button>
        </div>
        <inline-post
            v-for="post in posts"
            :class="getPostClass"
            :key="post.url"
            :ref="post.url"
            :post="post"
            :isHighlighted="post.url == highlightedPost"
        />
    </div>
</template>


<script>
import InlinePost from "@/components/inline-post/inline-post.vue"
import ShFilledButton from "@/components/common/sh-filled-button.vue";

    export default {
        name: "post-wrapper",
        components: {
            "inline-post": InlinePost,
            "sh-filled-button": ShFilledButton
        },
        props: {
            posts: {
                type: Object,
                required: false,
            },
            highlightedPost: {
                type: String,
                required: false
            }
        },
        data() {
        },
        computed: {

        },
        methods: {
        },
        mounted() {
            this.$refs[this.highlightedPost][0].$el.scrollIntoView({block: "center", behavior: "smooth"});
        }
    }
</script>

<style lang="scss">
.inline-thread {
    border-radius: 0.25rem;
    display: grid;
    justify-items: center;
    .thread-header {
        width: 100%;
        padding-bottom: 0.5rem;
        .close-thread-button {
            margin-left: auto;
            width: max-content;
            
        }
    }
}
</style>