<template>
    <div class="post-wrapper" :class="getClass">
        <div class="internal-wrapper" :class="getClass">
                <div 
                    v-if="displayMutedPost && getPost.isMuted" 
                    class="message-wrapper"
                    
                >
                <span 
                    v-if="getPost.muteReason"
                > {{  $t('component.muted-post.mute-reason', [getPost.muteReason]) }}</span>
                <span 
                    v-else
                > {{  $t('component.muted-post.muted') }}</span>
                    <sh-icon-button
                        small
                        noBackground
                        class="toggle-mute "
                        icon="icon-eye-blocked" 
                        @click="displayMutedPost = false" 
                    />
                </div>
                <div 
                    v-if="displaySpoileredPost && getPost.isSpoilered" 
                    class="message-wrapper"
                    
                >
                <span 
                    v-if="getPost.title"
                > {{  $t('component.spoilered-post.spoiler-reason', [getPost.title]) }}</span>
                <span 
                    v-else
                > {{  $t('component.spoilered-post.spoilered') }}</span>
                    <sh-icon-button
                        small
                        noBackground
                        class="toggle-mute"
                        icon="icon-eye-blocked" 
                        @click="displaySpoileredPost = false" 
                    />
                </div>
                <muted-post
                    v-if="mutedPost"
                    :muteReason="getPost.muteReason"
                    @showMutedPost="displayMutedPost = true"
                />
                <spoilered-post
                    v-else-if="spoileredPost"
                    :spoilerReason="getPost.title"
                    @showSpoileredPost="displaySpoileredPost = true"
                />
                <inline-thread
                    v-else-if="inlinePost"
                    @hideThread="displayThread = false"
                    :posts="localThread"
                    :highlightedPost="getPost.url"
                />
                <inline-post
                    v-else-if="isThread"
                    :class="getPostClass"
                    :key="post.url"
                    :ref="post.url"
                    :post="post"
                    :isHighlighted="post.url == highlightedPost"
                    @toggleEditing="isEditing = true"
                    @updatePost="(e) => $emit('updatePost',e)"
                    @toggleMute="$emit('toggleMute')"
                    @toggleBookmark="$emit('toggleBookmark')"
                />
                <interacted-post
                    v-else-if="getPost?.isInteraction"
                    :post="getPost"
                    @openThread="openThread"
                    @updatePost="(e) => $emit('updatePost',e)"
                    @toggleMute="$emit('toggleMute')"
                    @toggleBookmark="$emit('toggleBookmark')"
                />
                <form-panel
                    v-else-if="isEditing"
                    :post="getPost"
                    @edited="updatePost"
                    isEditing
                />
                <default-post
                    v-else-if="canDisplayPost"
                    :post="getPost"
                    @openThread="openThread"
                    @toggleEditing="isEditing = true"
                    @updatePost="(e) => $emit('updatePost',e)"
                    @toggleMute="$emit('toggleMute')"
                    @toggleBookmark="$emit('toggleBookmark')"
                />
        </div>
    </div>
</template>

<script>
import SpoileredPost from "@/components/spoilered-post/spoilered-post.vue"
import MutedPost from "@/components/muted-post/muted-post.vue"
import InlineThread from "@/components/inline-thread/inline-thread.vue"
import InlinePost from "@/components/inline-post/inline-post.vue"
import InteractedPost from "@/components/interacted-post/interacted-post.vue"
import FormPanel from "@/components/form-panel/form-panel.vue"
import DefaultPost from "@/components/default-post/default-post.vue"
import ShIconButton from "@/components/common/sh-icon-button.vue";
import { getPost, getThread } from "@/services/api/post-api.service"

    export default {
        name: "post-wrapper",
        components: {
            "spoilered-post": SpoileredPost,
            "muted-post": MutedPost,
            "inline-thread": InlineThread,
            "inline-post": InlinePost,
            "interacted-post": InteractedPost,
            "form-panel": FormPanel,
            "default-post": DefaultPost,
            "sh-icon-button": ShIconButton
        },
        props: {
            topLevel: {
                type: Boolean,
                default: false
            },
            post: {
                type: Object,
                required: false,
            },
            postUrl: {
                type: String,
                required: false
            },
            isThread: {
                type: Boolean,
                default: false
            },
            highlightedPost: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                isEditing: false,
                canDisplayPost: false,
                displayLocalPost: false,
                displayMutedPost: false,
                displaySpoileredPost: false,
                displayThread: false,
                localThread: null,
                localPost: null
            }
        },
        computed: {
            getClass() {
                var wrapperClass = "";
                if (this.displayMutedPost && this.getPost.isMuted) {
                    wrapperClass += " muted-wrapper "
                }
                if (this.topLevel) {
                    wrapperClass += " top-level "
                }
                if (this.isThread) {
                    wrapperClass += " is-thread "
                }
                if (this.displayThread) {
                    wrapperClass += " inline-thread-wrapper "
                }

                return wrapperClass;
            },
            getPost() {
                if (this.displayLocalPost) {
                    return this.localPost; 
                }
                else { return this.post; }
            },
            mutedPost() {
                if (!this.getPost) { return false; }
                return this.displayMutedPost ?  false : this.getPost.isMuted;
            },
            spoileredPost() {
                if (!this.getPost) { return false; }
                return this.displaySpoileredPost ? false : this.getPost.isSpoilered;
            },
            inlinePost() {
                if (this.displayThread) {
                    return true;
                }
                return false;
            }
        },
        methods: {
            updatePost(value) {
                this.isEditing = false;
                this.$emit('updatePost',value)
            },
            async openThread() {
                var threadUrl = this.getPost.threadUrl;
                var thread = this.$store.getters['Thread/getThread'](threadUrl);
                if (thread == null) {
                    // fetch thread and save
                    var posts = await getThread(threadUrl);

                    this.$store.dispatch('Thread/addThread',{ threadUrl: threadUrl, posts: posts })

                    thread = this.$store.getters['Thread/getThread'](threadUrl);
                }
                this.localThread = thread;
                this.displayThread = true;
            }
        },
        async created() {
            if (!this.post) {
                this.localPost = await getPost(this.postUrl);
                if (!this.localPost) { this.$emit('nullPost'); return; }
                this.displayLocalPost = true;
                this.$emit('setReplyPost', this.localPost);
            }
            this.canDisplayPost = true;
        }
    }
</script>

<style lang="scss">
    .post-wrapper {
        border-radius: 0.25rem;
        @media(min-width: 1280px) {
            width: 40rem;
        }
        &.top-level {
            border-bottom-color: var(--postBorder);
            border-bottom-width: thin;
            border-bottom-style: solid;
            background-color: var(--panelHover);
            box-shadow: 0px 9px 6px 2px var(--shadow);
            margin-bottom: 1rem;
        }
        &.muted-wrapper {
            border-color: var(--mutedPostBorder);
            border-style: solid;
            border-width: thin;
            border-radius: 0.25rem;
        }
        &.inline-thread-wrapper {
            border-color: var(--postBorder);
            border-style: solid;
            border-width: thin;
            border-radius: 0.25rem;
            padding: 0;
        }
        &.is-thread {
            background-color: var(--background);
        }
        .internal-wrapper {
            padding: 0.5rem;
            &.is-thread {
                padding: 0 0.5rem;
                display: grid;
                justify-items: center;
            }
            &.muted-wrapper {
                background-color: var(--mutedPostBackground);
            }
        }
        .message-wrapper { 
            align-items: center;
            display: flex;
            margin-bottom: 0.5rem;
            .toggle-mute {
                margin-left: auto;
            }
        }
    }
</style>