<template>
    <router-link 
        v-if="user"
        class="user-mention"
        :to="{name: 'user', params: { id: this.user.url }}"
    >
        <sh-profile-picture
            width=2
            rounded
            v-if="enableProfilePictureMentions"
            :src="user.url"
            :filename="user.profilePicture"
        />
        <span>@{{ user.handle }}</span>
    </router-link>
</template>
<script>
import ProfilePicture from '@/components/common/sh-profile-picture.vue'
import { mapGetters } from 'vuex'
export default {
    name: "user-mention",
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    components: {
        "sh-profile-picture" : ProfilePicture
    },
    computed: {
        ...mapGetters({
            getSettings: 'Settings/getSettings'
        }),
        enableProfilePictureMentions() {
            return this.getSettings.enableProfilePictureMentions
        }
    },
}
</script>
<style scoped lang="scss">
.user-mention {
    display:inline-flex;
    column-gap:0.1rem;
    align-items: flex-end;
}
</style>