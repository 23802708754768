<script>
import { h } from 'vue'
import { parseMessage } from '@/services/textParser.service';
    export default {
        name: "post-title",
        props: {
            body: {
                type: String,
                required: true
            },
            spanClass: {
                type: String,
                required: true
            },
            isExternal: {
                type: Boolean,
                default: false
            },
            externalTags: {
                type: Object,
                required: false
            }
        },
        render() {
            return[
                h('span',{ class: this.spanClass },parseMessage(this.body,{
                    postType: '',
                    metadata: null,
                    mentionedUsers: null,
                    external: this.isExternal,
                        tags: this.externalTags
                })),
            ]
        }
    }
</script>
<style scoped>
.title {
    display: block;
    font-style: italic;
    border-bottom: solid;
    border-bottom-width: thin;
}
</style>