<template>
    <div class="sh-single-line-text-input">
        <input 
            :type="type" 
            :placeholder="placeholder"
            :disabled="disabled"
            :autocomplete="autocomplete"
            v-model="value"
        >
    </div>
</template>

<script>
    export default {
        name: "sh-single-line-text-input",
        props: {
            placeholder: {
                type: String,
                required: false
            },
            modelValue: {
                type: String,
                required: false
            },
            autocomplete: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            password: {
                type: Boolean,
                default: false
            }
        },
        emits: ['update:modelValue'],
        computed: {
            type() {
                if(this.password) {
                    return 'password'
                } else {
                    return 'text'
                }
            },
            value: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            }
        }
    }
</script>

<style lang="scss">
.sh-single-line-text-input {
    background-color: var(--inputBackground);
    border-radius:.25rem;
    border-style:none;
    color: var(--text);
    padding: 0.25rem 0.5rem; 
    box-shadow: inset 0px 2px 0.5px 2px var(--shadow);
    input {
        appearance: none;
        color: var(--text);
        background-color: var(--inputBackground);
        padding-block: 0;
        padding-inline: 0;
        border: none;
        width: 100%;
    }
    &:focus-visible {
        outline-style: solid;
        outline-color: var(--inputOutline);
    }
}
</style>