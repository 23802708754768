import store from "@/store";
import { getNotifications, getNotificationsSince } from "./api/notification-api.service";

async function fetchNotifications(initialFetch = true) {
    var notifications;

    if (initialFetch) {
        notifications = await getNotifications();
    } else {
        var id = store.getters['Notifications/getLastFetchedId'];
        notifications = await getNotificationsSince(id);
    }

    if (notifications == null) {
        return;
    }
    if (notifications.length > 0) {
        store.dispatch('Notifications/addNotifications', {
            notifications: notifications,
            newNotifications: true,
            initialFetch: initialFetch
        });
    }
}

function startFetchingInterval() {
    var existingInterval = store.getters['Notifications/getInterval'];
    if (existingInterval) {
        return;
    }

    fetchNotifications(true);
    
    var interval = setInterval(
        fetchNotifications, 5000, false
    )

    store.dispatch('Notifications/setInterval', interval);
}

function clearFetchingInterval() {
    var existingInterval = store.getters['Notifications/getInterval'];

    if (existingInterval) {
        clearInterval(existingInterval);
        store.dispatch('Notifications/setInterval', null);
    }
}

export {
    startFetchingInterval,
    clearFetchingInterval
}