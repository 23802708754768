<template>
    <div class="sh-filled-button" :class="buttonClass" :style="getStyle">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "sh-filled-button",
        props: {
            small: {
                type: Boolean,
                default: false
            },
            image: {
                type: String,
                default: null
            },
            noBackground: {
                type: Boolean,
                default: false
            },
            noHover: {
                type: Boolean,
                default: false
            },
            borderRadius: {
                type: Number,
                default: .25
            },
            padding: {
                type: Number,
                default: 0.2
            },
            customSize: {
                type: Number,
                default: null
            },
        },
        computed: {
            buttonClass() {
                var returnedClass = '';

                if (this.inverted) {
                    returnedClass += 'inverted '
                } else {
                    returnedClass += 'uninverted '
                }

                if (this.small) {
                    returnedClass += ' small '
                } else {
                    returnedClass += ' large '
                }


                if (this.noBackground) {
                    returnedClass += ' no-background '
                } else {
                    returnedClass += ' background '
                }

                if (this.fitContent) {
                    returnedClass += ' fit-content '
                }

                if (this.noHover) {
                    returnedClass += ' no-hover '
                }

                return returnedClass;
            },
            getStyle() {
                var styleString = ""

                if (this.customSize) {
                    styleString += `width: ${this.customSize}rem; height: ${this.customSize}rem;`
                }

                if (this.image) {
                    styleString += `background-image:url(${this.image});`
                }

                styleString += `padding:${this.padding}rem;`

                styleString += `border-radius: ${this.borderRadius}rem;`

                return styleString
            }
        }
    }
</script>

<style lang="scss">
    .sh-filled-button {
        width: max-content;
        font-weight: bolder;
        user-select: none;
        align-self: center;
        background-repeat: no-repeat;
        background-position: center;
        color: var(--buttonText);

        &.large {
            padding: 0.2rem 2rem;
        }
        &.small {
            width: 1.5rem;
            border-radius: 0.2rem;
            height: 1.5rem;
        }

        &.inverted {
            outline-style: solid;
            outline-color: var(--button);
            &:hover {
                outline-color: var(--buttonHover);
            }
        }
        &.uninverted {
            &.background {
                background-color: var(--button);
                box-shadow: inset 0 0px 1px 0px black;
            }
            &:hover {
                background-color: var(--buttonHover);
            }
        }

        &.no-hover {
            &:hover {
                background-color: transparent;
            }
        }

        &.fit-content {
            width: fit-content;
        }

        &:hover {
            cursor: pointer;
        }
}

</style>