<template>
    <div class="sh-multi-line-text-input"  :style="getStyle"> 
        <textarea 
            :placeholder="placeholder"
            :disabled="disabled"
            v-model="value"
        >
        </textarea>
    </div>
</template>

<script>
    export default {
        name: "sh-multi-line-text-input",
        props: {
            placeholder: {
                type: String,
                required: false
            },
            modelValue: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            minHeight: {
                type: Number,
                default: 5
            },
            maxHeight: {
                type: Number,
                default: 20
            }
        },
        emits: ['update:modelValue'],
        computed: {
            getStyle() {
                return `min-height: ${this.minHeight}rem; max-height: ${this.maxHeight};`
            },
            value: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            }
        }
    }
</script>

<style lang="scss">
.sh-multi-line-text-input {
    background-color: var(--inputBackground);
    border-radius:.25rem;
    border-style:none;
    padding: 0.5rem 0.4rem;
    box-shadow: inset 0px 1px 1px 2px var(--shadow);
    display: grid;
    align-items: center;
    justify-items: center;
    &:focus-visible {
        outline-style: solid;
        outline-color: var(--inputOutline);
    }

    textarea { 
        font-family: auto;
        min-height: 100%;
        margin-top: 0.1rem;
        width: 100%;
        resize: vertical;
        appearance: none;
        color: var(--text);
        background-color: var(--inputBackground);
        border: none;
    }
}
</style>