import { validateToken } from "@/services/api/user-api.service";
import { startFetchingInterval as startNotificationFetch, clearFetchingInterval as clearNotificationFetch } from "@/services/notification-handler.service";
import { startFetchingInterval as startChatFetch, clearFetchingInterval as clearChatFetch } from "@/services/chat-handler.service";

const defaultState = {
    token: localStorage.getItem('token') || null,
    refreshToken: localStorage.getItem('refresh') || null,
    permissions: {},
}

const _getters = {
    getToken(state) { return state.token; },
    getPermissions(state) { return state.permissions ? Object.keys(state.permissions) : null; },
    getPermission: (state) => (permission) => { return state.permissions ? Object.keys(state.permissions).some((c) => c == permission) : null; },
}

const _mutations = {
}

const _actions = {
    async loadTokens({ state, dispatch }) {
        if (localStorage.getItem("token")) {
            state.token = localStorage.getItem("token")
            state.refreshToken = localStorage.getItem("refreshToken");
            
            if (validateToken()) {
                dispatch("setPermissions");
                startNotificationFetch();
                startChatFetch();
                await dispatch("User/fetchUser",'',{ root: true});
            } else {
                dispatch("clearTokens");
            }
        }
    },
    setTokens({ state, dispatch}, tokenDto) {
        state.token = tokenDto.token;
        state.refreshToken = tokenDto.refreshToken;

        localStorage.setItem("token",state.token);
        localStorage.setItem("refreshToken",state.refreshToken);

        startNotificationFetch();
        startChatFetch();

        dispatch("setPermissions");
        dispatch("User/fetchUser",'',{ root: true});
    },
    clearTokens({ state, dispatch }) {
        state.token = null;
        state.refreshToken = null;
        state.permissions = null;

        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");

        clearNotificationFetch();
        clearChatFetch();

        dispatch('User/clearUser','',{ root: true});
        dispatch('Notifications/clearNotifications','',{ root: true});
        dispatch('Chats/clearChats','',{ root: true});
    },
    setPermissions( {state }) {
        state.permissions = JSON.parse(window.atob(state.token.split('.')[1]));

        // not needed
        delete state.permissions.aud;
        delete state.permissions.exp;
        delete state.permissions.id;
        delete state.permissions.sub;
        delete state.permissions.iss;
    },
}

const authentication = {
    namespaced: true,
    state: defaultState,
    getters: _getters,
    mutations: _mutations,
    actions: _actions
}

export default authentication;