<template>
    <div class="lds-ring float-right"><div></div><div></div><div></div><div></div></div>
</template>
<script>
export default {
    name: "sh-loading"
}
</script>
<style scoped>


/* Loader */
.lds-ring {
  display: inline-block;
  position: relative;
width: 2rem;
height: 2rem;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
width: 2rem;
height: 2rem;
  margin: 8px;
    border: 4px solid var(--text);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--text) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>