<template>
    <sh-modal-panel class="form-modal" :show="show" @close="$emit('close')" useCustomPanel panelClass="form-modal-wrapper">
            <sh-icon-button
                class="close-button"
                small 
                icon="icon-cross"
                @click="$emit('close')"
            />
        <form-panel class="form-wrapper">
        </form-panel>
    </sh-modal-panel>
</template>
<script>
import ShModalPanel from "@/components/common/sh-modal-panel.vue"
import ShIconButton from '@/components/common/sh-icon-button.vue';
import FormPanel from "@/components/form-panel/form-panel.vue"
    export default {
        name: "form-modal",
        components: {
            "sh-icon-button": ShIconButton,
            "sh-modal-panel": ShModalPanel,
            "form-panel": FormPanel,
        },
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
    }
</script>
<style lang="scss">
.form-modal {
    .form-modal-wrapper {
        width: 90%;
        background-color: var(--panel);
        .close-button {
            position: absolute;
            top: 2%;
            right: 2%;
            background-color: var(--panelTransparent);
        }
        .form-wrapper {
            margin-top: 3rem;
        }
    }
}
</style>