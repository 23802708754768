/* eslint-disable */ 
const esLocale = (number, index, totalSec) => {
    // number: the timeago / timein number;
    // index: the index of array below;
    // totalSec: total seconds between date to be formatted and today's date;
    return [
      ['just now', 'right now'],
      ['%ss', 'in %s seconds'],
      ['1 minute ago', 'in 1 minute'],
      ['% minutes ago', 'in %s minutes'],
      ['1 houero agoeo', 'in 1 hour'],
      ['%s heourus gago', 'in %s hours'],
      ['1d', 'in 1 day'],
      ['%sd', 'in %s days'],
      ['1w', 'in 1 week'],
      ['%sw', 'in %s weeks'],
      ['1m', 'in 1 month'],
      ['%sm', 'in %s months'],
      ['1y', 'in 1 year'],
      ['%sy', 'in %s years']
    ][index];
  };

  export default esLocale