<template>
    <div class="sh-user-result" :class="selectedItem == user.url ? 'selected' : ''" @click="$emit('clicked')">
        <sh-profile-picture
            :src="user.url"
            :filename="user.profilePicture"
            width=2
            rounded
        />
        <span> {{  user.handle }}</span>
    </div>
</template>
<script>
import ShProfilePicture from './sh-profile-picture.vue';
    export default {
        name: "sh-user-result",
        components: {
            "sh-profile-picture": ShProfilePicture
        },
        props: {
            user: {
                type: Object,
                required: false
            },
            selectedItem: {
                type: String,
                required: false
            }
        }
    }
</script>

<style lang="scss" scoped>
.sh-user-result {
    width: 100%;
    display: flex;
    column-gap: 1rem;
    background-color: var(--panelHover);
    align-items: center;
    &:hover {
        background-color: var(--panelTransparent);
        cursor: pointer;
    }

    &.selected {
        background-color: var(--panelTransparent);
    }
}
</style>