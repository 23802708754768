<template>
    <div class="mobile-buttons"> 
        <div class="left" v-if="isUser">
            <sh-icon-button
                small
                noBackground
                fontSize="xx-large"
                @click="$emit('showForm')"
                icon="icon-pencil"
            />
            <router-link 
                :to="{ name: 'notification' }"
            >
            <sh-icon-button
                small
                noBackground
                fontSize="xx-large"
                @click="$emit('showForm')"
                icon="icon-bell"
            />
            </router-link>
        </div>
        <div class="right">
            <sh-icon-button
                v-if="isUser"
                small
                noBackground
                fontSize="xx-large"
                @click="logout"
                icon="icon-exit"
            />
            <router-link 
                v-else
                :to="{ name: 'login' }"
            >
                <sh-icon-button
                    small
                    noBackground
                    fontSize="xx-large"
                    icon="icon-enter"
                />
            </router-link>
        </div> 
    </div>
</template>

<script>
import ShIconButton from '../common/sh-icon-button.vue'
import favicon from "@/assets/logo.png"
import { logoutUser } from '@/services/api/user-api.service'
import { mapGetters, mapActions } from 'vuex'
    export default {
        name: "mobile-navigation-bar",
        components: {
            'sh-icon-button': ShIconButton
        },  
        data() {
            return {
                isFixed: false,
                isSearching: false,
                searchQuery: null
            }
        },
        computed: {
            ...mapGetters({
                isUser: 'User/getUrl',
                getPermission: 'Authentication/getPermission'
            }),
            getClass() {
                if (this.isFixed) {
                    return 'fixed'
                } else {
                    return ''
                }
            },
            getFavicon() {
                return favicon;
            }
        },
        methods:{
            ...mapActions({
                clearTokens: 'Authentication/clearTokens'
            }),
            logout() {
                logoutUser(),
                this.clearTokens()
            },
            handleKeyPress(e) {
                if (e.key == "Enter") {
                    if (this.searchQuery != null) {
                        this.$router.push({
                            name: 'search',
                            query: {
                                q: this.searchQuery
                            }
                        });
                    }
                    this.toggleSearch();
                }
            },
            toggleSearch() {
                this.isSearching = !this.isSearching;

                if (this.isSearching) {
                    window.addEventListener('keydown', this.handleKeyPress);
                } else {
                    window.removeEventListener('keydown', this.handleKeyPress);
                    this.searchQuery = null;
                }
            }
        }
    }
</script>

<style lang="scss">
.mobile-buttons {
    position: absolute;
    display: flex;
    width: 87%;
    .right, .left {
        display: flex;
        column-gap: .75rem;
    
    }
    .left {
        margin-right: auto;
    }
    .right {
        margin-left: auto;
    }
}
</style>