/* eslint-disable */
import store from "@/store";
import DefaultTheme from '@/themes/defaultTheme.json'
import { simpleThemeRender } from "./theme-handler.service";
const checkThemeVersion = (theme) => {
    if(theme == null)
        return
    if(theme.version == null)
        updateTheme(theme,DefaultTheme)
    if(parseFloat(theme.version) >= parseFloat(DefaultTheme.version)) 
        return

    updateTheme(theme,DefaultTheme)
}
const checkSettingsVersion = (settings) => {
    if(settings == null) 
        return
    if(settings.version == null)
        updateSettings(settings,store.getters['Settings/getDefaultSettings'])
    if(parseFloat(settings.version) >= parseFloat(store.getters['Settings/getDefaultSettings'].version)) {
        store.commit('Instance/setSettings',settings)
        return
    }

    updateSettings(settings,store.getters['Settings/getDefaultSettings'])
}

const updateSettings = (userSettings, systemSettings) => {
    userSettings.version = systemSettings.version
    
    userSettings = updateKeys(userSettings,systemSettings)
    
    store.commit('Instance/setSettings',userSettings)
    store.commit('Instance/saveSettings')
}

const updateTheme = (userTheme,systemTheme) => {
    userTheme.version = systemTheme.version

    userTheme = updateKeys(userTheme,systemTheme)

    store.commit('Theme/setTheme',userTheme)
    store.dispatch('Theme/saveTheme')
    simpleThemeRender(userTheme);
}

const updateKeys = (_old,_new) => {
    let newKeys = []
    let oldKeys = []
    Object.keys(_new).forEach(function(baseKey) {
        newKeys.push(baseKey)
    })
    Object.keys(_old).forEach(function(key) {
        oldKeys.push(key)
    })

    let missingKeys = newKeys.filter((key) => !oldKeys.includes(key))
    for(let i = 0; i < missingKeys.length; i++) {
        _old[missingKeys[i]] = _new[missingKeys[i]]
    }
    
    return _old
}

export {
    checkSettingsVersion,
    checkThemeVersion
}