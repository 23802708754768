<script>
import parseMessage from '@/services/text/bbcode.service'
import { h } from 'vue'
export default {
    name: "sh-bbcode",
    props: {
        text: {
            type: String,
            required: true
        },
        tag: {
            type: String,
            required: true
        }
    },
    computed: {
        getClass() {
            switch(this.tag) {
                case "italics":
                    return "italics"
                case "bold":
                    return "bold"
                case "strikethrough":
                    return "strikethrough"
                case "spoiler":
                    return "spoiler"
                default: 
                    return ""
            }
        }
    },
    render() {
        return h('span',{ class: this.getClass },parseMessage(this.text))
        // return <div class="PostBody"> {parseMessage(this.body,this.GetEmojis)} </div>
    }
}
</script>
<style scoped>

.italics {
    font-style: italic;
}
.bold {
    font-weight: bold;

}
.strikethrough {
    text-decoration: line-through;
}
.spoiler {
    background-color: var(--spoiler);
}
.spoiler:hover {
    background: none;
    cursor: pointer;
}
</style>