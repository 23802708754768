<template>
    <div class="emoji-panel">
        <div class="upload-panel">
            <sh-single-line-text-input
                :placeholder="$t('page.admin.emoji-group')"
                v-model="group"
            />
            <input ref="emojiUpload" @change="prepareFiles" type="file" multiple>

            <sh-filled-button > {{ $t('common.upload') }}</sh-filled-button>
        </div>
        <div class="emojis">
                <div
                    class="group"
                    v-for="_group in groups"
                    :key="_group"
                    :id="_group"
                >
                    <span class="title"> {{  _group }}</span>
                    <sh-emoji
                        v-for="emoji in getGroup(_group)"
                        :key="emoji.id"
                        :emoji="emoji"
                        :group="_group"
                        @deleteEmote="deleteEmote"
                        @updateEmote="updateEmote"
                    />
                </div>

        </div>
    </div>
</template>
<script>
import ShFilledButton from "@/components/common/sh-filled-button.vue";
import ShSingleLineTextInput from '@/components/common/sh-single-line-text-input.vue';
import ShEmoji from "@/components/common/sh-emoji.vue";
import { mapGetters } from "vuex";
import { getEmojis, createEmoji } from '@/services/api/emoji-api.service';
    export default {
        name: "emoji-panel",
        components: {
            'sh-filled-button' : ShFilledButton,
            'sh-single-line-text-input': ShSingleLineTextInput,
            'sh-emoji': ShEmoji,
        },
        data() {
            return {
                emojis: [],
                group: null
            }
        },
        computed: {
            ...mapGetters({
                groups: 'Emojis/getGroupNames',
                getGroup: 'Emojis/getGroup',
                emojis: 'Emojis/getAllEmotes',
                getSettings: 'Settings/getSettings'
            })
        },
        methods: {
            clearUpload() {
                this.$refs['emojiUpload'].value = null
            },
            async prepareFiles(e) {
                var files = e.target.files;
                if (files.length <= 0) { return; }
                if (this.group == null || this.group == "") { this.clearUpload(); return; }
                
                for(var i = 0; i < files.length; i++) {
                   await this.uploadEmoji(files[i]);
                }

                this.clearUpload();
            },
            async uploadEmoji(file) {
                var name = file.name.split('.')[0]
                await createEmoji({
                    File: file,
                    Name: name,
                    Group: this.group
                })
            }
        },
        async mounted() {
            this.emojis = await getEmojis()
        }
    }
</script>
<style lang="scss" scoped>
.emoji-panel {
    .upload-panel {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 1rem;
    }
    .emojis {
        margin-top: 1rem;
    }
}
</style>