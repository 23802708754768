import i18n from "@/i18n";
import { getEmojis } from "@/services/api/emoji-api.service";
import { refresh } from "@/services/api/api.service";
import { checkThemeVersion } from "@/services/updater.service";
import { simpleThemeRender, writeBackground } from "@/services/theme-handler.service"
import store from "@/store/index.js"
import { getSettings } from "@/services/api/site-settings-api.service";

async function main() {
    loadSiteSettings();
    wipeOldFrontend();
    loadEmojis();
    await loadSelf();
    await store.dispatch("Authentication/loadTokens");
    loadSettings();
    loadTheme();
}

function wipeOldFrontend() {
    caches.keys().then((names) => {
        for(let e in names) {
            caches.delete(e);
        }
    })
}

async function loadSiteSettings() {
    var settings = await getSettings();
    store.dispatch('SiteSettings/setSettings', settings);
}

function loadTheme() {
    var themeString = localStorage.getItem('theme');
    if (themeString) {
        var theme = JSON.parse(themeString);
        simpleThemeRender(theme);
        store.commit("Theme/setTheme", theme);
        checkThemeVersion(theme);
    } else {
        simpleThemeRender();
    }

    var backgrounds = store.getters['User/getBackgrounds'];
    if (backgrounds) {
        if (backgrounds.length > 0) {
            var index = Math.floor(Math.random() * backgrounds.length);
            var background = backgrounds[index];
            writeBackground(background.url, background.name);
        }
    }
}

function loadSettings() {
    var settings = localStorage.getItem('settings')
    if (settings) {
        store.dispatch("Settings/setSettings", JSON.parse(settings));
        i18n.global.locale = store.getters['Settings/getLanguage'];
    }
}
async function loadEmojis() {
    var emojis = await getEmojis();
    store.dispatch('Emojis/setEmojis', emojis);
}

async function loadSelf() {
    var refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
        refresh();
    }
}

export {
    loadTheme
}

export default main