<template>
    <div class="user-info-panel" v-if="isLoaded">
        <div class="header">
            <sh-profile-picture
                :src="getCorrectUser.url"
                :filename="getCorrectUser.profilePicture"
                width=4.5
                rounding=.5
                rounded
            />
            <div class="info-body">
                <div class="top">
                    <div class="left">
                        <post-title :body="getCorrectUser.username"  :isExternal="getCorrectUser.isExternal" :externalTags="getCorrectUser.tagObjects" spanClass="username"/>
                        <router-link :to="{name: 'user', params: { id: getCorrectUser.url } }"><div class="handle">@{{ getCorrectUser.handle }}</div></router-link>
                    </div>
                    <div class="middle" v-if="isUserPage">
                        <div class="statistic">
                            <div class="statistic-header"> {{ $t('common.following') }}</div>
                            <div class="count">{{ getCorrectUser.statistics.followCount }}</div>
                        </div>
                        <div class="statistic">
                            <div class="statistic-header"> {{ $t('common.followers') }}</div>
                            <div class="count">{{ getCorrectUser.statistics.followerCount }}</div>
                        </div>
                        <div class="statistic">
                            <div class="statistic-header"> {{ $t('common.posts') }}</div>
                            <div class="count">{{ getCorrectUser.statistics.postCount }}</div>
                        </div>
                    </div>
                    <div class="right">
                        <sh-icon-button
                            v-if="!isFloating && getCorrectUser.url == isUser"
                            small
                            noBackground
                            @click="$emit('edit')"
                            icon="icon-edit"
                        />
                        <router-link v-if="isFloating" :to="{name: 'user', params: { id: getCorrectUser.url } }" >
                            <sh-icon-button 
                                small
                                noBackground
                                icon="icon-enlarge2"
                            />
                        </router-link>
                        <sh-icon-button
                            v-if="isFloating"
                            small
                            noBackground
                            @click="$emit('close')"
                            icon="icon-cross"
                        />
                    </div>
                </div>
            <div class="bottom" v-if="getCorrectUser.url != isUser && isUser">
                <sh-filled-button class="button" @click="determineFollowAction">
                    {{ getFollowLabel }}
                </sh-filled-button>
                <sh-filled-button class="button" @click="determineMuteAction">
                    {{ getMuteLabel }}
                </sh-filled-button>
                <sh-filled-button class="button" @click="determineBlockAction">
                    {{ getBlockLabel }}
                </sh-filled-button>
            </div>
            </div>
        </div>
        <post-title v-if="isUserPage" :body="getCorrectUser.description"  :isExternal="getCorrectUser.isExternal" :externalTags="getCorrectUser.tagObjects" spanClass="body"/>
    </div>
</template>

<script>
import ShProfilePicture from "@/components/common/sh-profile-picture.vue";
import ShIconButton from "@/components/common/sh-icon-button.vue";
import ShFilledButton from "@/components/common/sh-filled-button.vue";
import PostTitle from "@/components/post-title/post-title.vue";

import { mapGetters } from "vuex";
import { 
    getSelf, 
    getRelationship, 
    followUser,
    unfollowUser,
    blockUser,
    unblockUser,
    muteUser,
    unmuteUser 
} from "@/services/api/user-api.service";
    export default {
        name: "user-info-panel",
        components: {
            "sh-profile-picture": ShProfilePicture,
            "sh-icon-button": ShIconButton,
            "sh-filled-button": ShFilledButton,
            "post-title": PostTitle
        },
        props: {
            user: {
                type: Object,
                required: false
            },
            url: {
                type: String,
                required: false
            },
            isFloating: {
                type: Boolean,
                default: false
            },
            isUserPage: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                localUser: null,
                isLoaded: false,
                relation: null
            }
        },
        computed: {
            ...mapGetters({
                isUser: "User/getUrl"
            }),
            getCorrectUser() {
                if (this.localUser) {
                    return this.localUser;
                } else {
                    return this.user;
                }
            },
            isFollowing() {
                if (this.relation == "following" || this.relation == "superfollowing") {
                    return true;
                } else {
                    return false;
                }
            },
            isMuted() {
                if (this.relation == "muted") {
                    return true;
                } else {
                    return false;
                }
            },
            isBlocked() {
                if (this.relation == "blocking") {
                    return true;
                } else {
                    return false;
                }
            },
            getFollowLabel() {
                if (this.isFollowing) {
                    return this.$t('common.unfollow');
                } else {
                    return this.$t('common.follow');
                }
            },
            getMuteLabel() {
                if (this.isMuted) {
                    return this.$t('common.unmute');
                } else {
                    return this.$t('common.mute');
                }
            },
            getBlockLabel() {
                if (this.isBlocked) {
                    return this.$t('common.unblock');
                } else {
                    return this.$t('common.block');
                }
            },
        },
        methods: {
            async determineFollowAction() {
                if (this.isFollowing) {
                    await unfollowUser(this.user.url);
                } else {
                    await followUser(this.user.url);
                }
                this.getRelation();
            },
            async determineMuteAction() {
                if (this.isMuted) {
                    await unmuteUser(this.user.url);
                } else {
                    await muteUser(this.user.url);
                }
                this.getRelation();
            },
            async determineBlockAction() {
                if (this.isBlocked) {
                    await unblockUser(this.user.url);
                } else {
                    await blockUser(this.user.url);
                }
                this.getRelation();
            },
            async getRelation() {
                if (this.user.url == this.isUser) { return; }
                this.relation = await getRelationship(this.user.url);
                this.relation = this.relation.replaceAll('"','').toLowerCase();
            }
        },
        async created() {
            if (this.user == null) {
                this.localUser = await getSelf();
            } else {

                await this.getRelation();
            }
            this.isLoaded = true;
        }
    }
</script>

<style lang="scss">
.user-info-panel {
    .header {
        display: flex;
        column-gap: 1rem;
        min-width: 15rem;
        .info-body {
            display: grid;
            width: 100%;
            .top {
                display: flex;
                width: 100%;
                .left {
                    text-align: left;
                    .username, .handle {
                        color: var(--link);
                    }
                }
                .middle {
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                    column-gap: 1rem;
                    .statistic {
                        .statistic-header {
                            font-weight: bold;
                        }
                    }
                }
                .right {
                    column-gap: .1rem;
                    margin-left: auto;
                    display: flex;
                }
            }
            .bottom {
                display: flex;
                column-gap: 1rem;
            }
        }
    }
}
</style>