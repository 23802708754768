<template>
    <div class="chat-view">
        <new-chat-modal
            :show="showNewChat"
            @close="showNewChat = false"
        />
        <div class="chat-header">
            <div class="title">
                {{ $t('page.chat.title') }}
            </div>
            <sh-filled-button class="create-chat-btn" @click="showNewChat = true">
                {{ $t('page.chat.new-chat') }}
            </sh-filled-button>
        </div>
        <div class="chat-list">
            <chat-item
                v-for="chat in getChats"
                :key="chat.id"
                :chat="chat"
            />
            <div class="loser" v-if="getChats.length <= 0">
                {{ $t('page.chat.empty') }}
            </div>
        </div>
    </div>
</template>

<script>
import ChatItem from "@/components/chat-item/chat-item.vue"
import ShFilledButton from "@/components/common/sh-filled-button.vue"
import NewChatModal from "@/components/new-chat-modal/new-chat-modal.vue"
import { mapGetters } from "vuex";
    export default {
        name: "ChatView",
        components: {
            "chat-item": ChatItem,
            "sh-filled-button": ShFilledButton,
            "new-chat-modal": NewChatModal,
        },
        data() {
            return {
                showNewChat: false
            }
        },
        computed: {
            ...mapGetters({
                getChats: 'Chats/getChats'
            })
        }

    }
</script>
<style scoped lang="scss">
.chat-view {
    width: 40rem;
    background-color: var(--panelHover);
    border-radius: 0.25rem;
    box-shadow: 0px 1px 3px 3px var(--shadow);
    .chat-header {
        display: flex;
        padding: 0.5rem;
        align-items: center;
        column-gap: 2rem;
        justify-content: center;
        border-color: var(--inputOutline);
        border-style: solid;
        border-width: thin;
        border-radius: 0.25rem;
        margin-bottom: .5rem;
        .title {
            font-size: larger;
            font-weight: bolder;
        }
        .create-chat-btn {
            margin-left: auto;
        }
    }
}
</style>