import { createStore } from "vuex";

import user from "@/modules/user";
import authentication from "@/modules/authentication";
import thread from "@/modules/thread";
import timeline from "@/modules/timeline";
import settings from "@/modules/settings";
import emojis from "@/modules/emoji";
import alert from "@/modules/alert";
import notification from "@/modules/notification";
import theme from "@/modules/theme"
import chats from "@/modules/chats"
import favicon from "@/modules/favicon";
import upload from "@/modules/upload";
import siteSettings from "@/modules/site-settings";

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        Alert: alert,
        Authentication: authentication,
        Chats: chats,
        User: user,
        Thread: thread,
        Timeline: timeline,
        Settings: settings,
        Emojis: emojis,
        Notifications: notification,
        Theme: theme,
        Favicon: favicon,
        Upload: upload,
        SiteSettings: siteSettings
    },
});
