<template>
    <div class="sh-floating-search">
        <div
            v-if="type == 'user' && isLoaded"
            class="search-results"
            :class="isInverted ? 'invert' : ''"
        >
            <sh-user-result
                v-for="user in results"
                :key="user.url"
                :user="user"
                :selectedItem="selectedItem"
                @clicked="$emit('clicked',user.handle)"
                @click="$emit('clicked',user.handle)"
            />
        </div>
        <div
            v-else-if="type == 'emoji' && isLoaded"
            class="search-results"
            :class="isInverted ? 'invert' : ''"
        >
            <sh-emoji-result
                v-for="emoji in results"
                :key="emoji.name"
                :emoji="emoji"
                :selectedItem="selectedItem"
                @clicked="$emit('clicked',emoji.name)"
                @click="$emit('clicked',emoji.name)"
            />
        </div>
    </div>
</template>
<script>
import ShUserResult from './sh-user-result.vue';
import ShEmojiResult from './sh-emoji-result.vue';
import { SearchUser } from '@/services/api/search-api.service';
import { mapGetters } from 'vuex';
    export default {
        name: "sh-floating-search",
        components: {
            "sh-user-result": ShUserResult,
            "sh-emoji-result": ShEmojiResult
        },
        props: {
            searchQuery: {
                type: String,
                required: false
            },
            type: {
                type: String,
                default: 'user'
            },
            isInverted: {
                type: Boolean,
                default: false
            },
            getObject: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isLoaded: false,
                results: [],
                selectedItem: null,
                index: 0
            }
        },
        computed: {
            ...mapGetters({
                getEmotes: 'Emojis/getAllEmotes'
            }),
            getValue() {
                if (this.type == "user") {
                    if (this.getObject)
                        return this.results[this.index]
                    else
                        return this.results[this.index].handle
                } else {
                    return  this.results[this.index].name
                }
            }
        },
        methods: {
            searchEmotes() {
                const regexQuery = new RegExp(this.searchQuery , 'i');
                const result = this.getEmotes.filter(emote => regexQuery.test(emote.name))
                if (result.length <= 0) { return; }
                this.selectedItem = result[0].name
                this.results = result.splice(0,5)
                this.isLoaded = true;
                window.addEventListener('keydown',this.handleKeyPress)
            },
            async searchUser() {
                this.results = await SearchUser(this.searchQuery)
                this.isLoaded = true;
                if (this.results.length > 0) {
                    this.selectedItem = this.results[0].url
                    window.addEventListener('keydown',this.handleKeyPress)
                }
            },
            disable() {
                window.removeEventListener('keydown',this.handleKeyPress)
                this.isLoaded = false;
                this.index = 0;
            },
            handleKeyPress(e) {
                switch(e.key) {
                    case 'ArrowUp': this.isInverted ? this.advanceSelection() : this.lowerSelection(); return;
                    case 'ArrowDown': this.isInverted ? this.lowerSelection() : this.advanceSelection(); return;
                    case 'Enter': this.$emit('clicked',this.getValue);
                }
            },
            advanceSelection() {
                if (this.index >= this.results.length-1) {
                    this.index = 0;
                } else {
                    this.index++;
                }

                this.setSelection();
            },
            lowerSelection() {
                if (this.index <= 0) {
                    this.index = this.results.length-1;
                } else {
                    this.index--;
                }

                this.setSelection();
            },
            setSelection() {
                if (this.results == null || this.results.length <= 0) { return; }
                if (this.type == 'user') {
                    this.selectedItem = this.results[this.index].url
                } else if (this.type == 'emoji') {
                    this.selectedItem = this.results[this.index].name
                }
            }
        }

    }
</script>

<style lang="scss" scoped>
.sh-floating-search { 
    position: absolute;
    width: 100%;
    .search-results {
        &.invert {
            display: flex;
            flex-direction: column-reverse;

        }
    }
}
</style>