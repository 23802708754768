<template>
    <div class="sh-user-relation">
        <sh-profile-picture
            :src="user.url"
            :filename="user.profilePicture"
            width=2
            rounded
        />
        <span> {{  user.handle }}</span>
        <div class="buttons" v-if="follow">
            <sh-filled-button>{{ $t('common.unfollow') }}</sh-filled-button>
        </div>
        <div class="buttons" v-if="follower && !relation.isActive">
            <sh-icon-button
                small
                noBackground
                icon="icon-plus"
                :title="$t('component.sh-user-relation.accept')"
                @click="$emit('accept')"
            />
            <sh-icon-button
                small
                noBackground
                icon="icon-cross"
                :title="$t('component.sh-user-relation.reject')"
                @click="$emit('deny')"
            />
        </div>
    </div>
</template>
<script>
import ShFilledButton from './sh-filled-button.vue';
import ShIconButton from './sh-icon-button.vue';
import ShProfilePicture from './sh-profile-picture.vue';
    export default {
        name: "sh-user-relation",
        components: {
            "sh-profile-picture": ShProfilePicture,
            "sh-icon-button": ShIconButton,
            "sh-filled-button": ShFilledButton
        },
        props: {
            follow: {
                type: Boolean,
                default: false
            },
            follower: {
                type: Boolean,
                default: false
            },
            relation: {
                type: Object,
                required: false
            },
        },
        computed: {
            user() {
                return this.relation.user;
            }
        }
    }
</script>

<style lang="scss" scoped>
.sh-user-relation {
    width: 100%;
    display: flex;
    column-gap: 1rem;
    background-color: var(--panelHover);
    align-items: center;
    border-radius: 0.25rem;
    .buttons {
        margin-left: auto;
        margin-right: 1rem;
        display: flex;
        column-gap: 0.5rem;
    }
    &:hover {
        background-color: var(--panelTransparent);
        cursor: pointer;
    }

    &.selected {
        background-color: var(--panelTransparent);
    }
}
</style>