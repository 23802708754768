<template>
    <sh-modal-panel>
        <div class="new-chat-modal">
            <sh-single-line-text-input 
                class="search-bar"
                v-model="name"
                :placeholder="$t('page.chat.new-chat-name')"
            /> 
            <div class="search-area">
                <sh-single-line-text-input 
                    class="search-bar"
                    v-model="query"
                    @input="Search"
                    :placeholder="$t('page.chat.members')"
                /> 
                <sh-floating-search
                    ref="searcher"
                    getObject
                    :searchQuery="query"
                    @clicked="addUser"
                />
            </div>
            <sh-filled-button @click="submit" class="btn">
                {{ $t('page.chat.create-chat') }}
            </sh-filled-button>
            <sh-user-result class="dropdown-user-result" v-for="user in displayList" :key="user.url" :user="user"/>
        </div>
    </sh-modal-panel>
</template>
<script>
import ShSingleLineTextInput from '@/components/common/sh-single-line-text-input.vue';
import ShModalPanel from "@/components/common/sh-modal-panel.vue"
import ShUserResult from "@/components/common/sh-user-result.vue"
import ShFloatingSearch from "@/components/common/sh-floating-search.vue"
import ShFilledButton from "@/components/common/sh-filled-button.vue"

import { createChat } from "@/services/api/chat-api.service.js"
    export default {
        name: "new-chat-modal",
        components: {
            "sh-single-line-text-input": ShSingleLineTextInput,
            "sh-modal-panel": ShModalPanel,
            "sh-user-result": ShUserResult,
            "sh-filled-button": ShFilledButton,
            "sh-floating-search": ShFloatingSearch
        },
        data() {
            return {
                query: '',
                name: '',
                users: [],
                displayList: []

            }
        },
        methods: {
            Search() {
                if (this.query.length > 2) {
                    this.$refs["searcher"].searchUser();
                } else {
                    this.$refs["searcher"].disable();

                }
            },
            addUser(e) {
                this.users.push(e.url);
                this.displayList.push(e);
                this.$refs["searcher"].disable();
                this.query = '';
            },
            submit() {
                if (this.users.length <= 0) { return; }
                createChat({
                    name: this.name,
                    users: this.users
                });
                this.$emit('close');
            }
        },
        onUnmounted() {
            this.name = '';
            this.displayList = [];
            this.users = []
        }
    }
</script>
<style lang="scss" scoped>
.new-chat-modal {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    top: 25%;
    width: 50%;
    display: grid;
    justify-content: center;
    .user-search {
        position: relative;
        top: 0 !important;
    }
    .search-area {
        position: relative;
    }
    .btn {
        place-self: center;
    }
}
</style>