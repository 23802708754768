<template>
    <sh-floating-panel class="sh-emoji-picker" :show="show" zIndex="500" instantFade clickable @closed="$emit('closed')">
        <div class="picker-header">
            <div 
                v-for="group in getGroups"
                class="emoji-group" 
                :key="group"
                :id="group"
            >   
                <sh-emoji
                    :emoji="getGroup(group)[0]"
                    @click="scrollToGroup(group)"
                />
            </div>
        </div>
        <div class="picker-body">        
            <sh-single-line-text-input 
                class="search-bar"
                v-model="query"
                @input="search"
                :placeholder="$t('common.search')"
            /> 
            <div 
                v-for="group in getGroups"
                class="emoji-group" 
                :ref="group"
                :key="group"
                :id="group"
            >   
                <span class="group-title">{{ group }}</span>
                <div class="emoji-holder">
                    <sh-emoji
                        class="picker-emote"
                        v-for="emoji in getGroup(group)"
                        :key="emoji.name"
                        :emoji="emoji"
                        @click="$emit('selected',emoji.name)"
                    />
                </div>
            </div>
        </div>
    </sh-floating-panel>
</template>
<script>
import ShFloatingPanel from './sh-floating-panel.vue';
import ShEmoji from './sh-emoji.vue';
import ShSingleLineTextInput from '@/components/common/sh-single-line-text-input.vue';
import { mapGetters } from 'vuex';
    export default {
        name: "sh-emoji-picker",
        props: {
            show: {
                type: Boolean,
                default: false
            }        
        },
        data() {
            return {
                query: null,
                results: null
            }
        },
        components: {
            "sh-floating-panel": ShFloatingPanel,
            "sh-emoji": ShEmoji,
            "sh-single-line-text-input": ShSingleLineTextInput,
        },
        computed: {
            ...mapGetters({
                getAllGroupNames: "Emojis/getGroupNames",
                getFullGroup: "Emojis/getGroup",
                getEmotes: "Emojis/getAllEmotes",
            }),
            getGroups() {
                if (this.results) {
                    return Object.keys(this.results);
                } else {
                    return this.getAllGroupNames
                }
            },
        },
        methods: {
            setResults(e) {
                this.results = e.reduce(function (r,a) {
                    r[a.group] = r[a.group] || []
                    r[a.group].push(a)
                    return r
                },Object.create(null))
            },
            search() {
                if (this.query.length > 2) {
                    const regexQuery = new RegExp(this.query , 'i');
                    const result = this.getEmotes.filter(emote => regexQuery.test(emote.name));
                    if (result.length <= 0) { return; }
                    this.setResults(result);
                } else {
                    this.results = null;
                }
            },
            scrollToGroup(value) {
                this.$refs[value][0].scrollIntoView({block: "center", behavior: "smooth"});
            },
            getGroup(e) {
                if (this.results) {
                    return this.results[e];
                } else {
                    return this.getFullGroup(e);
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.sh-emoji-picker {
    max-width: 100%;
    max-height: 20rem;
    overflow-y: scroll;
    .picker-header {
        display: flex;
        max-width: 100%;
        overflow-x: auto;
    }
    .picker-body {
        .emoji-group {
            display: grid;
            .group-title { 
                text-align: left;
            }
            .emoji-holder {
                display: flex;
                flex-flow: wrap;
                .picker-emote {
                        padding: 0.25rem;
                        border-radius: 0.25rem;
                        display: flex;
                    &:hover {
                        background-color: var(--panelHover);
                        cursor: pointer;
                    }
                }
                
            }
        }
    }
}
</style>