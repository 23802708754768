const settings = {
    language: 'en',
    defaultPostType: 'Public',
    defaultTimeline: 'public',
    automaticallyUpdateTimeline: false,
    automaticallyGetNewPosts: false,
    automaticallyGetOlderPosts: false,
    blockNotificationsFromNonFollowedUsers: false,
    blockNotificationsFromNonInstanceUsers: false,
    blockPushNotifications: false,
    muteBots: false,
    hidePostEmbedLinks: true,
    hideMutedThreads: false,
    hideMutedUsers: false,
    hideFilteredPosts: false,
    hideOwnFilteredPosts: false,
    filteredWords: [],
    hidePostStatistics: false,
    hideUserStatistics: false,
    disableLinkEmbeds: false,
    disableVideoEmbeds: false,
    enableProfilePictureMentions: false,
    displayImageHeightInThumbnail: true,

}

export default settings;