import { setHeaders, url, handleError } from "./api.service"

const SHAKOU_NOTIFICATIONS =  `/api/notifications`
const SHAKOU_NOTIFICATIONS_GET = (page) =>  `/api/notifications?page=${page}`
const SHAKOU_NOTIFICATIONS_SINCE = (since, page) =>  `/api/notifications?since=${since}&page=${page}`

async function getNotifications(page = 0) {
    var headers = setHeaders();

    var response;
    try {
        response = await fetch(url(SHAKOU_NOTIFICATIONS_GET(page)), headers);
    } catch {
        return null;
    }

    if (response.status != 200) {
        handleError(response);
        return null;
    }

    return await response.json();
}

async function getNotificationsSince(since = 0, page = 0) {
    var headers = setHeaders();

    var response;
    try {
        response = await fetch(url(SHAKOU_NOTIFICATIONS_SINCE(since, page)), headers);
    } catch {
        return null;
    }

    if (response.status != 200) {
        handleError(response);
        return null;
    }

    return await response.json();
}

function markNotificationsRead() {
    var headers = setHeaders("PUT");

    fetch(url(SHAKOU_NOTIFICATIONS), headers);
}

export {
    getNotifications,
    getNotificationsSince,
    markNotificationsRead
}