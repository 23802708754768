const _state = {
    alerts: []
}

const _getters = {
    getAlerts(state) { return state.alerts; }
}

const _actions = {
    removeAlert({ state }, index) {
        state.alerts.splice(index, 1);
    },
    addAlert({ state }, alert) {
        state.alerts.push(alert);
    }
}

const alert = {
    namespaced: true,
    state: _state,
    getters: _getters,
    actions: _actions
}

export default alert;