<script>
import { h } from 'vue'
import { parseMessage } from '@/services/textParser.service';
    export default {
            name: "post-body",
            props: {
                body: {
                    type: String,
                    required: true
                },
                type: {
                    type: String,
                    default: "TEXT"
                },
                metadata: {
                    type: Object,
                    required: false
                },
                mentionedUsers: {
                    type: Object,
                    required: false
                },
                isExternal: {
                    type: Boolean,
                    default: false
                },
                externalTags: {
                    type: Object,
                    required: false
                }
            },
            data() {
                return {
                    showFullMessage: false
                }
            },
            computed: {
                longMessage() {
                    if(this.body != null)
                        if(this.body.length > 1600) 
                            return true
                    return false
                },
                getClass() {
                    if(this.body != null)
                        if(this.body.length > 1600 && !this.showFullMessage) 
                            return 'body long'
                    return 'body'
                }
            },
            render() {
                return[
                    h('div',{ class: this.getClass},parseMessage(this.body,{
                        postType: this.type,
                        metadata: this.metadata,
                        mentionedUsers: this.mentionedUsers,
                        external: this.isExternal,
                        tags: this.externalTags
                    })),
                    h('span', { class: 'show-button'}, [ this.longMessage ? 
                        h('span', {
                            onClick:()=>{ 
                                this.showFullMessage = !this.showFullMessage 
                            }
                        },
                        this.showFullMessage ? this.$t('component.post.hide') : this.$t('component.post.show')) : 
                        h('span')
                    ])
                ]
            }
    }
</script>
<style lang="scss">
.show-button {
    color: var(--link);
    &:hover {
        cursor: pointer;
    }
}
.body {
    align-items: center;
    margin: 1rem 0rem;
    word-break: break-word;
    white-space: pre-wrap;
    text-align: left;

    &.long {
        max-height: 10rem;
        overflow: hidden;
    }
}

</style>