import store from "@/store";

const userPermissions = {
    unAuthenticated: 'unAuthenticated',
    isAuthenticated: 'CanLogin',
    isPoster: 'isPoster',
    CanSeeModeratorSettings: 'CanInvite',
    isNotPoster: 'isNotPoster',
    isAdmin: 'Admin',
    canLocalPost: 'CanSeeLocal'
}

const postPermissions = {
    hasMedia: 'hasMedia',
    isThread: 'isThread'
}

function filterListV2(filterableList) {
    var filteredList = [];
    if (store.getters['Authentication/getPermissions'] != null) {
        if (store.getters['Authentication/getPermission'](userPermissions.isAdmin)) {
            return filterableList;
        }
    }
    filterableList.forEach((item) => {
        if (item.permission == null) { filteredList.push(item); }
        if (store.getters['Authentication/getPermission'](item.permission)) {
            filteredList.push(item);
        }
    })

    return filteredList
}

async function filterList(filterableList, { checkPost = false, hasMedia, isThread }, { checkPoster = false, requiredPosterUrl}, checkPermissions = false) {
    var isValidated = isLoggedIn();

    var filteredList = filterableList;

    if (checkPost) {
        if (!hasMedia) {
            filteredList = filter(filteredList, postPermissions.hasMedia);
        }
        if (!isThread) {
            filteredList = filter(filteredList, postPermissions.isThread);
        }
    }

    if (checkPoster) {
        if (requiredPosterUrl != store.getters['User/getUrl']) {
            filteredList = filter(filteredList, userPermissions.isPoster);
        }
        if (requiredPosterUrl == store.getters['User/getUrl']) {
            filteredList = filter(filteredList, userPermissions.isNotPoster);
        }
    }

    if (checkPermissions) {
        filteredList = filterUserPermissions(filteredList);
    }

    if (isValidated) {
        filteredList = filter(filteredList, userPermissions.unAuthenticated);
    } else {
        filteredList = filter(filteredList, userPermissions.isAuthenticated);
        filteredList = filter(filteredList, userPermissions.isPoster);
    }
    return filteredList;
}

function filterUserPermissions(list) {
    return list.filter((item) => {
        if (item.permission == null) { return item; }
        if (store.getters["Authentication/getPermissions"] == null) { return null; }
        if(store.getters["Authentication/getPermissions"].includes(item.permission)) {
            return item;
        } else {
            return null;
        }
    })
}

function filter(list, permission) {
    return list.filter((item) => {
        if (item.permission == null) { return item; }
        if(item.permission.includes(permission)) {
            return null;
        } else {
            return item;
        }
    })
}

function isLoggedIn() {
    if (store.getters['User/getUrl']) {
        return true;
    } else {
        return false;
    }
}

export {
    userPermissions,
    postPermissions,
    filterList,
    filterListV2,
    isLoggedIn
}