<template>
    <div class="notification-wrapper">
        <muted-notification
            v-if="notification.isMuted"
            :notification="notification"
        />
        <default-notification
            v-else
            :notification="notification"
        />
    </div>
</template>
<script>
import DefaultNotifcation from "@/components/default-notification/default-notification.vue"
import MutedNotifcation from "@/components/muted-notification/muted-notification.vue"
    export default {
        name: "notification-wrapper",
        components: {
            "default-notification": DefaultNotifcation,
            "muted-notification": MutedNotifcation
        },
        props: {
            notification: {
                type: Object,
                required: true
            }
        }
    }
</script>
<style lang="scss">
.notification-wrapper { 

}
</style>