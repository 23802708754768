<template>
    <div class="timeline-panel" v-if="isLoaded" :class="getPanelClass">
        <div class="timeline-panel-header"  v-if="!isThread && !isSearch && !userUrl">
            <sh-dropdown-list
                :defaultValue="timeline"
                :listItems="allowedTimelines"
                class="switch-timeline"
                @clicked="(e) => $emit('switchTimelines',e)"
            />
            <sh-filled-button 
                class="load-new"
                @click="mergeFetchedPosts(name)" 
            >
                {{  loadNewLabel }}
            </sh-filled-button>
        </div>
        <post-wrapper
            v-for="post in getTimeline(name)"
            :ref="post.url"
            :key="post.url"
            :post="post"
            :topLevel="!isThread"
            :isThread="isThread"
            :highlightedPost="threadUrl"
            @updatePost="prepareUpdate"
            @toggleMute="prepareMute(post)"
            @toggleBookmark="prepareBookmark(post)"
        />
        <sh-filled-button 
            v-if="!isThread && !userUrl"
            class="load-more"
            @click="fetchPosts(false)" 
        >
            {{  loadMoreLabel }}
        </sh-filled-button>
    </div>
</template>

<script>
import PostWrapper from '@/components/post-wrapper/post-wrapper.vue'
import ShFilledButton from '@/components/common/sh-filled-button.vue'
import ShDropdownList from '@/components/common/sh-dropdown-list.vue'
import { getPublicTimeline, getFederatedTimeline, getHomeTimeline, getBookmarks, getUserTimeline, getUserLikesTimeline } from '@/services/api/timeline-api.service.js'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { startFetchingInterval, clearFetchingInterval } from '@/services/post-handler.service'
import { getThread } from '@/services/api/post-api.service'
import timelines from "@/entities/Timelines"
import { filterListV2 } from '@/services/permission-handler.service'
import { isInViewport } from '@/services/elements.service'
    export default {
        name: "timeline-panel",
        components: {
            "post-wrapper": PostWrapper,
            "sh-filled-button": ShFilledButton,
            "sh-dropdown-list": ShDropdownList
        },
        props: {
            name: {
                type: String,
                required: true,
                default: "HomeTimeline"
            },
            timeline: {
                type: String,
                required: false,
                default: "public"
            },
            isSearch: {
                type: Boolean,
                default: false
            },
            isThread: {
                type: Boolean,
                default: false
            },
            threadUrl: {
                type: String,
                required: false
            },
            userUrl: {
                type: String,
                required: false
            },
            hasMedia: {
                type: Boolean,
                default: false
            },
            isLikes: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                scrollTimeout: false,
                scrollHandlerExists: false,
                isLoaded: false,
                allowedTimelines: []
            }
        },
        computed: {
            ...mapGetters({
                getTimeline: 'Timeline/getPosts',
                getPage: 'Timeline/getPage',
                getMorePosts: 'Timeline/getMorePosts',
                getNewPosts: 'Timeline/getFetchedPosts',
                automaticallyUpdate: 'Settings/getAutomaticallyUpdateTimeline',
                loadOlderPosts: 'Settings/getAutomaticallyGetOlderPosts'
            }),
            loadMoreLabel() {
                if (this.getMorePosts(this.name)) {
                    return this.$t('component.timeline.load-more-posts')
                } else {
                    return this.$t('component.timeline.no-more-posts')
                }
            },
            loadNewLabel() {
                var newPosts = this.getNewPosts(this.name);
                if (newPosts > 0) {
                    return this.$t('component.timeline.load-new-posts', [newPosts])
                } else {
                    return this.$t('component.timeline.no-new-posts')
                }
            },
            getPanelClass() {
                if (this.isSearch) {
                    return "search-panel"
                }

                return ""
            }
        },
        methods: {
            ...mapActions({
                actionClearTimeline: 'Timeline/clearTimeline',
                updatePost: 'Timeline/updatePost',
                setPosts: "Timeline/createTimeline",
                addPosts: "Timeline/addPosts",
                mergeFetchedPosts: "Timeline/mergeFetchedPosts"
            }),
            ...mapMutations({
                toggleMute: 'Timeline/toggleMute',
                toggleBookmark: 'Timeline/toggleBookmark',
            }),
            prepareMute(value) {
                this.toggleMute({
                    timelineName: this.name,
                    post: value
                })
            },
            prepareBookmark(value) {
                this.toggleBookmark({
                    timelineName: this.name,
                    post: value
                })
            },
            prepareUpdate(value) {
                this.updatePost({
                    timelineName: this.name,
                    type: this.timeline,
                    post: value
                })
            },
            scrollThreadIntoView() {
                if (this.isThread) {
                    this.$nextTick(() => {
                        if (this.$refs[this.threadUrl]) {
                            this.$refs[this.threadUrl][0].$el.scrollIntoView({block: "center", behavior: "smooth"});
                        }
                    })
                }
            },
            clearTimeline() {
                this.actionClearTimeline(this.name);
            },
            switchTimelines() {
                var timelineFunction;
                this.$nextTick(async () => {
                    this.clearTimeline();

                    switch(this.timeline) {
                        case "public": timelineFunction = getPublicTimeline; break;
                        case "home": timelineFunction = getHomeTimeline; break;
                        case "federated": timelineFunction = getFederatedTimeline; break;
                        case "bookmarks": timelineFunction = getBookmarks; break;
                    } 
                    var posts = await timelineFunction(0);
                    
                    this.setPosts({
                        timelineName: this.name,
                        posts: posts,
                        page: 0,
                        type: this.timeline,
                        automaticallyUpdate: this.automaticallyUpdate
                    });

                    if (this.timeline != "bookmarks") {
                        startFetchingInterval(this.name);
                    }
                })
            },
            async fetchPosts(initialFetch = false) {
                if (this.userUrl && initialFetch) {
                    this.clearTimeline();
                }
                if (this.isSearch && !initialFetch) {
                    this.$emit('fetchMore');
                    return;
                }
                // Get newer posts instead
                if (this.getTimeline(this.name) != null && initialFetch && !this.isThread) {
                    this.isLoaded = true;
                    return;
                }
                // No more posts, and this isn't our initial fetch
                if (!this.getMorePosts(this.name) && !initialFetch && !this.isThread) {
                    return;
                }

                var page = this.getPage(this.name);
                if (!initialFetch) {
                    page++;
                }

                var timelineFunction;
                var postFunction;

                if (this.isThread) {
                    this.clearTimeline();
                    timelineFunction = getThread;
                } else {
                    switch(this.timeline) {
                        case "public": timelineFunction = getPublicTimeline; break;
                        case "home": timelineFunction = getHomeTimeline; break;
                        case "federated": timelineFunction = getFederatedTimeline; break;
                        case "bookmarks": timelineFunction = getBookmarks; break;
                        case "user": this.isLikes ? timelineFunction = getUserLikesTimeline : timelineFunction = getUserTimeline; break;
                    } 
                }
                
                var posts;
                if (this.isThread) {
                    posts = await timelineFunction(this.threadUrl);
                }
                else if (this.userUrl) {
                    posts = await timelineFunction(this.userUrl, this.hasMedia, page);
                }
                else {
                    posts = await timelineFunction(page);
                }

                if (initialFetch) {
                    postFunction = this.setPosts;
                } else {
                    postFunction = this.addPosts;
                }
                
                postFunction({
                    timelineName: this.name,
                    posts: posts,
                    page: page,
                    type: this.timeline,
                    automaticallyUpdate: this.automaticallyUpdate
                });

                if (!this.isThread) {
                    startFetchingInterval(this.name);
                    if (!this.scrollHandlerExists) {
                        window.addEventListener('wheel', this.handleScroll);
                        this.scrollHandlerExists = true;
                    }
                }
                if (initialFetch) {
                    this.isLoaded = true;
                }
                this.scrollThreadIntoView();

            },
            handleScroll() {
                if (!this.loadOlderPosts || this.scrollTimeout) { return; }
                var size = this.getTimeline(this.name).length;
                var second = this.getTimeline(this.name)[size-5];
                if (isInViewport(this.$refs[second.url][0].$el)) {
                    this.fetchPosts(false);
                    this.scrollTimeout = true;
                    setTimeout(() => {
                        this.scrollTimeout = false;
                    }, 5000)
                }
            }

        },
        created() {
            this.fetchPosts(true);
            this.allowedTimelines = filterListV2(timelines);
        },
        mounted() {
            if (!this.isThread && this.timeline != "bookmarks") {
                startFetchingInterval(this.name);
            }
        },
        unmounted() {
            clearFetchingInterval(this.name);
            window.removeEventListener('wheel', this.handleScroll);
            this.scrollHandlerExists = false;
        }
    }
</script>

<style scoped lang="scss">
.timeline-panel { 
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .timeline-panel-header {
        display: flex;
        margin-bottom: 0.5rem;
        width: 100%;
        .switch-timeline {
            z-index:400;
        }
    }
    &.search-panel {
        margin-right: 0;
    }
    .load-more {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .load-new {
        margin-left: auto;
    }
}
</style>