<template>
    <div class="post-footer">
        <sh-icon-button
            small
            noBackground
            :fitContent="getReplies > 0"
            icon="icon-forward"
            @click="$emit('toggleReplyForm')"
        >
            {{ getReplies }}
        </sh-icon-button>
        <sh-icon-button
            :class="hasLiked"
            small
            noBackground
            :fitContent="getLikes > 0"
            :icon="hasLikedIcon"
            @click="handleLike"
        >
            {{ getLikes }}
        </sh-icon-button>
        <sh-icon-button
            :class="hasRepeated"
            small
            noBackground
            :fitContent="getRepeats > 0"
            @click="handleRepost"
            icon="icon-loop2"
        >
            {{ getRepeats }}
        </sh-icon-button>
        <sh-icon-button
            small
            noBackground
            :fitContent="getReacts > 0"
            @click="openReact"
            icon="icon-smile"
        >
            {{ getReacts }}
        </sh-icon-button>
        <sh-emoji-picker
            :show="showEmojiPicker"
            @closed="showEmojiPicker = false"
            @selected="handleReact"
        />
        <sh-dropdown-list
            v-if="hasMentions"
            useSmallIcon
            useIcon
            useIconNoBackground
            maxContent
            icon="icon-users"
            isUserList
            :listItems="mentions"
        />
        <sh-icon-button
            v-if="isThread"
            small
            noBackground
            @click="$emit('openThread')"
            icon="icon-keyboard_arrow_down"
        />
        <sh-dropdown-list
            useSmallIcon
            useIcon
            useIconNoBackground
            maxContent
            zIndex="500"
            icon="icon-ellipsis-h"
            @clicked="handleListClick"
            :listItems="availableInteractionOptions"
        />

    </div>
</template>

<script>
import ShDropdownList from "@/components/common/sh-dropdown-list.vue";
import ShIconButton from "@/components/common/sh-icon-button.vue";
import ShEmojiPicker from "@/components/common/sh-emoji-picker.vue";

import { mapGetters } from "vuex";
import { bookmarkPost, deletePost, likePost, muteThread, reactPost,unReactPost, repostPost, unLikePost, unRepostPost, unbookmarkPost, unmuteThread } from "@/services/api/post-api.service";
import { userPermissions, postPermissions, filterList, isLoggedIn } from "@/services/permission-handler.service"
    export default {
        name: "post-footer",
        components: {
            "sh-icon-button": ShIconButton,
            "sh-dropdown-list": ShDropdownList,
            "sh-emoji-picker": ShEmojiPicker,
        },
        props: {
            posterUrl: {
                type: String,
                required: true
            },
            postUrl: {
                type: String,
                required: true
            },
            mentions: {
                type: Array,
                required: true
            },
            hasMedia: {
                type: Boolean,
                default: false
            },
            reply: {
                type: Object,
                required: true
            },
            replies: {
                type: Array,
                required: true
            },
            isThread: {
                type: Boolean,
                default: false
            },
            isBookmarked: {
                type: Boolean,
                default: false
            },
            isMuted: {
                type: Boolean,
                default: false
            },
            interactions: {
                type: Array,
                required: true,
            }
        },
        data() {
            return {
                showEmojiPicker: false,
                show: false,
                defaultInteractionOptions: [
                    { value: "copyLink", label: this.$t('component.post.copy-link'), icon: 'icon-copy-link'},
                    { value: "bookmark", label: null, icon: null, permission: [userPermissions.isAuthenticated ]},
                    { value: "mute", label: null, icon: null, permission: [userPermissions.isAuthenticated ]},
                    { value: "block", label: this.$t('common.block'), icon: 'icon-blocked', permission: [ userPermissions.isAuthenticated, userPermissions.isNotPoster ] },
                    { value: "download", label: this.$t('component.post.download'), icon: 'icon-arrow-down', permission: [postPermissions.hasMedia] },
                    { value: "downloadThread", label: this.$t('component.post.download-thread'), icon: 'icon-folder-download', permission: [ postPermissions.hasMedia, postPermissions.isThread ]},
                    { value: "edit", label: this.$t('component.post.edit'), icon: 'icon-edit', permission: [userPermissions.isAuthenticated, userPermissions.isPoster] },
                    { value: "delete", label: this.$t('component.post.delete'), icon: 'icon-cross', permission: [userPermissions.isAuthenticated, userPermissions.isPoster] },
                ],
                availableInteractionOptions: []
            }
        },
        computed: {
            ...mapGetters({
                getUser: "User/getUrl"
            }),
            getReplies() {
                if (!this.replies) {
                    return null;
                }
                return this.replies.length > 0 ? this.replies.length : null;
            },
            getLikes() {
                var likes = this.getInteraction("like")
                if (!likes) {
                    return null;
                }
                return likes.length > 0 ? likes.length : null;
            },
            getRepeats() {
                var repeats = this.getInteraction("repost")
                if (!repeats) {
                    return null;
                }
                return repeats.length > 0 ? repeats.length : null;
            },
            hasLiked() {
                if (!this.getLikes) {
                    return "";
                }

                if (this.getUserInteraction("like")) {
                    return "liked";
                } else {
                    return "";
                }
            },
            hasLikedIcon() {
                if (this.hasLiked == "liked") {
                    return "icon-star-full";
                } else {
                    return "icon-star-empty";
                }
            },
            hasRepeated() {
                if (!this.getRepeats) {
                    return "";
                }
                if (this.getUserInteraction("repost")) {
                    return "repeated"
                } else {
                    return ""
                }
            },
            hasMentions() {
                if (!this.mentions) {
                    return false;
                }

                if (this.mentions.length <=0) {
                    return false
                }

                return true;
            }
        },
        methods: {
            hasReacted(reaction) {
                var reactions = this.getInteraction('React');
                return reactions.forEach((r) => r.emoji.name == reaction && r.user.url == this.getUser)
            },
            getUserInteraction(type) {
                if (!this.interactions) {
                    return null;
                }
                var interactions = this.getInteraction(type).filter((interaction) => interaction.user.url == this.getUser);

                if (interactions.length > 0) {
                    return true
                } else {
                    return false;
                }
            },
            getInteraction(type) {
                if (!this.interactions) {
                    return null;
                }
                var interactions = this.interactions.filter((interaction) => interaction.type.toLowerCase() == type);

                return interactions;
            },
            copyLink() {
                navigator.clipboard.writeText(window.location.origin + '/post/' + this.postUrl);
            },
            block() {

            },
            delete() {
                deletePost(this.postUrl);
            },
            openReact() {
                if (isLoggedIn()) {
                    this.showEmojiPicker = !this.showEmojiPicker;
                }
            },
            async handleLike() {
                if (isLoggedIn()) {
                    var response;
                    if (this.hasLiked) { 
                        response = await unLikePost(this.postUrl);
                    } else {
                        response = await likePost(this.postUrl);
                    }
                    this.$emit('updatePost', response);
                }
            },
            async handleRepost() {
                if (isLoggedIn()) {
                    var response;
                    if (this.hasRepeated) { 
                        response = await unRepostPost(this.postUrl);
                    } else {
                        response = await repostPost(this.postUrl);
                    }
                    this.$emit('updatePost', response);
                }
            },
            async handleReact(reaction) {
                if (isLoggedIn()) {
                    var response;
                    if (this.hasReacted(reaction)) { 
                        response = await unReactPost(this.postUrl, reaction);
                    } else {
                        response = await reactPost(this.postUrl, reaction);
                    }
                    this.$emit('updatePost', response);
                    this.showEmojiPicker = false;
                }
            },
            handleMute() {
                if (isLoggedIn()) {
                    if (this.isMuted) { 
                        unmuteThread(this.postUrl);
                    } else {
                        muteThread(this.postUrl);
                    }
                    this.$emit('toggleMute');
                }
            },
            handleBookmark() {
                if (isLoggedIn()) {
                    if (this.isBookmarked) { 
                        unbookmarkPost(this.postUrl);
                    } else {
                        bookmarkPost(this.postUrl);
                    }
                    this.$emit('toggleBookmark');
                }
            },
            handleListClick(value) {
                switch(value) {
                    case "copyLink": this.copyLink(); return;
                    case "block": this.block(); return;
                    case "delete": this.delete(); return;
                    case "mute": this.handleMute(); return;
                    case "edit": this.$emit('toggleEditing'); return;
                    case "bookmark": this.handleBookmark(); return;
                    case "download": this.$emit('downloadImages'); return;
                    case "downloadAll": this.$emit('downloadAll'); return;
                }
            },
            updateBookmark() {
                var bookmarkIndex = 1;
                if (this.isBookmarked) {
                    this.defaultInteractionOptions[bookmarkIndex].label = this.$t('component.post.remove-bookmark');
                    this.defaultInteractionOptions[bookmarkIndex].icon = 'icon-bookmark-remove';
                } else {
                    this.defaultInteractionOptions[bookmarkIndex].label = this.$t('component.post.add-bookmark');
                    this.defaultInteractionOptions[bookmarkIndex].icon = 'icon-bookmark-add';
                }
            },
            updateMuted() {
                var mutedIndex = 2;
                if (this.isMuted) {
                    this.defaultInteractionOptions[mutedIndex].label = this.$t('component.post.unmute');
                    this.defaultInteractionOptions[mutedIndex].icon = 'icon-eye';
                } else {
                    this.defaultInteractionOptions[mutedIndex].label = this.$t('component.post.mute');
                    this.defaultInteractionOptions[mutedIndex].icon = 'icon-eye-blocked';
                }
            }
        },
        async created() {
            this.updateBookmark();
            this.updateMuted();

            this.availableInteractionOptions = await filterList(
                this.defaultInteractionOptions, 
                { checkPost: true, hasMedia: this.hasMedia, isThread: this.isThread }, 
                { checkPoster: true, requiredPosterUrl: this.posterUrl});
        },
        watch: {
            isMuted() {
                this.updateMuted();
            },
            isBookmarked() {
                this.updateBookmark();
            },
        }
    }
</script>

<style lang="scss">
.footer-picker {
    max-width: 1000% !important;
}
    .post-footer {
        display: flex;
        column-gap: 0.5rem;
        position: relative;
        .repeated {
            color: var(--repeatIcon);
        }
        .liked {
            color: var(--likeIcon);
        }
    }
</style>