const fileExtensions = {
    Audio: ["ogg", "flac", "mp3"],
    Video: ["mp4", "webm", "mkv"],
    Image: ["png", "jpg", "jpeg", "gif"]
}

const fileTypes = {
    Audio: "audio",
    Video: "video",
    Image: "image",
    Unknown: "Unknown"
}

function getFileType(fileName) {
    var parts = fileName.split('.');
    var extension = parts[parts.length-1];

    if (fileExtensions.Audio.includes(extension)) {
        return fileTypes.Audio;
    }
    if (fileExtensions.Video.includes(extension)) {
        return fileTypes.Video;
    }
    if (fileExtensions.Image.includes(extension)) {
        return fileTypes.Image;
    }

    return fileTypes.Unknown;
}

export {
    getFileType,
    fileTypes
}

export default getFileType