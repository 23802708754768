<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
    <div class="sh-dropdown-list">
        <sh-icon-button
            v-if="useIcon"
            @click="toggle"
            :icon="icon"
            :small="useSmallIcon" 
            :noBackground="useIconNoBackground"
        >
        </sh-icon-button>
        <sh-filled-button 
            v-else
            @click="toggle" 
            borderRadius=".25"
            class="header"
        >
            {{  selectedItem }} <span :class="arrowIcon"></Span>
        </sh-filled-button >
        <sh-floating-panel 
            class="floating-panel"
            :class="listClass"
            :show=isToggled
            :offsetY=offsetY
            :maxContent=maxContent
            :style="getStyle"
            @closed="isToggled = false"
            clickable
            instantFade
        >
            <sh-user-result class="dropdown-user-result" v-if="isUserList" v-for="user in listItems" :key="user.url" :user="user"/>
            <sh-dropdown-list-item v-else @clicked="onClick" v-for="item in listItems" :value="item.value" :icon="item.icon" :key="item.value">{{  item.label }}</sh-dropdown-list-item>
        </sh-floating-panel>
    </div>
</template>

<script>
import ShFilledButton from "@/components/common/sh-filled-button.vue";
import ShIconButton from "@/components/common/sh-icon-button.vue";
import ShFloatingPanel from './sh-floating-panel.vue';
import ShDropdownListItem from './sh-dropdown-list-item.vue';
import ShUserResult from './sh-user-result.vue';

    export default {
        name: "sh-dropdown-list",
        components: {
            "sh-filled-button": ShFilledButton,
            "sh-icon-button": ShIconButton,
            "sh-floating-panel": ShFloatingPanel,
            "sh-user-result": ShUserResult,
            "sh-dropdown-list-item": ShDropdownListItem
        },
        props: {
            listItems: {
                type: Array,
                required: true
            },
            defaultValue: {
                type: String,
                required: false
            },
            offsetY: {
                type: Number,
                default: 5
            },
            useIcon: {
                type: Boolean,
                default: false
            },
            useSmallIcon: {
                type: Boolean,
                default: false
            },
            useIconNoBackground: {
                type: Boolean,
                default: false
            },
            maxContent: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                required: false
            },
            isUserList: {
                type: Boolean,
                default: false,
            },
            zIndex: {
                type: Number,
                required: false,
                default: null
            },
            listClass: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                isToggled: false,
                selectedItem: "defaultItem"
            }
        },
        computed: {
            arrowIcon() {
                if(this.isToggled) {
                    return 'icon-keyboard_arrow_up'
                } else {
                    return 'icon-keyboard_arrow_down'
                }
            },
            getStyle() {
                var style = '';
                
                if (this.zIndex) {
                    style += `z-index:${this.zIndex};`
                }

                return style;
            }
        },
        methods: {
            toggle() {
                this.isToggled = !this.isToggled;
            },
            onClick(value) {
                this.$emit('clicked',value);
                this.toggle();
                
                var item = this.listItems.find(o => o.value == value);
                this.selectedItem = item.label;
            },
            setDefaultValue() {
                if (this.icon) {
                    return null;
                }
                if (!this.defaultValue) {
                    this.selectedItem = this.listItems[0].label;
                } else {
                    var item = this.listItems.find(o => o.value == this.defaultValue);
                    this.selectedItem = item.label;
                }
            }
        },
        created() {
            this.setDefaultValue();
        }
    }
</script>

<style lang="scss">
.sh-dropdown-list {
    position: relative;
    .header {
        width: fit-content;
        padding: 0 1rem;
        margin: 0.5rem 0;
        border-radius: .1rem;
        background-color: var(--panel);
        user-select: none;
        &:hover {
            cursor: pointer;
        }
    }
    .floating-panel {
        box-shadow: 0px 2px 1px 1px var(--shadow);
        .dropdown-user-result {
            min-width: 15rem;
        }
    }
}
</style>