<template>
    <div class="notification-view">
        <div class="view-wrap">
            <notification-panel class="full-panel">

            </notification-panel>
        </div>
    </div>
</template>
<script>
import NotificationPanel from "@/components/notification-panel/notification-panel.vue";
    export default {
        name: "NotificationView",
        components: {
            "notification-panel": NotificationPanel
        },
    }
</script>
<style lang="scss" scoped>
.notification-view {
    width: 40rem;
    .view-wrap {
        background-color: var(--background);
        .full-panel {
            height: 100%;
        }
    }
}
</style>