import en from "./en/en.json"
import es from "./es/es.json"
import enLocale from "./en/en.js"
import esLocale from "./es/es.js"
import shortform from "./shortform/shortform.js"
import { createI18n } from 'vue-i18n'
import { register } from "timeago.js"



export const defaultLocale = 'en';
export const languages = {
    en: en,
    es: es
}

const messages = Object.assign(languages);

const i18n = createI18n({
    locale: defaultLocale,
    messages
});

register('en',enLocale);
register('es',esLocale);
register('shortform',shortform);

export default i18n