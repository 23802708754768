<template>
    <div class="link-menu-list">
        <router-link  
            class="link-item"
            v-for="item in menuItems"
            :key="item.value"
            :to="{name: `${item.value}` }"
            :title="item.label"
        >
            <sh-icon-button
                :icon="item.icon"
                customSize="2"
                fontSize="x-large"
                small
                noBackground
            >
                <div 
                    v-if="item.value == 'chat' && unreadCount > 0"
                    class="chat-icon-badge">
                </div>
            </sh-icon-button>
            <span>{{ item.label }}</span>
        </router-link>
    </div>
</template>

<script>
import ShIconButton from "@/components/common/sh-icon-button.vue";
import { mapGetters } from "vuex";
export default {
    name: "user-panel",
    components: {
        "sh-icon-button": ShIconButton,
    },
    computed: {
        ...mapGetters({
            isUser: "User/getUrl",
            getUser: "User/getUser",
            unreadCount: "Chats/getUnreadMessageCount"
        })
    },
    data() {
        return {
            menuItems: [
                { value: "home", label: this.$t('component.user-panel.home'), icon: 'icon-home'},
                { value: "notification", label: this.$t('component.user-panel.notifications'), icon: 'icon-bell'},
                { value: "chat", label: this.$t('component.user-panel.chat'), icon: 'icon-bubbles'},
                { value: "emojis", label: this.$t('component.user-panel.emojis'), icon: 'icon-smile'},
            ]
        }
    }
}
</script>

<style lang="scss">
.link-menu-list {

    .link-item {
        align-items: center;
        border-radius: 0.25rem;
        column-gap: 1rem;
        display: flex;
        padding: 0.2rem;
        position: relative;
        margin: 0 0.25rem 0.5rem 0.25rem;
        .chat-icon-badge {
            position: absolute;
            height: .75rem;
            width: .75rem;
            background-color: var(--unseenNotification);
            top: 0;
            right: 0;
            z-index: 100;
            border-radius: 5rem;
        }
        &:hover {
            background-color: var(--buttonHover);
        }
    }
}
</style>