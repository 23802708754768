<template>
    <div class="sh-icon-button" :class="buttonClass" :Style="buttonStyle" >
        <span :class="icon"></span>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "sh-icon-button",
        props: {
            icon: {
                type: String,
                required: true
            },
            inverted: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            fitContent: {
                type: Boolean,
                default: false
            },
            noBackground: {
                type: Boolean,
                default: false
            },
            noHover: {
                type: Boolean,
                default: false
            },
            shadow: {
                type: Boolean,
                default: false
            },
            customSize: {
                type: Number,
                default: null
            },
            fontSize: {
                type: String,
                default: null
            }
        },
        computed: {
            buttonStyle() {
                var styleString = ""

                if (this.customSize) {
                    styleString += `width: ${this.customSize}rem; height: ${this.customSize}rem;`
                }
                
                if (this.fontSize) {
                    styleString += `font-size: ${this.fontSize};`
                }

                return styleString;
            },
            buttonClass() {
                var returnedClass = '';

                if (this.inverted) {
                    returnedClass += 'inverted '
                } else {
                    returnedClass += 'uninverted '
                }

                if (this.small) {
                    returnedClass += ' small '
                } else {
                    returnedClass += ' large '
                }


                if (this.noBackground) {
                    returnedClass += ' no-background '
                } else {
                    returnedClass += ' background '
                }

                if (this.fitContent) {
                    returnedClass += ' fit-content '
                }

                if (this.noHover) {
                    returnedClass += ' no-hover '
                }
                if (this.shadow) {
                    returnedClass += ' shadow '
                }
                return returnedClass;
            }
        }
    }
</script>

<style lang="scss">
.sh-icon-button {
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--buttonIcon);

    &.large {
        width: 2rem;
        padding: 0.2rem 2rem;
    }
    &.small {
        width: 1.5rem;
        border-radius: 0.2rem;
        height: 1.5rem;
    }

    &.inverted {
        outline-style: solid;
        outline-color: var(--button);
        &:hover {
            outline-color: var(--buttonHover);
        }
    }
    &.uninverted {
        &.background {
            background-color: var(--button);
        }
        &:hover {
            background-color: var(--buttonHover);
        }
    }

    &.no-hover {
        &:hover {
            background-color: transparent;
        }
    }

    &.fit-content {
        width: fit-content;
    }

    &.shadow {
        box-shadow: 0px 1px 1px 1px var(--shadow);
    }

    &:hover {
        cursor: pointer;
    }
}
</style>