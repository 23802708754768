import checkForFilteredWords from '@/services/text/filtered-words.service'
import i18n from '@/i18n'
import { url, setHeaders, handleError, refresh } from './api.service'

const SHAKOU_CREATE_POST = `/api/post`
const SHAKOU_EDIT_POST = SHAKOU_CREATE_POST
const SHAKOU_DELETE_POST = (url) => SHAKOU_GET_POST(url)
const SHAKOU_BOOKMARK_POST = (url) => `/api/post/${url}/bookmark`
const SHAKOU_VOTE_POST = (url, number) => `/api/post/${url}/vote/${number}`
const SHAKOU_LIKE_POST = (url) => `/api/post/${url}/like`
const SHAKOU_REPOST_POST = (url) => `/api/post/${url}/repost`
const SHAKOU_REACT_POST = (url, emoji) => `/api/post/${url}/react/${emoji}`
const SHAKOU_UNBOOKMARK_POST = (url) => `/api/post/${url}/unbookmark`
const SHAKOU_UNLIKE_POST = (url) => `/api/post/${url}/unlike`
const SHAKOU_UNREPOST_POST = (url) => `/api/post/${url}/unrepost`
const SHAKOU_UNREACT_POST = (url, emoji) => `/api/post/${url}/unreact/${emoji}`
const SHAKOU_GET_POST = (url) => `/api/post/${url}`
const SHAKOU_GET_THREAD = (url) => `/api/post/${url}/thread`

const SHAKOU_MUTE_THREAD = (url) => `/api/mute/thread/${url}`
const SHAKOU_UNMUTE_THREAD = (url) => `/api/unmute/thread/${url}`

async function createPost(postData) {
    var headers = setHeaders("POST", postData);

    var response = await fetch(url(SHAKOU_CREATE_POST), headers);

    if (response.status != 201) {
        // attempt to refresh and submit the post again
        if (response.status == 401) {
            var success = await refresh();
            if (success) {
                createPost(postData);
            }
        // Show error message, lets update the BE to be more clear?
        } else if (response.status == 400) {
            handleError(response, i18n.global.t('error.failed-to-create-post'))
        }
    }
}

async function editPost(postData) {
    var headers = setHeaders("PUT", postData);

    var response = await fetch(url(SHAKOU_EDIT_POST), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return await response.json();
}

async function deletePost(postUrl) {
    var headers = setHeaders("DELETE");

    var response = await fetch(url(SHAKOU_DELETE_POST(postUrl)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }
}

async function bookmarkPost(postUrl) {
    var headers = setHeaders("POST");

    var response = await fetch(url(SHAKOU_BOOKMARK_POST(postUrl)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return await response.json();
}

async function votePost(postUrl, number) {
    var headers = setHeaders("POST");

    var response = await fetch(url(SHAKOU_VOTE_POST(postUrl, number)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return await response.json();
}

async function likePost(postUrl) {
    var headers = setHeaders("POST");

    var response = await fetch(url(SHAKOU_LIKE_POST(postUrl)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return await response.json();
}

async function repostPost(postUrl) {
    var headers = setHeaders("POST");

    var response = await fetch(url(SHAKOU_REPOST_POST(postUrl)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return await response.json();
}

async function reactPost(postUrl, emojiName) {
    var headers = setHeaders("POST");

    var response = await fetch(url(SHAKOU_REACT_POST(postUrl, emojiName)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return await response.json();
}

async function unbookmarkPost(postUrl) {
    var headers = setHeaders("POST");

    var response = await fetch(url(SHAKOU_UNBOOKMARK_POST(postUrl)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return null;
}


async function unLikePost(postUrl) {
    var headers = setHeaders("POST");

    var response = await fetch(url(SHAKOU_UNLIKE_POST(postUrl)), headers);

    if (response.status != 200) {
        handleError("Fuck", i18n.global.t('error.vote'));
        return;
    }

    return await response.json();
}

async function unRepostPost(postUrl) {
    var headers = setHeaders("POST");

    var response = await fetch(url(SHAKOU_UNREPOST_POST(postUrl)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return await response.json();
}

async function unReactPost(postUrl, emojiName) {
    var headers = setHeaders("POST");

    var response = await fetch(url(SHAKOU_UNREACT_POST(postUrl, emojiName)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }
    
    return await response.json();
}

async function getPost(postUrl) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_GET_POST(postUrl)), headers);

    if (response.status != 200) {
        return;
    }

    return await response.json();
}

async function muteThread(postUrl) {
    var headers = setHeaders("POST");

    var response = await fetch(url(SHAKOU_MUTE_THREAD(postUrl)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }
    
    return null;
}

async function unmuteThread(postUrl) {
    var headers = setHeaders("POST");

    var response = await fetch(url(SHAKOU_UNMUTE_THREAD(postUrl)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }
    
    return null;
}

async function getThread(postUrl) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_GET_THREAD(postUrl)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }
    
    return checkForFilteredWords(await response.json());
}

export {
    createPost,
    editPost,
    deletePost,
    bookmarkPost,
    votePost,
    likePost,
    repostPost,
    reactPost,
    unbookmarkPost,
    unLikePost,
    unRepostPost,
    unReactPost,
    getPost,
    getThread,
    muteThread,
    unmuteThread
}