<template>
    <div class="filter-settings">
        <h4>{{  $t('component.settings.filter-panel.words') }}</h4>
        <sh-multi-line-text-input
            :modelValue="getFilteredWords"
            v-model="filteredWords"
            :placeholder="$t('component.settings.filter-panel.filtered-words')"
        />
        <span>{{ $t('component.settings.filter-panel.filtered-words') }}</span>
        <sh-filled-button class="submit-button" @click="prepareFilteredWords">
            {{ $t('common.submit') }}
        </sh-filled-button>

        <h4>{{  $t('component.settings.filter-panel.title') }}</h4>
        <sh-checkbox
            id="hideFilteredPosts" 
            v-model="hideFilteredPosts"
            @click="setHideFilteredPosts(!hideFilteredPosts)"
            >{{ $t("component.settings.filter-panel.hide-filtered-posts")}}</sh-checkbox>
        <sh-checkbox 
            id="hideOwnFilteredPosts" 
            v-model="hideOwnFilteredPosts"
            @click="setHideOwnFilteredPosts(!hideOwnFilteredPosts)"
        >{{ $t("component.settings.filter-panel.hide-own-filtered-posts")}}</sh-checkbox>
        <sh-checkbox 
            id="hideMutedThreads" 
            v-model="hideMutedThreads"
            @click="setHideMutedThreads(!hideMutedThreads)"
        >{{ $t("component.settings.filter-panel.hide-muted-threads")}}</sh-checkbox>
        <sh-checkbox 
            id="hideMutedUsers" 
            v-model="hideMutedUsers" 
            @click="setHideMutedUser(!hideMutedUsers)"
            
        >{{ $t("component.settings.filter-panel.hide-muted-users")}}</sh-checkbox>
    </div>
</template>
<script>
import ShCheckbox from "@/components/common/sh-checkbox.vue"
import ShMultiLineTextInput from "@/components/common/sh-multi-line-text-input.vue"
import ShFilledButton from "@/components/common/sh-filled-button.vue"
import { mapGetters, mapActions } from "vuex";
    export default {
        name: "filter-settings",
        components: {
            "sh-checkbox": ShCheckbox,
            "sh-multi-line-text-input": ShMultiLineTextInput,
            "sh-filled-button": ShFilledButton,
        },
        computed: {
            ...mapGetters({
                savedFilteredWords: "Settings/getFilteredWords",
                hideFilteredPosts: "Settings/getHideFilteredPost",
                hideOwnFilteredPosts: "Settings/getHideOwnFilteredPost",
                hideMutedThreads: "Settings/getHideMutedThreads",
                hideMutedUsers: "Settings/getHideMutedUsers",
            }),
            getFilteredWords() {
                if (this.savedFilteredWords == null) {
                    return null;
                } else {
                    return this.savedFilteredWords.join('\n');
                }
            }
        },
        data() {
            return {
                filteredWords: "",
            }
        },
        methods: {
            ...mapActions({
                setFilteredWords: "Settings/setFilteredWords",
                setHideMutedUser: "Settings/setHideMutedUser",
                setHideMutedThreads: "Settings/setHideMutedThreads",
                setHideFilteredPosts: "Settings/setHideFilteredPosts",
                setHideOwnFilteredPosts: "Settings/setHideOwnFilteredPosts",
            }),
            prepareFilteredWords() {
                if (this.filteredWords == "") {
                    this.setFilteredWords(null);
                    return;
                }
                var filtered = this.filteredWords.split('\n')
                this.setFilteredWords(filtered);
            },
        }
    }
</script>
<style lang="scss">
.filter-settings {
    .submit-button {
        margin-left: auto;
    }
}
</style>