import i18n from "@/i18n";
import {default as defaultState} from "@/settings/defaultSettings";

const _state = {
    settings: defaultState
}

const _getters = {
    getSettings(state) { return state.settings; },
    getLanguage(state) { return state.settings.language; },
    getDefaultPostType(state) { return state.settings.defaultPostType; },
    getDefaultTimeline(state) { return state.settings.defaultTimeline; },
    getFilteredWords(state) { return state.settings.filteredWords; },
    getHidePostStatistics(state) { return state.settings.hidePostStatistics; },
    getHideUserStatistics(state) { return state.settings.hideUserStatistics; },

    getHideFilteredPost(state) { return state.settings.hideFilteredPosts; },
    getHideOwnFilteredPost(state) { return state.settings.hideOwnFilteredPosts; },
    getHideMutedThreads(state) { return state.settings.hideMutedThreads; },
    getHideMutedUsers(state) { return state.settings.hideMutedUsers; },
    getDisplayImageHeightInThumbnail(state) { return state.settings.displayImageHeightInThumbnail; },
    getAutomaticallyUpdateTimeline(state) { return state.settings.automaticallyUpdateTimeline; },
    getEnableProfilePictureMentions(state) { return state.settings.enableProfilePictureMentions; },
    getAutomaticallyGetOlderPosts(state) { return state.settings.automaticallyGetOlderPosts; },
    getHidePostEmbedLinks(state) { return state.settings.hidePostEmbedLinks; },
    getDisableLinkEmbeds(state) { return state.settings.disableLinkEmbeds; },
    getDisableVideoEmbeds(state) { return state.settings.disableVideoEmbeds; },
}

const _mutations = {
}

const _actions = {
    setSettings({ state, dispatch }, settings) {
        state.settings = settings;
        dispatch('saveSettings');
    },
    setLanguage({ state, dispatch }, language) {
        state.settings.language = language; 
        i18n.global.locale = language;
        dispatch('saveSettings');
    },
    setDefaultPostType({ state, dispatch }, type) { 
        state.settings.defaultPostType = type; 
        dispatch('saveSettings');
    },
    setDefaultTimeline({ state, dispatch }, type) { 
        state.settings.defaultTimeline = type; 
        dispatch('saveSettings');
    },
    setFilteredWords({ state, dispatch }, filteredWords) {
        state.settings.filteredWords = filteredWords;
        dispatch('saveSettings');
    },
    setHideMutedUser({ state, dispatch}, value) {
        state.settings.hideMutedUsers = value
        dispatch('saveSettings');
    },
    setHideMutedThreads({ state, dispatch}, value) {
        state.settings.hideMutedThreads = value
        dispatch('saveSettings');
    },
    setHideFilteredPosts({ state, dispatch}, value) {
        state.settings.hideFilteredPosts = value
        dispatch('saveSettings');
    },
    setHideOwnFilteredPosts({ state, dispatch}, value) {
        state.settings.hideOwnFilteredPosts = value
        dispatch('saveSettings');
    },
    setDisplayImageHeightInThumbnail({ state, dispatch}, value) {
        state.settings.displayImageHeightInThumbnail = value
        dispatch('saveSettings');
    },
    setAutomaticallyUpdateTimeline({ state, dispatch}, value) {
        state.settings.automaticallyUpdateTimeline = value
        dispatch('saveSettings');
    },
    setAutomaticallyGetOlderPosts({ state, dispatch}, value) {
        state.settings.automaticallyGetOlderPosts = value
        dispatch('saveSettings');
    },
    setEnableProfilePictureMentions({ state, dispatch}, value) {
        state.settings.enableProfilePictureMentions = value
        dispatch('saveSettings');
    },
    setHidePostEmbedLinks({ state, dispatch}, value) {
        state.settings.hidePostEmbedLinks = value
        dispatch('saveSettings');
    },
    setDisableLinkEmbeds({ state, dispatch}, value) {
        state.settings.disableLinkEmbeds = value
        dispatch('saveSettings');
    },
    setDisableVideoEmbeds({ state, dispatch}, value) {
        state.settings.disableVideoEmbeds = value
        dispatch('saveSettings');
    },
    saveSettings({ state }) {
        localStorage.setItem("settings", JSON.stringify(state.settings));
    }
}

const settings = {
    namespaced: true,
    state: _state,
    getters: _getters,
    mutations: _mutations,
    actions: _actions
}

export default settings;