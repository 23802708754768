const defaultState = {
    groups: [],
    unorderedEmotes: []
}
const _getters = {
    getGroupNames(state) { return Object.keys(state.groups).sort() },
    getGroup: (state) => (group) => { 
        return state.groups[group].sort((a,b) => a.name.localeCompare(b.name)) 
    },
    getAllEmotes(state) { return state.unorderedEmotes }
}
const _mutations = {
    setGroups(state, allEmojis) {
        state.groups = allEmojis.reduce(function (r,a) {
            r[a.group] = r[a.group] || []
            r[a.group].push(a)
            return r
        },Object.create(null))
    },
    setUnorderedEmotes(state, unordered) {
        state.unorderedEmotes = unordered
    }
}

const _actions = {
    setEmojis({ commit }, emojis) {
        if (emojis == null) { return; }
        commit('setGroups', emojis);
        commit('setUnorderedEmotes', emojis);
    }
}

const emojis = {
    namespaced: true,
    state: defaultState,
    getters: _getters,
    mutations: _mutations,
    actions: _actions
}

export default emojis;