<template>
    <div class="post-embed" v-if="isVisible">
        <div 
            class="embed-toggle"
            :class="getToggle"   
            @click="isDisabled = !isDisabled" 
        ></div>                        
        <post-wrapper
            v-if="!isDisabled"
            class="embedded-post"
            :post="post"
            :postUrl="getUrl"
            @setReplyPost="setPost"
            @nullPost="isVisible = false"
        />
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
    export default {
        name: "post-embed",
        props: {
            metadata: {
                type: Object,
                required: true
            }
        },
        components: {
            "post-wrapper": defineAsyncComponent(() => import("@/components/post-wrapper/post-wrapper.vue"))
        },
        data() {
            return {
                post: null,
                isDisabled: false,
                isVisible: true
            }
        },
        computed: {
            getToggle() {
                if(this.isDisabled)
                    return 'icon-keyboard_arrow_down'
                else
                    return 'icon-keyboard_arrow_up'
            },
            getUrl() {
                var postUrl = new URL(this.metadata.url).pathname
                postUrl = postUrl.replace('/post/','')
                return postUrl
            }
        },
        methods: {
            setPost(p) {
                this.post = p 
            }
        }
    }
</script>

<style lang="scss">
.post-embed {
    min-height: 25px;
    position: relative;
    margin-top: .5rem;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 1px 1px var(--shadow);
    .embedded-post {
        width: auto;
    }
    .embed-toggle {
        font-size: x-large;
        float: inline-end;
    }
    .embed-toggle:hover {
        cursor: pointer;
    }
    

}
</style>