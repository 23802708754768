<template>
    <div class="text-areas">
        <sh-single-line-text-input
            :modelValue="user.username"
            v-model="username"
            :placeholder="$t('common.username')"
        />
        <sh-multi-line-text-input
            :modelValue="user.description"
            v-model="description"
            :placeholder="$t('common.description')"
        />
        <sh-checkbox
            v-model="isPrivate"
        > {{ $t('common.is-private') }} </sh-checkbox>
        <sh-checkbox
            v-model="isFederated"
        > {{ $t('common.is-federated') }} </sh-checkbox>
        <sh-filled-button class="submit-button" @click="submitUpdate">
            {{ $t('common.edit') }}
        </sh-filled-button>
    </div>
</template>
<script>
import ShSingleLineTextInput from "@/components/common/sh-single-line-text-input.vue"
import ShMultiLineTextInput from "@/components/common/sh-multi-line-text-input.vue"
import ShCheckbox from "@/components/common/sh-checkbox.vue"
import ShFilledButton from "@/components/common/sh-filled-button.vue"
import { updateUser } from "@/services/api/user-api.service"
    export default {
        name: "text-areas",
        components: {
            "sh-single-line-text-input": ShSingleLineTextInput,
            "sh-multi-line-text-input": ShMultiLineTextInput,
            "sh-filled-button": ShFilledButton,
            "sh-checkbox": ShCheckbox,
        },
        props: {
            user: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                username: null,
                description: null,
                isPrivate: false,
                isFederated: false
            }
        },
        methods: {
            submitUpdate() {
                updateUser({
                    Id: "45e7031e-ccf4-46a5-8927-6d73b9680f21",
                    username: this.username,
                    description: this.description,
                    isPrivate: this.isPrivate,
                    isFederated: this.isFederated,
                })
            }
        },
        created() {
            this.isPrivate = this.user.isPrivate;
            this.isFederated = this.user.isFederated;
        }
    }
</script>
<style lang="scss" scoped>
.text-areas {
    width: 75%;
    .submit-button {
        margin-left: auto;
        text-align: center;
        width: 10%;
        
    }
}
</style>