<template>
    <div class="message-view">
        <div class="form-footer">
            <sh-floating-search
                ref="floatingSearch"
                class="search-area"
                @clicked="addText"
                :searchQuery="searchQuery"
                :type="searchType"
                isInverted
            />
            <sh-multi-line-text-input
                    v-model="body"
                    @focusin="addListener"
                    @focusout="removeListener"
                    :placeholder="$t('component.form.body')"
            />
            <sh-filled-button 
                class="submit-btn"
                @click="submit"
            >
                {{ $t('common.submit') }}
            </sh-filled-button>
        </div>
        <div class="message-wrapper">
            <chat-message
                v-for="message in messages"
                :key="message.id"
                :message="message"
            />
            <sh-filled-button 
                v-if="messages.length > 10"
                class="submit-btn"
                @click="fetchOlderMessages"
            >
                {{ getLabel }}
            </sh-filled-button>
        </div>
    </div>
</template>

<script>

import ShFilledButton from "@/components/common/sh-filled-button.vue";
import ChatMessage from "@/components/chat-message/chat-message.vue";
import ShFloatingSearch from "@/components/common/sh-floating-search.vue";
import ShMultiLineTextInput from "@/components/common/sh-multi-line-text-input.vue";

import { getMessagesSince, getMessages, createMessage, getMessagesPage} from "@/services/api/chat-api.service.js"
import { mapActions } from "vuex";
    export default {
        name: "MessageView",
        components: {
            "sh-floating-search": ShFloatingSearch,
            "sh-multi-line-text-input": ShMultiLineTextInput,
            "sh-filled-button": ShFilledButton,
            "chat-message": ChatMessage,
        },
        data() {
            return {
                body: '',
                messages: [],
                interval: null,
                page: 1,
                canLoadMore: true
            }
        },
        computed: {
            getLabel() {
                if (this.canLoadMore) {
                    return this.$t('common.load-more');
                } else {
                    return this.$t('common.no-more');
                }
            }
        },
        methods: {
            ...mapActions({
                markChatRead: "Chats/markRead"
            }),
            submit() {
                if (this.body == null || this.body == "") { return; }
                createMessage({
                    chatId: this.$route.params.id,
                    body: this.body
                });
                this.body = '';
            },
            async getMessages() {
                this.messages = await getMessages(this.$route.params.id);
                this.messages.sort((a,b) => {
                        return new Date(b.createdDate) - new Date(a.createdDate)
                });
                this.interval = setInterval(this.fetchNewMessages, 1000);
            },
            async fetchOlderMessages() {
                if (!this.canLoadMore) { return; }
                var messages = await getMessagesPage(this.$route.params.id, this.page); 
                if (messages.length <= 0) {
                    this.canLoadMore = false;
                    return;
                }
                this.messages = [...new Set([...this.messages, ...messages])];
                this.messages.sort((a,b) => {
                    return new Date(b.createdDate) - new Date(a.createdDate)
                });
                this.page++;
            },
            async fetchNewMessages() {
                if (this.messages.length <= 0) { 
                    this.messages = await getMessages(this.$route.params.id); 
                    if (this.messages.length > 0) {
                        this.messages.sort((a,b) => {
                            return new Date(b.createdDate) - new Date(a.createdDate)
                        });
                    }
                    return; 
                }
                var messages = await getMessagesSince(this.$route.params.id, this.messages[0].id);
                if (messages == null) { return; }
                if (messages.length > 0) {
                    this.messages = [...new Set([...this.messages, ...messages])];
                    this.messages.sort((a,b) => {
                        return new Date(b.createdDate) - new Date(a.createdDate)
                    });
                    this.markRead();
                }
            },
            markRead() {
                this.markChatRead(this.$route.params.id);
            },
            addListener() {
                window.addEventListener('keydown',this.handleKeyPress)
                window.addEventListener('paste',this.handlePaste)
            },
            removeListener() {
                window.removeEventListener('keydown',this.handleKeyPress)
                window.removeEventListener('paste',this.handlePaste)
            },
            
            handleKeyPress(e) {
                if (e.key == "Enter" && this.previousKey != 'Shift' && !this.ongoingSearchQuery) {
                    e.preventDefault();
                    this.submit();
                }
                this.previousKey = e.key;
                if(this.ongoingSearchQuery) {
                    if (e.key.length > 1) { this.handleBigKeyPress(e); return; }
                    if (e.key == ' ' || e.key == '@' || e.key == ':' || e.key == '/') {
                        this.endSearchQuery();
                    } else {
                        this.addSearchQuery(e);
                    }
                    if (this.value == '') { this.endSearchQuery(); }
                    return;
                }
                if (e.key == '@') {
                    this.ongoingSearchQuery = true;
                    this.searchType = 'user';
                } else if (e.key == ":") {
                    this.ongoingSearchQuery = true;
                    this.searchType = 'emoji';
                }
            },
            handleBigKeyPress(e) {
                if (e.key != "Backspace") { 
                    e.preventDefault(); 
                } else {
                    this.searchQuery = this.searchQuery.slice(0, -1); 
                }
                if (this.searchQuery.length < 2) {
                    this.endSearchQuery();
                }   
            },
            addSearchQuery(e) {
                if (e.key.length > 1) { return; }
                this.searchQuery += e.key
                if (this.searchQuery.length > 2) {
                    if (this.searchType == 'user') {
                        this.$refs['floatingSearch'].searchUser()
                    } else if (this.searchType == "emoji") {
                        this.$refs['floatingSearch'].searchEmotes()
                    }
                }
            },
            endSearchQuery() {
                this.ongoingSearchQuery = false;
                this.searchQuery = '';
                this.$refs['floatingSearch'].disable()
            },
            addText(value) {
                let regex
                if(this.searchType == "emoji") {
                    regex = new RegExp(`:${this.searchQuery}\\b`);
                } else if (this.searchType == "user") {
                    regex = new RegExp(`@${this.searchQuery}\\b`);
                }
                
                if (this.searchType == "emoji") {
                    this.body = this.body.replace(regex,`:${value}:`)
                }
                else if (this.searchType == "user") {
                    this.body = this.body.replace(regex,`@${value} `)
                }

                this.endSearchQuery();
            }
        },
        created() {
            this.getMessages();
            this.markRead();

        },
        beforeUnmount() {
            clearInterval(this.interval);
            console.log('wat')
            this.interval = null;
        }
    }
</script>
<style scoped lang="scss">
.message-view {
    width: 40rem;
    height: 94vh;
    background-color: var(--panelHover);
    border-radius: 0.25rem;
    box-shadow: 0px 1px 3px 3px var(--shadow);
    display: flex;
    flex-direction: column-reverse;
    .message-wrapper {
        box-shadow: inset 0 0 3px 2px var(--shadow);
        height: 100%;
        margin: 0.5rem 0.25rem 1rem;
        display: flex;
        flex-direction: column-reverse;
        overflow-y: auto;
    }
    .form-footer {
        align-self: center;
        width: 95%;
        margin: 0.5rem 0.1rem;
        position: relative;
        .search-area {
            top: 11.5rem;
        }
        .submit-btn {
            margin-left: auto;
        }
    }
}
</style>