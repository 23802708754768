

const _state = {
    settings: {}
}

const _getters = {
    getSettings(state) { return state.settings; }
}
const _actions = {
    setSettings({ state }, settings) {
        state.settings = settings;
    }
}

const siteSettings = {
    namespaced: true,
    state: _state,
    getters: _getters,
    actions: _actions
}

export default siteSettings;
