<template>
    <div class="theme-selector">
        <div
            v-for="theme in getThemes"
            :key="theme.name"
        >
            {{  theme.name }}
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
    export default {
        name: "theme-selector",
        components: {
        },
        computed: {
            ...mapGetters({
                getThemes: "User/getThemes",
            })
        },
    }
</script>
<style lang="scss">
.theme-buttons {
    margin: 1rem;
    display: flex;
    column-gap: 3rem;
    justify-content: center;
}
.section {
    display: inline-grid;
}
</style>