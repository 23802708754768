<template>
    <div 
        class="form-panel" :style="getStyle" 
        v-on:dragenter="isDragging = true"
        @dragover.prevent
        @drop.stop="handleDrag"    
    >
        <div class="upload-overlay" v-if="isDragging" v-on:dragleave="isDragging = false"><span class="icon-document-upload"></span></div>
        <div class="top">
            <div class="example-post" v-if="showExample">
                <post-body
                    :body="postData.body"
                    :type="postData.type"
                />
            </div>
            <div 
                class="example-button" 
                @click="showExample = !showExample"
                v-html="getExampleLabel"
            ></div>
            <sh-single-line-text-input
                v-model="postData.title"
                :placeholder="$t('component.form.subject')"
            />
            <sh-multi-line-text-input
                v-model="postData.body"
                @focusin="addListener"
                @focusout="removeListener"
                :placeholder="$t('component.form.body')"
            >
            </sh-multi-line-text-input>           
        </div>    
        <div class="middle">    
            <sh-floating-search
                ref="floatingSearch"
                class="search-area"
                @clicked="addText"
                :searchQuery="searchQuery"
                :type="searchType"
            />
            <sh-icon-button
                v-for="icon in allowedVisibilities"
                small
                noBackground
                noHover
                :class="getSelectedVisibility(icon.value)"
                :key="icon.value"
                :icon="icon.icon"
                @click="selectVisibility(icon.value)"
            />
            <sh-dropdown-list
                class="types"
                listClass="post-types-list"
                :listItems="getPostTypes"
                @clicked="selectPostType"
            />
        </div>
        <div class="options">
            <sh-checkbox v-model="postData.isSpoilered">{{ $t('component.form.spoiler')}}</sh-checkbox>    
            <sh-checkbox v-if="canFederate" v-model="postData.isFederated">{{ $t('component.form.federated')}}</sh-checkbox>    
        </div>
        <div class="upload-progress-bar" v-if="getLargeUpload">
            <div class="upload-progress" :style="getUploadProgress">
            </div>
        </div>
        <div class="footer">
            <sh-loading
                v-if="isUploading"
                class="loading-icon"
            />
            <sh-icon-button
                small
                noBackground
                customSize=3
                fontSize="xx-large"
                icon="icon-upload3"
                :class="isUploading ? 'disabled' : ''" 
                @click="isUploading ? null : $refs['fileUpload'].click()"
            />
            <sh-icon-button
                small
                noBackground
                customSize=3
                fontSize="xx-large"
                icon="icon-smile"
                @click="displayEmojiPicker = !displayEmojiPicker"
            />
            <sh-emoji-picker
                @closed="displayEmojiPicker = false"
                @selected="addEmote"
                :show="displayEmojiPicker"
            />
            <sh-icon-button
                small
                noBackground
                customSize=3
                fontSize="xx-large"
                icon="icon-insert_chart"
                @click="togglePoll"
            />
            <sh-icon-button
                v-if="false"
                small
                noBackground
                customSize=3
                @click="displayMentionSearch = true"
                fontSize="xx-large"
                icon="icon-users"
            >
                <sh-user-search 
                    :show="displayMentionSearch"
                    @close="displayMentionSearch = false"
                    @addMention="addMention"
                >
                </sh-user-search>
            </sh-icon-button>
            <sh-filled-button class="submit-button" :class="isUploading ? 'disabled' : ''" @click="submit" borderRadius=".25">
                {{ getButtonLabel }}
            </sh-filled-button>
        </div>
        <sh-poll
            v-if="hasPoll"
            :poll="postData.pollOptions"
            isEditing
            @uploadPollMedia="uploadPollMedia"
            @changeLabel="changePollOptionLabel"
            @addOption="addPollOption"
            @delete="removePollOption"
        />
        <sh-media-gallery
            :media="postData.media"
            editing
            maxPerRow="1"
            @delete="removeMedia"
            @updateDescription="updateDescription"
            @updateNsfw="updateNsfw"
        >
        </sh-media-gallery>
        <input 
            ref="fileUpload"
            class="hidden"
            type="file"
            multiple
            @change="getFiles"
        >
    </div>
</template>

<script>
import ShCheckbox from "@/components/common/sh-checkbox.vue";
import ShFilledButton from "@/components/common/sh-filled-button.vue";
import ShDropdownList from "@/components/common/sh-dropdown-list.vue";
import ShIconButton from "@/components/common/sh-icon-button.vue";
import ShSingleLineTextInput from "@/components/common/sh-single-line-text-input.vue";
import ShMultiLineTextInput from "@/components/common/sh-multi-line-text-input.vue";
import ShMediaGallery from "@/components/common/sh-media-gallery.vue";
import ShEmojiPicker from "@/components/common/sh-emoji-picker.vue";
import ShFloatingSearch from "@/components/common/sh-floating-search.vue";
import ShUserSearch from "@/components/common/sh-user-search.vue";
import ShLoading from "@/components/common/sh-loading.vue";
import ShPoll from "@/components/common/sh-poll.vue";
import PostBody from "@/components/post-body/post-body.vue";

import visibilities from "@/entities/Visibilities";
import postTypes from "@/entities/PostTypes";
import { createPost, editPost } from "@/services/api/post-api.service.js"
import { filterList } from "@/services/permission-handler.service"
import { uploadFile } from "@/services/api/upload-api.service"
import { mapActions, mapGetters } from "vuex"

    export default {
        name: "form-panel",
        components: {
            "sh-filled-button": ShFilledButton,
            "sh-icon-button": ShIconButton,
            "sh-single-line-text-input": ShSingleLineTextInput,
            "sh-multi-line-text-input": ShMultiLineTextInput,
            "sh-media-gallery": ShMediaGallery,
            "sh-dropdown-list": ShDropdownList,
            "sh-emoji-picker": ShEmojiPicker,
            "sh-floating-search": ShFloatingSearch,
            "sh-user-search": ShUserSearch,
            "sh-loading": ShLoading,
            "sh-poll": ShPoll,
            "sh-checkbox": ShCheckbox,
            "post-body": PostBody
        },
        data() {
            return {
                showExample: false,
                hasPoll: false,
                pollIndex: 0,
                isUploading: false,
                isDragging: false,
                ongoingSearchQuery: false,
                searchSelection: 0,
                searchType: 'user',
                searchQuery: '',
                displayEmojiPicker: false,
                displayMentionSearch: false,
                allowedVisibilities: [],
                postData: {
                    isSpoilered: false,
                    isFederated: false,
                    title: null,
                    body: '',
                    folder: null,
                    visibility: 'Public',
                    type: 'Text',
                    reply: null,
                    media: [
                    ],
                    pollOptions: [
                    ]
                }
            }
        },
        props: {
            width: {
                type: Number,
                default: 100
            },
            replyUrl: {
                type: String,
                default: null
            },
            replyHandles: {
                type: Object,
                default: null
            },
            isEditing: {
                type: Boolean,
                default: false
            },
            post: {
                type: Object,
                required: false
            }
        },
        computed: {
            ...mapGetters({
                getLargeUpload: 'Upload/getLargeUpload',
                getTotalParts: 'Upload/getTotalParts',
                getCurrentPart: 'Upload/getCurrentPart',
                getUser: 'User/getUser',
            }),
            canFederate() {
                return this.getUser.isFederated;
            },
            getUploadProgress() {
                var progress = (this.getCurrentPart/this.getTotalParts) * 100;
                return `width: ${progress}%`;
            },
            getStyle() {
                return `width: ${this.width}%;`
            },
            getPostTypes() {
                return postTypes
            },
            getButtonLabel() {
                if (this.isEditing) {
                    return this.$t('component.form.edit');
                } else {
                    return this.$t('component.form.submit')
                }
            },
            getExampleLabel() {
                if (this.showExample) {
                    return this.$t('component.form.hide-example');
                } else {
                    return this.$t('component.form.show-example')
                }
            }
        },
        methods: {
            ...mapActions({
                addAlert: "Alert/addAlert",
                setLargeUpload: 'Upload/setLargeUpload',
                setTotalParts: 'Upload/setTotalParts',
                setCurrentPart: 'Upload/setCurrentPart',
                resetLargeUploads: 'Upload/reset',
            }),
            togglePoll() {
                this.hasPoll = !this.hasPoll;

                if (this.hasPoll) {
                    this.addPollOption();
                    this.addPollOption();
                } else {
                    this.postData.pollOptions = [];
                    this.pollIndex = 0;
                }
            },
            changePollOptionLabel(e) {
                this.postData.pollOptions[e.index].label = e.label
            },
            addPollOption() {
                this.pollIndex++;
                this.postData.pollOptions.push({
                    number: this.pollIndex,
                    label: null,
                    media: null
                })
            },
            removePollOption(index) {
                this.postData.pollOptions.splice(index, 1);
            },
            addListener() {
                window.addEventListener('keydown',this.handleKeyPress)
                window.addEventListener('paste',this.handlePaste)
            },
            removeListener() {
                window.removeEventListener('keydown',this.handleKeyPress)
                window.removeEventListener('paste',this.handlePaste)
            },
            addEmote(value) {
                this.postData.body += `:${value}:`
            },
            selectPostType(value) {
                this.postData.type = value;
            },
            selectVisibility(value) {
                this.postData.visibility = value;
            },
            getSelectedVisibility(value) {
                if (this.postData.visibility == value) {
                    return 'active';
                } else {
                    return '';
                }
            },
            updateDescription(updateObject) {
                var updateIndex = this.postData.media.indexOf(updateObject.item);
                this.postData.media[updateIndex].description = updateObject.value
            },
            updateNsfw(updateObject) {
                var updateIndex = this.postData.media.indexOf(updateObject.item);
                this.postData.media[updateIndex].isNsfw = updateObject.value

            },
            addPollMedia(media, index) {
                this.postData.pollOptions[index].media = media;
                this.postData.pollOptions[index].hasMedia = true;

                if (this.postData.pollOptions.length >= 2) {
                    this.$emit('enableScrolling');
                }
            },
            addMedia(media) {
                this.postData.media.push(media);

                if (this.postData.media.length >= 2) {
                    this.$emit('enableScrolling');
                }
            },
            removeMedia(media) {
                var removeIndex = this.postData.media.indexOf(media);
                this.postData.media.splice(removeIndex, 1);

                if (this.postData.media.length < 2) {
                    this.$emit('disableScrolling');
                }
            },
            async uploadPollMedia(e) {
                var files = e.event.target.files;
                var file = files[0]
                
                var response = await uploadFile({
                    name: file.name,
                    folder: this.postData.folder,
                    file: file
                });
                if (!response) { return; }
                if (!this.postData.folder) {
                    this.postData.folder = response.folder;
                }
                this.addPollMedia(response, e.index);
            },
            async getFiles(event) {
                this.isUploading = true;
                var files = event.target.files;
                if (files.length > 1) {
                    this.setLargeUpload(true);
                    this.setTotalParts(files.length);
                }
                for(var i = 0; i < files.length; i++) {
                    this.setCurrentPart(i);
                    await this.uploadFile(files[i]);
                }
                this.resetLargeUploads();
                this.isUploading = false;
            },
            async uploadFile(file) {
                var response = await uploadFile({
                    name: file.name,
                    folder: this.postData.folder,
                    file: file
                });
                if (!response) { return; }
                if (!this.postData.folder) {
                    this.postData.folder = response.folder;
                }
                this.addMedia(response);
            },
            validateInput() {
                if (!this.postData.title && !this.postData.body && this.postData.media.length <= 0) {
                    return false;
                } else {
                    return true;
                }
            },
            clearInput() {
                this.postData.title = null;
                this.postData.body = '';
                this.postData.visibility = "Public";
                this.postData.type = "Text";
                this.postData.reply = null;
                this.postData.isSpoilered = false;
                this.postData.isFederated = false;
                this.postData.media = [];
                this.postData.pollOptions = [];
                this.postData.folder = null;
                this.hasPoll = false;
            },
            async submit() {
                if (!this.validateInput() || this.isUploading) {
                    return;
                }

                if (this.isEditing) {
                    var updatedPost = await editPost(this.postData);
                    this.$emit("edited", updatedPost);
                } else {
                    createPost(this.postData);
                }
                this.clearInput();
                this.$emit('submitted');
            },
            handleDrag(e) {
                this.isDragging = false;
                e.preventDefault();
                if(!e.dataTransfer && !e.dataTrasfer.types.includes("Files")) 
                    return;

                
                var fakeEvent = {};
                fakeEvent.target = {};

                fakeEvent.target.files = e.dataTransfer.files;
                if(fakeEvent.target.files.length > 0)
                    this.getFiles(fakeEvent);
            },
            handlePaste(e) {
                if(e.clipboardData.files.length > 0) {
                    e.preventDefault();
                    var fakeEvent = {};
                    fakeEvent.target = {};

                    fakeEvent.target.files = e.clipboardData.files;
                    this.getFiles(fakeEvent)
                }
            },
            handleKeyPress(e) {
                if(this.ongoingSearchQuery) {
                    if (e.key.length > 1) { this.handleBigKeyPress(e); return; }
                    if (e.key == ' ' || e.key == '@' || e.key == ':' || e.key == '/') {
                        this.endSearchQuery();
                    } else {
                        this.addSearchQuery(e);
                    }
                    if (this.postData.body == '') { this.endSearchQuery(); }
                    return;
                }
                if (e.key == '@') {
                    this.ongoingSearchQuery = true;
                    this.searchType = 'user';
                } else if (e.key == ":") {
                    this.ongoingSearchQuery = true;
                    this.searchType = 'emoji';
                }
            },
            handleBigKeyPress(e) {
                if (e.key != "Backspace") { 
                    e.preventDefault(); 
                } else {
                    this.searchQuery = this.searchQuery.slice(0, -1); 
                }
                if (this.searchQuery.length < 2) {
                    this.endSearchQuery();
                }   
            },
            addSearchQuery(e) {
                if (e.key.length > 1) { return; }
                this.searchQuery += e.key
                if (this.searchQuery.length > 2) {
                    if (this.searchType == 'user') {
                        this.$refs['floatingSearch'].searchUser()
                    } else if (this.searchType == "emoji") {
                        this.$refs['floatingSearch'].searchEmotes()
                    }
                }
            },
            endSearchQuery() {
                this.ongoingSearchQuery = false;
                this.searchQuery = '';
                this.$refs['floatingSearch'].disable()
            },
            addText(value) {
                let regex
                if(this.searchType == "emoji") {
                    regex = new RegExp(`:${this.searchQuery}\\b`);
                } else if (this.searchType == "user") {
                    regex = new RegExp(`@${this.searchQuery}\\b`);
                }
                
                if (this.searchType == "emoji") {
                    this.postData.body = this.postData.body.replace(regex,`:${value}:`)
                }
                else if (this.searchType == "user") {
                    this.postData.body = this.postData.body.replace(regex,`@${value} `)
                }

                this.endSearchQuery();
            }
        },
        async created() {
            this.allowedVisibilities = await filterList(visibilities, { checkPost: false }, {checkPoster: false}, true);
        },
        mounted() {
            this.postData.reply = this.replyUrl;
            if (this.replyUrl) {
                if (this.replyHandles.length > 0) {
                    this.postData.body = ''
                    this.replyHandles.forEach((e) => {
                        this.postData.body += `@${e} `
                    })
                }
            } else if (this.isEditing) {
                this.postData.body = this.post.body;
                this.postData.media = this.post.media;
                this.postData.title = this.post.title;
                this.postData.visibility = this.post.visibility;
                this.postData.type = this.post.type;
                this.postData.id = this.post.id;
                this.postData.folder = this.post.folder;
            }
        }
    }
</script>

<style lang="scss">
.disabled {
    background-color: var(--background) !important;
    color: var(--disabled) !important;
}
.form-panel {
    position: relative;
    display: grid;
    justify-items: center;
    margin-left: auto;
    margin-right: auto;
    row-gap: .5rem;
    margin-top: .2rem;
    .upload-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        border-style: dashed;
        border-width: thin;
        padding: 0.2rem;
        z-index: 5;
        background-color: var(--panelTransparent);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: xxx-large;
        color: var(--text);
    }
    .top {
        width: 100%;
        display: grid;
        row-gap: 0.4rem;
        position: relative;
        .example-button {
            font-size: xx-small;
            text-align: left;
            color: var(--link);
            user-select: none;
            margin-bottom: .1rem;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .middle {
        position: relative;
        align-items: center;
        display: flex;
        width: 100%;
        .search-area {
            top: 0;
            z-index: 500;
        }
        .active {
            color: var(--activeIcon);
        }
        .types {
            margin-left: auto;
            .post-types-list {
                right: 0;
            }
        }
    }
    .options {
        width: 100%;
    }
    .upload-progress-bar {
        width: 100%;
        height: 10px;
        background-color: var(--panel);
        border-radius: .25rem;
        .upload-progress {
            background-color: var(--uploadProgress);
            height: 100%;
            border-radius: .25rem;
            width: 0%;
        }
    }
    .footer {
        position: relative;
        display: flex;
        width: 100%;
        .loading-icon {
            margin-right: 3rem;
        }
        .submit-button {
            margin-left: auto;
            width: auto;
        }
    }
}

</style>