<template>
    <div class="sh-checkbox" @click="this.value = !this.value">
        <span v-if="left" class="sh-checkbox-label"><slot></slot></span>
        <div
            class="checkbox"
            :class="isToggled"
        >
            <span v-if="value" >✓</span>
        </div>
        <span v-if="!left" class="sh-checkbox-label"><slot></slot></span>
    </div>
</template>

<script>
    export default {
        name: "sh-checkbox",
        props: {
            left: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                required: false
            },
            modelValue: {
                type: Boolean,
                required: false
            },
        },
        emits: ['update:modelValue'],
        computed: {
            isToggled() {
                if(this.value) {
                    return 'toggled'
                } else {
                    return 'untoggled'
                }
            },
            value: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            }
        }
    }
</script>

<style lang="scss">
.sh-checkbox {
    align-items: center;
    column-gap: .5rem;
    display: flex;    
    user-select: none;
    width: fit-content;
    .sh-checkbox-label {
        color: var(--buttonText);
    }
    .checkbox {
        display: flex;
        justify-content: center;
        border-radius: 0.25rem;
        border-style: none;
        background-color: var(--inputBackground);
        height: 1rem;
        width: 1rem;
    }
    &:hover {
        cursor: pointer;
    }
    &:focus-visible {
        outline-style: solid;
        outline-color: var(--inputOutline);
    }
}
</style>