<template>
    <span v-if="disableGreentext">{{ text }}</span>
    <span v-else :class="getClass">{{ text }}</span>
</template>
<script>
export default {
    name: "sh-greentext",
    props: {
        text: {
            type: String,
            required: true
        },
        reverseGreentext: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        disableGreentext() {
            return false
            // return this.getSettings.disableGreentext
        },
        getClass() {
            if(this.reverseGreentext)
                return 'reverse-greentext'
            else
                return 'greentext'
        }
    }
}
</script>
<style lang="scss">
.greentext {
    color: var(--greentext);
}
.reverse-greentext {
    color: var(--reverseGreentext);
}
</style>