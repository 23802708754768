<template>
    <div class="chat-message" :class="isOwnMessage ? 'own' : ''">
        <sh-profile-picture
            :src="message.poster.url"
            :title="message.poster.handle"
            rounded
            width=2
        />
        <div class="message-data">
            <div class="message-body">
                <post-body
                    :body="message.body"
                />
            </div>
            <div class="timestamp" ref="timestamp"  :datetime="getDate"></div>
        </div>
    </div>
</template>

<script>
import ShProfilePicture from "@/components/common/sh-profile-picture.vue"
import PostBody from "@/components/post-body/post-body.vue"
import { mapGetters } from "vuex"
import { render, cancel } from "timeago.js"
    export default {
        name: "chat-message",
        components: {
            "sh-profile-picture" : ShProfilePicture,
            "post-body" : PostBody,
        },
        props: {
            message: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters({
                getLanguage: 'Settings/getLanguage',
                getUser: 'User/getUrl'
            }),
            getDate() {
                return new Date(`${this.message.createdDate}Z`)
            },
            isOwnMessage() {
                return (this.getUser == this.message.poster.url)
            }
        },
        methods: {
            startRender() {
                render(this.$refs['timestamp'], this.getLanguage)
            },
            cancelRender() {
                cancel(this.$refs['timestamp'].$el)
            }
        },
        mounted() {
            this.startRender();
        },
        beforeUnmount() {
            this.cancelRender();
        },
    }
</script>
<style lang="scss">
.chat-message {
    display: flex;
    margin: 1rem;
    column-gap: 0.5rem;
    .message-data {
        .body {
            margin: 0;
        }
        .timestamp {
            font-size: xx-small;
            text-align: right;
        }
    }
    &.own {
        flex-direction: row-reverse;
    }
}

</style>