
import Favicon from "@/entities/Favicon";

const _state = {
    unreadNotifications: 0,
    faviconBadge: new Favicon({})
}

const _getters = {
    getUnreadNotificationCount(state) {  return state.unreadNotifications; }
}
const _actions = {
    set({ state }, amount) {
        state.unreadNotifications = amount;
        state.faviconBadge.value = state.unreadNotifications;
    },
    add({ state }, amount) {
        state.unreadNotifications += amount;
        state.faviconBadge.value = state.unreadNotifications;
    },
    remove({ state }, amount) {
        state.unreadNotifications -= amount;
        state.faviconBadge.value = state.unreadNotifications;
    },
}

const favicon = {
    namespaced: true,
    state: _state,
    getters: _getters,
    actions: _actions
}

export default favicon;
