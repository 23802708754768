/* eslint-disable */
import store from "@/store";
import DefaultTheme from '@/themes/defaultTheme.json'
import { loadBackground } from "./api/file-api.service";

const themeIndex = 'index-max'

const simpleThemeRender = (passedTheme) => {
    let theme = passedTheme ? passedTheme : DefaultTheme

    let rules = ""
    Object.keys(theme).forEach(function(key,value) {
        if(key !== 'version') {
            // Spoiler color must be text at full alpha
            if(key == 'text') {
                rules += `--spoiler: ${rgbObjectToString(hexToRgb(theme[key].color,1))};`
            }
            // NavBar color must be text at full alpha
            if(key == 'panelHeader') {
                rules += `--navbar: ${rgbObjectToString(hexToRgb(theme[key].color,1))};`
            }
            rules += `--${key}: ${rgbObjectToString(hexToRgb(theme[key].color,theme[key].alpha))};`
        }
    })
    setTheme(`:root { ${rules}`)
}

const setAbstractRules = (theme) => {
    theme['notificationHiddenColor'].color = theme['text'].color

    return theme
}
const renderTheme = () => {
    let theme = store.getters['Theme/getTheme']
    let defaultTheme = store.getters['Theme/getDefaultTheme']

    if(theme.version < defaultTheme.version) {
        updateTheme(theme,defaultTheme)
    }
    let rules = ""
    Object.keys(theme.other).forEach(function(key,value) {
        rules += `--${key}:${theme.other[key]}` + ";"
    })
    rules += colourRules(theme.colour)
    setTheme(`:root { ${rules}}`)
    store.commit('Theme/setIsChanged',false)
}
const setTheme = (ruleString) => {
    let styleInj
    if(document.getElementById('customRules')) {
        styleInj = document.getElementById('customRules')
    } else {
        styleInj = document.createElement('style')
        styleInj.setAttribute("id", 'customRules')
    }
    document.head.appendChild(styleInj)
    let style = styleInj.sheet
    style.insertRule(ruleString,themeIndex)
}
function setBackground(backgroundRule) {
    let styleInj
    if(document.getElementById('customBackground')) {
        styleInj = document.getElementById('customBackground')
    } else {
        styleInj = document.createElement('style')
        styleInj.setAttribute("id", 'customBackground')
    }
    document.head.appendChild(styleInj)
    let style = styleInj.sheet
    style.insertRule(backgroundRule,'index-max')
}
async function writeBackground(folder, file) {
    if(store.getters['Settings/getDisableBackground']) {
        setBackground(` body { background-image: none;  }`)
        return
    }
    let finalUrl = loadBackground(folder, file);
    setBackground(`
        body {
            background-image: url(${finalUrl});
            background-size: cover;
            background-attachment: fixed;
        }
    `)
}
function updateTheme(themeToUpdate,defaultTheme) {
    updateThemeOther(themeToUpdate,defaultTheme)
    updateThemeColour(themeToUpdate,defaultTheme)
    themeToUpdate.version = defaultTheme.version
    store.dispatch("Theme/saveThemeService",
        { isLoggedIn: store.getters['User/getLoggedIn'], theme: themeToUpdate }
    )
}

function updateThemeOther(themeToUpdate,defaultTheme) {
    let defaultKeys = []
    let curKeys = []
    Object.keys(defaultTheme.other).forEach(function(baseKey,baseValue) {
        defaultKeys.push(baseKey)
    })
    Object.keys(themeToUpdate.other).forEach(function(key,value) {
        curKeys.push(key)
    })
    if(defaultKeys.length == curKeys.length) {
        return
    }
    let newKeys = defaultKeys.filter((key) => !curKeys.includes(key))

    for(let i = 0; i < newKeys.length; i++) {
        themeToUpdate.other[newKeys[i]] = defaultTheme.other[newKeys[i]]
    }
}
function updateThemeColour(themeToUpdate,defaultTheme) {
    let defaultKeys = []
    let curKeys = []
    Object.keys(defaultTheme.colour).forEach(function(baseKey,baseValue) {
        defaultKeys.push(baseKey)
    })
    Object.keys(themeToUpdate.colour).forEach(function(key,value) {
        curKeys.push(key)
    })
    if(defaultKeys.length == curKeys.length) {
        return
    }
    let newKeys = defaultKeys.filter((key) => !curKeys.includes(key))

    for(let i = 0; i < newKeys.length; i++) {
        themeToUpdate.colour[newKeys[i]] = defaultTheme.colour[newKeys[i]]
    }
}


function colourRules(object) {
    let rules = '' 
    Object.keys(object).forEach(function(key,value) {
        let newValue = rgbObjectToString(hexToRgb(object[key].color,object[key].opacity))
        rules += `--${key}:${newValue}` + ";"
    })
    return rules
}
function hexToRgb(hex,alpha) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: parseFloat(alpha)
    } : null;
}
function rgbObjectToString(rgb) {
    if(rgb == null) {
        rgb = {}
        rgb.r = 0
        rgb.g = 0
        rgb.b = 0
        rgb.a = 1
    }
    let string = "rgba("
    string += rgb.r + ","
    string += rgb.g + ","
    string += rgb.b + ","
    string += rgb.a + ")"
    return string
}
const style = {
    simpleThemeRender,
    renderTheme,
    hexToRgb,
    rgbObjectToString,
    writeBackground
}

export {
    simpleThemeRender,
    writeBackground
}

export default style