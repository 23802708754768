import { url, handleError } from './api.service'
import i18n from '@/i18n';
import store from '@/store';

const SHAKOU_UPLOAD = '/api/upload';
const SHAKOU_UPLOAD_MULTIPART = '/api/upload/multipart';

async function uploadFile(fileData) {
    const formData = new FormData();
    formData.append('File', fileData.file);
    formData.append('Name', fileData.name);
    if (fileData.folder) {
        formData.append('Folder', fileData.folder);
    }

    // TODO: Fix hardcoded filesize (20mbs)
    if (fileData.file.size > 20000000) {
        store.dispatch('Upload/setLargeUpload',true);
        return await multipartUpload(fileData);
    }

    var response = await fetch(url(SHAKOU_UPLOAD), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : 'Bearer ' + store.state.Authentication.token)
        },
        body: formData
    })

    if (response.status != 200) {
        handleError(response, i18n.global.t('error.bad-file'), fileData);
        return null;
    }

    return await response.json();
}

async function multipartUpload(fileData) {
    var fileSize = fileData.file.size
    var sliceStart = 0
    var fileSlice = 5000000 // 5mbs
    var filePart = 0
    var folder = fileData.folder
    var finalPart = false
    var response = null

    var slices = Math.round(fileSize / fileSlice);
    store.dispatch('Upload/setTotalParts',slices);
    for (;filePart < slices; filePart++) {
        store.dispatch('Upload/setCurrentPart',filePart);
        
        var sliceEnd = sliceStart + fileSlice;

        // Final part
        if (filePart == (slices-1)) {
            sliceEnd = fileSize;
            finalPart = true;
        }

        var chunk = CreateChunk(fileData.file, sliceStart, sliceEnd);
        response = await uploadMultipart({
            folder: folder,
            name: fileData.name,
            part: String(filePart).padStart(2,'0'),
            finalPart: finalPart,
            file: chunk
        });

        if (response.status != 200) { console.log(response); store.dispatch('Upload/reset'); return; }
        if (folder == null) {
            var j = await response.json();
            folder = j.folder;
        }
        sliceStart += fileSlice;
    }
    store.dispatch('Upload/reset')
    return await response.json();
}

async function uploadMultipart(fileData) {
    const formData = new FormData();
    formData.append('File', fileData.file);
    formData.append('Name', fileData.name);
    formData.append('Part', fileData.part);
    formData.append('FinalPart', fileData.finalPart);
    if (fileData.folder) {
        formData.append('Folder', fileData.folder);
    }

    return await fetch(url(SHAKOU_UPLOAD_MULTIPART), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : 'Bearer ' + store.state.Authentication.token)
        },
        body: formData
    })
}

function CreateChunk(file, sliceStart, sliceEnd) {
    // Get the correct browser slice function
    var slice = file.mozSlice ? file.mozSlice : file.webkitSlice ? file.webkitSlice : file.slice;
    return slice.bind(file)(sliceStart,sliceEnd);
}

export {
    uploadFile,
}
