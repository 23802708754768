

const _state = {
    currentPart: 0,
    totalParts: 0,
    largeUpload: false,

}

const _getters = {
    getTotalParts(state) { return state.totalParts; },
    getCurrentPart(state) { return state.currentPart; },
    getLargeUpload(state) { return state.largeUpload; },
}
const _actions = {
    setTotalParts({ state }, amount) {
        state.totalParts = amount;
    },
    setCurrentPart({ state }, amount) {
        state.currentPart = amount;
    },
    setLargeUpload({ state }, status) {
        state.largeUpload = status;
    },
    reset({ state }) {
        state.largeUpload = false;
        state.currentPart = 0;
        state.totalParts = 0;
    }
}

const upload = {
    namespaced: true,
    state: _state,
    getters: _getters,
    actions: _actions
}

export default upload;
