<template>
    <div class="user-settings">
        <div class="section">
            <profile-picture
                :user="getUser"
            />
            <text-areas
                :user="getUser"
            />
        </div>
        <div class="section" v-if="getUser.isFederated">
            <input 
                id="import-followers"
                type="file"
                accept="text/txt, text/csv"
                @change="uploadFile"
            />
            <label for="import-followers"> {{  $t('component.settings.user-panel.import-followers') }}</label>
        </div>
        <div class="section">
            <user-backgrounds
                :backgrounds="getBackgrounds"
            />
        </div>
    </div>
</template>
<script>
import { importFollowers } from "@/services/api/user-api.service"
import ProfilePicture from "./profile-picture.vue"
import TextAreas from "./text-areas.vue"
import UserBackgrounds from "./user-backgrounds.vue"
import { mapGetters, mapActions } from 'vuex'
    export default {
        name: "user-settings",
        components: {
            "profile-picture": ProfilePicture,
            "text-areas": TextAreas,
            "user-backgrounds": UserBackgrounds,
        },
        computed: {
            ...mapGetters({
                getUser: "User/getUser",
                getBackgrounds: "User/getBackgrounds",
            })
        },
        methods: {
            ...mapActions({
                setUser: 'User/setUser'
            }),
            async uploadFile(event) {
                var file = event.target.files[0];
                await importFollowers(file);
            }
        },
    }
</script>
<style lang="scss" scoped>
.user-settings {
    width: 100%;
    overflow-y: auto;
    display: grid;
    row-gap: 2rem;
    .section {
        display: flex;
        column-gap: 1rem;
    }
}
</style>