<template>
    <sh-floating-panel class="sh-user-search" :show="show" instantFade clickable @closed="$emit('closed')">
        <sh-single-line-text-input 
            class="search-bar"
            v-model="query"
            @input="Search"
            :placeholder="$t('page.search.mentioned-handle')"
        /> 
        <sh-floating-search
            ref="searcher"
            :searchQuery="query"
            @clicked="$emit('addMention')"
        />
    </sh-floating-panel>
</template>
<script>
import ShFloatingPanel from './sh-floating-panel.vue';
import ShSingleLineTextInput from '@/components/common/sh-single-line-text-input.vue';
import ShFloatingSearch from '@/components/common/sh-floating-search.vue';
    export default {
        name: "sh-user-search",
        components: {
            "sh-floating-panel": ShFloatingPanel,
            "sh-single-line-text-input": ShSingleLineTextInput,
            "sh-floating-search": ShFloatingSearch
        },
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                query: null
            }
        },
        methods: {
            Search() {
                if (this.query.length > 2) {
                    this.$refs["searcher"].searchUser();
                } else {
                    this.$refs["searcher"].disable();
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
.sh-user-search {
    width: 20rem !important;
    font-size: small;
    left: 0;
}
</style>