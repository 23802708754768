import store from "@/store";
import { getFederatedTimelineSince, getHomeTimelineSince, getPublicTimelineSince } from "./api/timeline-api.service";

async function fetchPosts(name) {
    var timelineFunction;

    var id = store.getters['Timeline/getLastFetchedId'](name);
    var type = store.getters['Timeline/getType'](name);

    switch(type) {
        case "public": timelineFunction = getPublicTimelineSince; break;
        case "home": timelineFunction = getHomeTimelineSince; break;
        case "federated": 
            timelineFunction = getFederatedTimelineSince; 
            id = store.getters['Timeline/getLastFetchedCreatedDate'](name);    
        break;
    } 

    var posts; 
    try {
        posts = await timelineFunction(id);
    }
    catch {
        clearFetchingInterval(name);
        return;
    }

    if(posts.length > 0) {
        store.dispatch('Timeline/addFetchedPosts', {
            timelineName: name,
            posts: posts
        });
    }
}

function startFetchingInterval(name) {
    var timeline = store.getters['Timeline/getTimeline'](name);
    if (!timeline) {
        return;
    }

    var existingInterval = store.getters['Timeline/getInterval'](name);
    if (existingInterval) {
        return;
    }

    var interval = setInterval(
        fetchPosts, 10000, name
    )

    store.dispatch('Timeline/setInterval', {
        timelineName: name,
        interval: interval
    });
}

function clearFetchingInterval(name) {
    var existingInterval = store.getters['Timeline/getInterval'](name);

    if (existingInterval) {
        clearInterval(existingInterval);
        store.dispatch('Timeline/setInterval', {
            timelineName: name,
            interval: null
        });
    }
}


export {
    startFetchingInterval,
    clearFetchingInterval
}
