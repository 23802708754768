
import i18n from "@/i18n"
const postTypes = [
    { value: "Text", label: i18n.global.t('component.form.post-type.text') },
    // { value: "Markdown", label: i18n.global.t('component.form.post-type.markdown')},
    { value: "Bbcode", label: i18n.global.t('component.form.post-type.bbcode') },
    // { value: "Html", label: i18n.global.t('component.form.post-type.html') },
]

export default postTypes
