<template>
    <div class="login-panel">
        <div class="body">
            <sh-single-line-text-input :placeholder="$t('common.handle')"  v-model="loginData.handle" />
            <sh-single-line-text-input :placeholder="$t('common.password')"   v-model="loginData.password" password />
        </div>
        <div class="footer">
            <sh-filled-button @click="submit()" padding=".2rem 2" borderRadius=".25">{{ $t('common.login')}}</sh-filled-button>
            <sh-filled-button @click="$emit('changePanel')" padding=".2rem .4" borderRadius=".25">{{ $t('common.register')}}</sh-filled-button>
        </div>
    </div>
</template>

<script>
import ShSingleLineTextInput from "@/components/common/sh-single-line-text-input.vue";
import shFilledButton from "../common/sh-filled-button.vue";

import { loginUser } from "@/services/api/user-api.service";
import { mapActions } from "vuex";
    export default {
        name: "login-panel",
        components: {
            "sh-single-line-text-input": ShSingleLineTextInput,
            "sh-filled-button": shFilledButton,
        },
        data() {
            return {
                loginData: {
                    handle: null,
                    password: null
                }
            }
        },
        methods: {
            ...mapActions({
                addAlert: "Alert/addAlert"
            }),
            validateInput() {
                if(!this.loginData.handle) {
                    this.addAlert({
                        label: this.$t('error.missing-username'),
                        expireTime: 500,
                        type: 'warning'
                    })
                    return false;
                }
                if(!this.loginData.password) {
                    this.addAlert({
                        label: this.$t('error.missing-password'),
                        expireTime: 500,
                        type: 'warning'
                    })
                    return false;
                }
                return true;
            },
            handleKeyPress(e) {
                if (e.key == 'Enter') {
                    this.submit();
                }
            },
            async submit() {
                if (!this.validateInput()) {
                    return;
                }

                if(await loginUser(this.loginData)) {
                    this.$router.push({ name: 'home'})
                }

            }
        },
        mounted() {
            window.addEventListener('keydown', this.handleKeyPress)
        },
        beforeRouteLeave() {
            window.removeEventListener('keydown', this.handleKeyPress)
        },
        beforeUnmount() {
            window.removeEventListener('keydown', this.handleKeyPress)
        },
    }
</script>

<style lang="scss">
.login-panel {
    display: inline-grid;
    margin-right: auto;
    margin-left: auto;
    justify-items: center;
    row-gap: 1rem;
    width: fit-content;
    background-color: var(--background);
    padding: 3rem;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 1px 1px var(--shadow);
    .body, .footer {
        display: grid;
        justify-items: center;
        row-gap:.5rem;
    }
}
</style>