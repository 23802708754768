<template>
    <sh-modal-panel class="settings-modal" :show="show" @close="$emit('close')">
        <div class="settings-wrapper">
            <div class="settings-tabs">
                <sh-icon-button
                    v-for="tab in allowedTabs"
                    class="tab-icon"
                    :key="tab.value"
                    @click="changeTab(tab.value)"
                    small
                    shadow
                    label
                    customSize="3.5"
                    fontSize="xx-large"
                    :title="tab.title"
                    :icon="tab.icon"
                >
                    <span class="tab-label">{{ tab.title }}</span>
                </sh-icon-button>
                    
            </div>
            <div class="body">
                <general-settings v-if="activeTab == 'general'"/>
                <user-settings v-else-if="activeTab == 'user'"/>
                <security-settings v-else-if="activeTab == 'security'"/>
                <filter-settings v-else-if="activeTab == 'filter'"/>
                <theme-settings v-else-if="activeTab == 'theme'"/>
                <notification-settings v-else-if="activeTab == 'notification'"/>
                <relation-settings v-else-if="activeTab == 'relation'"/>
                <mute-settings v-else-if="activeTab == 'mute'"/>
                <info-settings v-else-if="activeTab == 'info'"/>
                <admin-settings v-else-if="activeTab == 'admin' && getPermission('Admin')"/>
            </div>
        </div>
    </sh-modal-panel>
</template>
<script>
import ShModalPanel from "@/components/common/sh-modal-panel.vue"
import ShIconButton from "@/components/common/sh-icon-button.vue"
import GeneralSettings from "./general-settings/general-settings.vue"
import UserSettings from "./user-settings/user-settings.vue"
import SecuritySettings from "./security-settings/security-settings.vue"
import FilterSettings from "./filter-settings/filter-settings.vue"
import ThemeSettings from "./theme-settings/theme-settings.vue"
import NotificationSettings from "./notification-settings/notification-settings.vue"
import RelationSettings from "./relation-settings/relation-settings.vue"
import MuteSettings from "./mute-settings/mute-settings.vue"
import InfoSettings from "./info-settings/info-settings.vue"
import AdminSettings from "./admin-settings/admin-settings.vue"

import { userPermissions, filterListV2 } from "@/services/permission-handler.service.js"
import { mapGetters } from 'vuex'
    export default {
        name: "settings-modal",
        components: {
            "sh-modal-panel": ShModalPanel,
            "sh-icon-button": ShIconButton,
            "general-settings": GeneralSettings,
            "user-settings": UserSettings,
            "security-settings": SecuritySettings,
            "filter-settings": FilterSettings,
            "theme-settings": ThemeSettings,
            "notification-settings": NotificationSettings,
            "relation-settings": RelationSettings,
            "mute-settings": MuteSettings,
            "info-settings": InfoSettings,
            "admin-settings": AdminSettings
        },
        data() {
            return {
                activeTab: "general",
                tabs: [
                    { value: "general", icon: "icon-wrench", title: this.$t('component.settings.general') },
                    { value: "user", icon: "icon-user", title: this.$t('component.settings.profile'), permission: userPermissions.isAuthenticated  },
                    { value: "security", icon: "icon-locked", title: this.$t('component.settings.security'), permission: userPermissions.isAuthenticated },
                    { value: "filter", icon: "icon-filter", title: this.$t('component.settings.filter') },
                    { value: "theme", icon: "icon-paint-brush", title: this.$t('component.settings.theme') },
                    { value: "notification", icon: "icon-bell", title: this.$t('component.settings.notifications'), permission: userPermissions.isAuthenticated },
                    { value: "relation", icon: "icon-group", title: this.$t('component.settings.relations'), permission: userPermissions.isAuthenticated },
                    { value: "mutes", icon: "icon-eye-blocked", title: this.$t('component.settings.mutes'), permission: userPermissions.isAuthenticated },
                    { value: "info", icon: "icon-info", title: this.$t('component.settings.info') },
                    { value: "admin", icon: "icon-gauge", title: this.$t('component.settings.admin'), permission: userPermissions.isAdmin },
                ],
                allowedTabs: []
            }
        },
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapGetters({
                isUser: 'User/getUrl',
                getPermission: "Authentication/getPermission"
            })
        },
        methods: {
            changeTab(value) {
                this.activeTab = value
            },
            async setTabs() {
                this.allowedTabs = filterListV2(this.tabs, { checkPost: false}, { checkPoster: false }, true);
            }
        },
        async mounted() {
           await this.setTabs();
        },
        watch: {
            isUser() {
                this.setTabs();
            }
        }
    }
</script>
<style lang="scss">
.settings-modal {
    .settings-wrapper {
        column-gap: 4rem;
        display: flex;
        height: 95%;
        padding: 0.5rem;
        .settings-tabs {
            height: 100%;
            display: flex;
            flex-direction: column;
            row-gap: .5rem;
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            
            .tab-icon {
                display: grid;
                padding: 0.2rem;
                .tab-label {
                    font-size: xx-small;
                    color: var(--buttonText);
                }
            }

        }
        .body {
            width: 80%;
            padding-right: 1rem;
            overflow-y: auto;
        }
    }
}
</style>