import i18n from '@/i18n'
import { url, setHeaders, handleError, refreshHeaders } from './api.service'
import store from '@/store'

const SHAKOU_VALIDATE_TOKEN = `/api/user/token`
const SHAKOU_LOGIN_USER = `/api/user/login`
const SHAKOU_LOGOUT_USER = `/api/user/logout`
const SHAKOU_REGISTER_USER = `/api/user/register`
const SHAKOU_EMAIL_CHANGE = `/api/user/email`
const SHAKOU_PASSWORD_CHANGE = `/api/user/password`
const SHAKOU_USER_CONFIRM = (token) =>  `/api/user/confirm/${token}`
const SHAKOU_GET_SELF = `/api/user`
const SHAKOU_GET_USER = (url) => `/api/user/${url}`
const SHAKOU_GET_USER_BY_HANDLE = (url) => `/api/user/${url}/handle`
const SHAKOU_DELETE_USER = (url) => SHAKOU_GET_USER(url)
const SHAKOU_GET_RELATION = (url) => `/api/user/${url}/relation`
const SHAKOU_FOLLOW_USER = (url) => `/api/user/${url}/follow`
const SHAKOU_UNFOLLOW_USER = (url) => `/api/user/${url}/unfollow`
const SHAKOU_SUPERFOLLOW_USER = (url) => `/api/user/${url}/superfollow`
const SHAKOU_BLOCK_USER = (url) => `/api/user/${url}/block`
const SHAKOU_UNBLOCK_USER = (url) => `/api/user/${url}/unblock`
const SHAKOU_MUTE_USER = (url) => `/api/user/${url}/mute`
const SHAKOU_UNMUTE_USER = (url) => `/api/user/${url}/unmute`
const SHAKOU_USER_FOLLOWERS = (url) => `/api/user/${url}/followers`
const SHAKOU_USER_FOLLOWING = (url) => `/api/user/${url}/following`
const SHAKOU_OWN_FOLLOWERS = `/api/user/followers`
const SHAKOU_IMPORT_FOLLOWERS = `/api/user/import-followers`

async function validateToken() {
    // Don't waste time fetching
    if (store.getters["Authentication/getToken"] == null) {
        return false;
    }

    var response;
    var headers = setHeaders("GET");

    try {
        response = await fetch(url(SHAKOU_VALIDATE_TOKEN), headers);
    } catch {
        return false;
    }
    if (response.status != 200) {
        store.dispatch("Authentication/clearTokens");
        return false;
    }
    return true;

    
}

async function loginUser(loginData) {
    document.body.style.cursor='wait';
    
    var headers = setHeaders("POST",loginData);
    var response;
    try {
        response = await fetch(url(SHAKOU_LOGIN_USER), headers);
    } catch {
        document.body.style.cursor='default';
        return;
    }

    document.body.style.cursor='default';
    if (response.status != 200) {
        handleError(response, i18n.global.t('error.login'));
        return false;
    }

    var data = await response.json();

    store.dispatch('Authentication/setTokens', data);

    return true;
}

function logoutUser() {
    fetch(url(SHAKOU_LOGOUT_USER), refreshHeaders());
}

async function registerUser(registrationData) {
    document.body.style.cursor='wait';

    var headers = setHeaders("POST",registrationData);
    var response;
    try {
        response = await fetch(url(SHAKOU_REGISTER_USER), headers);
    } catch {
        document.body.style.cursor='default';
        return false;
    }

    document.body.style.cursor='default';
    if (response.status != 201) {
        handleError(response, registerUser, registrationData);
        return false;
    }

    return true;
}

async function getSelf() {
    var headers = setHeaders("GET");
    var response;
    try {
        response = await fetch(url(SHAKOU_GET_SELF), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        return;
    }

    return await response.json();
}

async function getUser(userUrl) {
    var headers = setHeaders("GET");
    var response;
    try {
        response = await fetch(url(SHAKOU_GET_USER(userUrl)), headers);
    } catch {
        return null;
    }

    if (response.status != 200) {
        handleError(response, i18n.global.t('error.failed-to-fetch-user'));
        return null;
    }
    
    return await response.json();
}


async function getUserByHandle(userHandle) {
    var headers = setHeaders("GET");
    var response;
    try {
        response = await fetch(url(SHAKOU_GET_USER_BY_HANDLE(userHandle)), headers);
    } catch {
        return null;
    }

    if (response.status != 200) {
        return null;
    }
    
    return await response.json();
}


async function deleteUser(userUrl) {
    var headers = setHeaders("DELETE");
    var response;
    try {
        response = await fetch(url(SHAKOU_DELETE_USER(userUrl)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        handleError(response, deleteUser, userUrl);
        return;
    }
}

async function followUser(userUrl) {
    var headers = setHeaders("POST");
    var response;
    try {
        response = await fetch(url(SHAKOU_FOLLOW_USER(userUrl)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        handleError(response, followUser, userUrl);
        return;
    }
}

async function unfollowUser(userUrl) {
    var headers = setHeaders("POST");
    var response;
    try {
        response = await fetch(url(SHAKOU_UNFOLLOW_USER(userUrl)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        handleError(response, unfollowUser, userUrl);
        return;
    }
}

async function superfollowUser(userUrl) {
    var headers = setHeaders("POST");
    var response;
    try {
        response = await fetch(url(SHAKOU_SUPERFOLLOW_USER(userUrl)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        handleError(response, superfollowUser, userUrl);
        return;
    }
}

async function blockUser(userUrl) {
    var headers = setHeaders("POST");
    var response;
    try {
        response = await fetch(url(SHAKOU_BLOCK_USER(userUrl)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        handleError(response, blockUser, userUrl);
        return;
    }
}

async function unblockUser(userUrl) {
    var headers = setHeaders("POST");
    var response;
    try {
        response = await fetch(url(SHAKOU_UNBLOCK_USER(userUrl)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        handleError(response, unblockUser, userUrl);
        return;
    }
}

async function muteUser(userUrl) {
    var headers = setHeaders("POST");
    var response;
    try {
        response = await fetch(url(SHAKOU_MUTE_USER(userUrl)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        handleError(response, muteUser, userUrl);
        return;
    }
}

async function unmuteUser(userUrl) {
    var headers = setHeaders("POST");
    var response;
    try {
        response = await fetch(url(SHAKOU_UNMUTE_USER(userUrl)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        handleError(response, unmuteUser, userUrl);
        return;
    }
}


async function emailChange(emailObject) {
    var headers = setHeaders("PUT", emailObject);
    try {
        await fetch(url(SHAKOU_EMAIL_CHANGE), headers);
    } catch {
        return false;
    }

    return true;
}


async function passwordChange(passwordObject) {
    var headers = setHeaders("PUT", passwordObject);
    try {
        await fetch(url(SHAKOU_PASSWORD_CHANGE), headers);
    } catch {
        return false;
    }

    return true;
}

async function updateUser(updateObject) {
    var headers = setHeaders("PUT", updateObject);
    try {
        await fetch(url(SHAKOU_GET_SELF), headers);
    } catch {
        return false;
    }

    store.dispatch('Alert/addAlert', {
        type: 'success',
        expireTime: 1000,
        label: i18n.global.t('page.settings.update-user')
    })
    return true;
}

async function confirmUser(confirmUrl) {
    var headers = setHeaders("POST");
    try {
        await fetch(url(SHAKOU_USER_CONFIRM(confirmUrl)), headers);
    } catch {
        return false;
    }

    return true;
}

async function getRelationship(userUrl) {
    var headers = setHeaders();
    var response;
    try {
        response = await fetch(url(SHAKOU_GET_RELATION(userUrl)), headers);
    } catch {
        return "undefined";
    }
    return await response.text();
}

async function getFollowing(userUrl) {
    var headers = setHeaders();
    var response;
    try {
        response = await fetch(url(SHAKOU_USER_FOLLOWING(userUrl)), headers);
    } catch {
        return "undefined";
    }
    return await response.json();
}

async function getFollowers(userUrl) {
    var headers = setHeaders();
    var response;
    try {
        response = await fetch(url(SHAKOU_USER_FOLLOWERS(userUrl)), headers);
    } catch {
        return "undefined";
    }
    return await response.json();
}


async function getOwnFollowers() {
    var headers = setHeaders();
    var response;
    try {
        response = await fetch(url(SHAKOU_OWN_FOLLOWERS), headers);
    } catch {
        return "undefined";
    }
    return await response.json();
}

function confirmFollow(followData) {
    var headers = setHeaders('POST', followData);
    fetch(url(SHAKOU_OWN_FOLLOWERS), headers);
}


function importFollowers(file) {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('Name', "ImportFile");

    fetch(url(SHAKOU_IMPORT_FOLLOWERS), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : 'Bearer ' + store.state.Authentication.token)
        },
        body: formData
    });
}

export {
    validateToken,
    loginUser,
    logoutUser,
    registerUser,
    getSelf,
    getUser,
    getUserByHandle,
    deleteUser,
    followUser,
    unfollowUser,
    superfollowUser,
    blockUser,
    unblockUser,
    muteUser,
    unmuteUser,
    emailChange,
    passwordChange,
    updateUser,
    confirmUser,
    getRelationship,
    getFollowers,
    getFollowing,
    getOwnFollowers,
    confirmFollow,
    importFollowers
}