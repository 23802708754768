import store from '@/store/index'

const markFiltered = (word,posts) => {
    const mutedWordRegex = new RegExp(`${word}`, "i")
    for(let i = 0; i < posts.length; i++) {
        if(mutedWordRegex.test(posts[i].body)) {
            posts[i].isMuted = true 
            posts[i].muteReason = word
        }
    }
}

const checkForFilteredWords = (posts) => {
    const filteredWords = store.getters['Settings/getFilteredWords']

    // No words filters, return all posts
    // hideFilteredPosts is a server side settings, and will not include the words the user filters in the response
    if(filteredWords == null)
        return posts
    if(filteredWords.length <= 0) 
        return posts
    if (filteredWords[0] == "")
        return posts;

    // Check posts for muted words
    for(let i = 0; i < filteredWords.length; i++) {
        markFiltered(filteredWords[i],posts)
    }

    return posts
}

export default checkForFilteredWords