<template>
    <div class="bookmark-view">

    </div>
</template>
<script>
    export default {
        name: "BookmarkView"
    }
</script>
<style lang="scss" scoped>

</style>