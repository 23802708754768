import { setHeaders, url, handleError } from "./api.service"

const SHAKOU_CHATS =  `/api/chats`
const SHAKOU_MESSAGES = (id) => `/api/chats/${id}`
const SHAKOU_MESSAGES_SINCE = (id,since) => `/api/chats/${id}?since=${since}`
const SHAKOU_MESSAGES_PAGE = (id,page) => `/api/chats/${id}?page=${page}`
const SHAKOU_MESSAGE  = (id) => `/api/chats/${id}`
const SHAKOU_MESSAGE_DELETE  = (id, messageId) => `/api/chats/${id}/${messageId}`


async function getChats() {
    var headers = setHeaders();

    var response;
    try {
        response = await fetch(url(SHAKOU_CHATS), headers);
    } catch {
        return null;
    }

    if (response.status != 200) {
        return null;
    }

    return await response.json();
}
async function getMessages(chatId) {
    var headers = setHeaders();

    var response;
    try {
        response = await fetch(url(SHAKOU_MESSAGES(chatId)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        return;
    }

    return await response.json();
}
async function getMessagesSince(chatId, sinceId) {
    var headers = setHeaders();

    var response;
    try {
        response = await fetch(url(SHAKOU_MESSAGES_SINCE(chatId, sinceId)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        return;
    }

    return await response.json();
}
async function getMessagesPage(chatId, page) {
    var headers = setHeaders();

    var response;
    try {
        response = await fetch(url(SHAKOU_MESSAGES_PAGE(chatId, page)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        return;
    }

    return await response.json();
}
async function markRead(chatId) {
    var headers = setHeaders('PUT');

    var response;
    try {
        response = await fetch(url(SHAKOU_MESSAGES(chatId)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        return;
    }

    return;
}


async function createChat(chatData) {
    var headers = setHeaders('POST', chatData);

    var response;
    try {
        response = await fetch(url(SHAKOU_CHATS), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        return;
    }

    return await response.json();
}
async function createMessage(messageData) {
    var headers = setHeaders('POST', messageData);

    var response;
    try {
        response = await fetch(url(SHAKOU_MESSAGE(messageData.chatId)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        return;
    }

    return;
}
async function deleteChat(chatId) {
    var headers = setHeaders('DELETE');

    var response;
    try {
        response = await fetch(url(SHAKOU_MESSAGE(chatId)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        handleError(response);
        return;
    }

    return await response.json();
}
async function deleteMessage(chatId, messageId) {
    var headers = setHeaders('DELETE');

    var response;
    try {
        response = await fetch(url(SHAKOU_MESSAGE_DELETE(chatId, messageId)), headers);
    } catch {
        return;
    }

    if (response.status != 200) {
        handleError(response);
        return;
    }

    return await response.json();
}

export {
    getChats,
    getMessages,
    getMessagesSince,
    getMessagesPage,
    markRead,
    createChat,
    createMessage,
    deleteChat,
    deleteMessage
}