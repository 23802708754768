<template>
    <div class="default-post">
        <post-header
            :poster="post.poster"
            :postUrl="post.url"
            :reply="post.reply"
            :title="post.title"
            :visibility="post.visibility"
            :createdDate="post.createdDate"
            :isEdited="post.isEdited"
            :isFederated="post.isFederated"
            :isExternal="post.isExternal"
            :editedDate="post.updatedDate"
            :externalTags="post.tagObjects"
        />
        <post-body
            :body="post.body"
            :metadata="post.metadata"
            :type="post.type"
            :mentionedUsers="post.mentionedUsers"
            :isExternal="post.isExternal"
            :externalTags="post.tagObjects"
        />
        <sh-poll
            v-if="post.hasPoll"
            :postUrl="post.url"
            :hasVoted="post.hasVoted"
            :poll="post.pollOptions"
            @updatePost="updatePost"
        />
        <sh-media-gallery
            v-if="post.media"
            :media="post.media"
        />
        <sh-reactions
            v-if="post.interactions.length > 0"
            :postUrl="post.url"
            :interactions="post.interactions"
            @updatePost="updatePost"
        />
        <post-footer
            :posterUrl="post.poster.url"
            :postUrl="post.url"
            :hasMedia="post.hasMedia"
            :mentions="post.mentionedUsers"
            :reply="post.reply"
            :replies="post.replies"
            :interactions="post.interactions"
            :isMuted="post.isMuted"
            :isBookmarked="post.isBookmarked"
            :isThread="isThread"
            @toggleEditing="$emit('toggleEditing')"
            @toggleReplyForm="toggleReplyForm"
            @updatePost="updatePost"
            @downloadImages="downloadImages"
            @openThread="$emit('openThread')"
            @toggleMute="$emit('toggleMute')"
            @toggleBookmark="$emit('toggleBookmark')"
        />
        <form-panel
            v-if="replyFormVisible && isUser"
            @submitted="toggleReplyForm"
            :replyUrl="post.url"
            :replyHandles="gatherReplyHandles"
        />
    </div>
</template>

<script>
import ShMediaGallery from "@/components/common/sh-media-gallery.vue";
import PostHeader from '@/components/post-header/post-header.vue'
import PostBody from '@/components/post-body/post-body.vue'
import PostFooter from '@/components/post-footer/post-footer.vue'
import FormPanel from '@/components/form-panel/form-panel.vue'
import ShReactions from "@/components/common/sh-reactions.vue";
import ShPoll from "@/components/common/sh-poll.vue";

import { loadFullFile } from "@/services/api/file-api.service";
import { mapGetters } from "vuex";
    export default {
        name: "default-post",
        components: {
            "post-header": PostHeader,
            "post-body": PostBody,
            "post-footer": PostFooter,
            "form-panel": FormPanel,
            "sh-media-gallery": ShMediaGallery,
            "sh-reactions": ShReactions,
            "sh-poll": ShPoll,
        },
        props: {
            post: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                replyFormVisible: false
            }
        },
        computed: {
            ...mapGetters({
                isUser: 'User/getUrl'
            }),
            isThread() {
                if (this.post.reply || this.post.replies.length > 0) {
                    return true;
                } else {
                    return false;
                }
            },
            gatherReplyHandles() {
                var handles = []
                if (this.post.poster.url != this.isUser) {
                    handles.push(this.post.poster.handle);
                }

                if (this.post.mentionedUsers) {
                    this.post.mentionedUsers.forEach((e) => {
                        if (e.url != this.isUser) {
                            handles.push(e.handle);
                        }
                    });
                }

                return handles;
            }
        },
        methods: {
            toggleReplyForm() {
                this.replyFormVisible = !this.replyFormVisible
            },
            updatePost(post) {
                this.$emit('updatePost',post)
            },
            async downloadImages() {
                for(let i = 0; i < this.post.media.length; i++) {
                    const img = await fetch(loadFullFile(this.post.media[i].url))
                    const imgBlob = await img.blob()

                    const imageURL = URL.createObjectURL(imgBlob)
                    const link = document.createElement('a')
                    link.href = imageURL
                    link.download = this.post.media[i].url.split('/')[1]
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }
            }
        }
    }
</script>

<style lang="scss">
    .default-post {
        width: 100%;
    }
</style>