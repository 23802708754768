<template>
    <div class="admin-settings">
        admin
        <emoji-panel/>
    </div>
</template>
<script>
import EmojiPanel from './emoji-panel/emoji-panel.vue';
    export default {
        name: "admin-settings",
        components: {
            "emoji-panel": EmojiPanel
        },
        props: {
        }
    }
</script>
<style lang="scss">

</style>