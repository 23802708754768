<template>
    <div class="sh-interactions">
        <div class="likes">
            <sh-floating-panel 
                :show="showLikes && getLikeCount > 0"
                :offsetY=offsetY
                :maxContent=maxContent
                :style="getStyle"
                @closed="isToggled = false"
                clickable
                instantFade
            >
                <sh-user-result class="dropdown-user-result" v-for="interaction in getLikes" :key="interaction.user.url" :user="interaction.user"/>
            </sh-floating-panel>
            <div class="top" @mouseenter="showLikes = true" @mouseleave="showLikes = false">
                {{ $t('component.interactions.likes')}}
            </div>
            <div class="bottom">
                {{ getLikeCount }}
            </div>
        </div>
        <div class="reposts">
            <sh-floating-panel 
                :show="showReposts && getRepostCount > 0"
                :offsetY=offsetY
                :maxContent=maxContent
                :style="getStyle"
                @closed="isToggled = false"
                clickable
                instantFade
            >
                <sh-user-result class="dropdown-user-result" v-for="interaction in getReposts" :key="interaction.user.url" :user="interaction.user"/>
            </sh-floating-panel>
            <div class="top" @mouseenter="showReposts = true" @mouseleave="showReposts = false">
                {{ $t('component.interactions.reposts')}}
            </div>
            <div class="bottom">
                {{ getRepostCount }}
            </div>
        </div>
        <div class="users">
            <sh-profile-picture
                    v-for="user in getUsers"
                    :key="user.url"
                    :src="user.url"
                    :filename="user.profilePicture"
                    :title="user.handle"
                    rounded
                    width=2
                />
        </div>
    </div>
</template>
<script>
import ShFloatingPanel from './sh-floating-panel.vue';
import ShUserResult from './sh-user-result.vue';
import ShProfilePicture from './sh-profile-picture.vue';
    export default {
        name: "sh-interactions",
        props: {
            interactions: {
                type: Object,
                required: true
            }
        },
        components: {
            "sh-profile-picture": ShProfilePicture,
            "sh-floating-panel": ShFloatingPanel,
            "sh-user-result": ShUserResult,
        },
        data() {
            return {
                showLikes: false,
                showReposts: false,
            }
        },
        computed: {
            getLikeCount() {
                return this.getInteraction('like').length;
            },
            getRepostCount() {
                return this.getInteraction('repost').length;
            },
            getLikes() {
                return this.getInteraction('like');
            },
            getReposts() {
                return this.getInteraction('repost');
            },
            getUsers() {
                var users = []
                this.interactions.forEach((interaction) =>  {
                    if (users.length > 10) { return users; }
                    if (!users.some((u) => u.url == interaction.user.url)) {
                        users.push(interaction.user)
                    }
                });
                return users
            }
        },
        methods: {
            getInteraction(type) {
                if (!this.interactions) {
                    return null;
                }
                var interactions = this.interactions.filter((interaction) => interaction.type.toLowerCase() == type);

                return interactions;
            },
        }
    }
</script>
<style lang="scss" scoped>
.sh-interactions {
    display: flex;
    margin: 0.5rem 0;
    column-gap: 1rem;
    .likes, .reposts {            
        position: relative;
        font-size: small;
        .top {
            font-weight: bolder;
            &:hover {
                cursor:pointer;
            }
        }
        .bottom {
            text-align: left;
            font-weight: 600;
        }
    }
    .users {
        display: flex;
        column-gap: 0.5rem;
        align-items: center;
        border-left-style: solid;
        border-left-width: thin;
        padding-left: 0.5rem;
        max-width: 90%;
        overflow-x: hidden;
    }
}
</style>