import { url, setHeaders } from './api.service'
import store from '@/store';


const SHAKOU_EMOJI = '/api/emoji'
const SHAKOU_EMOJI_CREATE = '/api/emoji'
const SHAKOU_EMOJI_GET = (name) => `/api/emoji/${name}`
const SHAKOU_EMOJI_GET_FILE = (group, name) => `/api/emoji/${group}/${name}`
const SHAKOU_EMOJI_DELETE = (name) => `/api/emoji/${name}`

async function getEmojis() {
    var response = await fetch(url(SHAKOU_EMOJI))

    if(response.status != 200) {
        return null;
    }
    
    return await response.json();
}

async function getEmoji(name) {
    var response = fetch(url(SHAKOU_EMOJI_GET(name)))

    if(response.status != 200) {
        return null;
    }
    
    return await response.json();
}

async function deleteEmoji(name) {
    var headers = setHeaders('DELETE')
    var response = fetch(url(SHAKOU_EMOJI_DELETE(name), headers))

    if(response.status != 200) {
        return false;
    }
}

async function createEmoji(emojiData) {
    const formData = new FormData();
    formData.append('File', emojiData.File);
    formData.append('Name', emojiData.Name);
    formData.append('Group', emojiData.Group);

    var response = await fetch(url(SHAKOU_EMOJI_CREATE), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : 'Bearer ' + store.state.Authentication.token)
        },
        body: formData
    });

    if(response.status != 200) {
        return false;
    }

    return await response.json();
}

export {
    getEmoji,
    getEmojis,
    deleteEmoji,
    createEmoji,
    SHAKOU_EMOJI_GET_FILE
}