<template>
    <div class="search-panel">
        <div class="top">
            <sh-single-line-text-input 
                class="search-bar"
                @focusin="addListener"
                @focusout="removeListener"
                :placeholder="$t('common.search')"
                v-model="query"
            /> 
            <sh-icon-button 
                small 
                shadow
                icon="icon-search"
                @click="Search"
            />
            <sh-icon-button 
                small 
                shadow
                icon="icon-cog"
                @click="showAdvanced = !showAdvanced"
            />
        </div>
        <div class="advanced-search" v-if="showAdvanced">
            <div class="users">
                <div class="user-wrapper">
                    <sh-single-line-text-input 
                        class="search-bar"
                        v-model="creator"
                        @input="SearchCreator"
                        :placeholder="$t('page.search.creator-handle')"

                    /> 
                    <sh-floating-search
                        ref="creatorSearch"
                        :searchQuery="creator"
                        @clicked="setCreator"
                    />
                </div>
                <div class="user-wrapper">
                    <sh-single-line-text-input 
                        class="search-bar"
                        v-model="mentioned"
                        @input="SearchMentions"
                        :placeholder="$t('page.search.mentioned-handle')"
                    /> 
                    <sh-floating-search
                        ref="mentionedSearch"
                        :searchQuery="mentioned"
                        @clicked="setMentioned"
                    />
                </div>
            </div>
            <div class="dates">
                <sh-datetime
                    v-model="beforeDate"
                >
                    {{ $t('page.search.before-date')}}
                </sh-datetime>
                <sh-datetime
                    v-model="afterDate"
                >
                    {{ $t('page.search.after-date')}}
                </sh-datetime>
            </div>
        </div>
    </div>
</template>
<script>
import ShSingleLineTextInput from '@/components/common/sh-single-line-text-input.vue';
import ShIconButton from '@/components/common/sh-icon-button.vue';
import ShDatetime from '@/components/common/sh-datetime.vue';
import ShFloatingSearch from '@/components/common/sh-floating-search.vue';
    export default {
        name: "SearchPanel",
        components: {
            "sh-single-line-text-input": ShSingleLineTextInput,
            "sh-icon-button": ShIconButton,
            "sh-datetime": ShDatetime,
            "sh-floating-search": ShFloatingSearch
        },
        data() {
            return {
                query: null,
                creator: null,
                mentioned: null,
                beforeDate: null,
                afterDate: null,
                showAdvanced: false
            }
        },
        methods: {
            setLocalData() {
                this.query = this.$route.query.q;
                this.creator = this.$route.query.creator;
                this.mentioned = this.$route.query.mentioned;
                this.beforeDate = this.$route.query.beforeDate;
                this.afterDate = this.$route.query.afterDate;
            },
            Search() {
                this.$emit('search', {
                    query: this.query,
                    creator: this.creator,
                    mentioned: this.mentioned,
                    beforeDate: this.beforeDate,
                    afterDate: this.afterDate,
                    clearTimeline: true
                })
            },
            addListener() {
                window.addEventListener('keydown', this.handleKeyPress);
            },
            removeListener() {
                window.removeEventListener('keydown', this.handleKeyPress);
            },
            hideResults(name) {
                this.$refs[name].disable();
            },
            setCreator(value) {
                this.creator = value;
                this.hideResults('creatorSearch');
            },
            setMentioned(value) {
                this.mentioned = value;
                this.hideResults('mentionedSearch');
            },
            SearchMentions() {
                if (this.mentioned.length > 2) {
                    this.SearchUser('mentionedSearch')
                } else {
                    this.hideResults('mentionedSearch');
                }
            },
            SearchCreator() {
                if (this.creator.length > 2) {
                    this.SearchUser('creatorSearch')
                } else {
                    this.hideResults('creatorSearch');
                }
            },
            SearchUser(name) {
                this.$refs[name].searchUser();
            },
            handleKeyPress(e) {
                if (e.key == "Enter") {
                    if (this.query != null) {
                        this.Search();
                    }
                }
            }
        },
        created() {
            this.setLocalData();
        }
    }
</script>
<style lang="scss" scoped>
.search-bar {
    width: 100%;
}
.search-panel {
    width: 100%;
    .top {
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }
    .advanced-search {
        margin-top: 1rem;
        .users {
            display: flex;
            width: 100%;

            .user-wrapper {
                position: relative;
            }
        }
    }
}
</style>