<template>
    <div class="theme-settings">
        <theme-selector v-if="false"/>
        <theme-editor/>
    </div>
</template>
<script>
import ThemeEditor from './theme-editor.vue';
import ThemeSelector from './theme-selector.vue';
    export default {
        name: "theme-settings",
        components: {
            "theme-editor": ThemeEditor,
            "theme-selector": ThemeSelector
        }
    }
</script>
<style lang="scss">
.theme-buttons {
    margin: 1rem;
    display: flex;
    column-gap: 3rem;
    justify-content: center;
}
.section {
    display: inline-grid;
}
</style>