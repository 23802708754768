const defaultState = {
    threads: []
}

const _getters = {
    getThread: (state) => (threadUrl) => {
        if (state.threads[threadUrl]) {
            return state.threads[threadUrl]
        } else {
            return null;
        }
    }
}

const _mutations = {

}

const _actions = {
    addThread({ state }, threadObject) {
        state.threads[threadObject.threadUrl] = threadObject.posts;
    }
}

const thread = {
    namespaced: true,
    state: defaultState,
    getters: _getters,
    mutations: _mutations,
    actions: _actions
}

export default thread