<template>
    <div class="security-settings">
        <div class="password-reset">
            <sh-single-line-text-input
                :placeholder="$t('common.password')"
                v-model="oldPassword"
            />
            <sh-single-line-text-input
                :placeholder="$t('common.new-password')"
                v-model="newPassword"
            />
            <sh-filled-button @click="updatePassword"> {{ $t('common.submit') }}</sh-filled-button>
        </div>
        <div class="email-reset">
            <sh-single-line-text-input
                :placeholder="$t('common.password')"
                v-model="password"
            />
            <sh-single-line-text-input
                :placeholder="$t('common.new-email')"
                v-model="newEmail"
            />
            <sh-filled-button @click="updateEmail"> {{ $t('common.submit') }}</sh-filled-button>
        </div>
    </div>
</template>
<script>
import ShSingleLineTextInput from '@/components/common/sh-single-line-text-input.vue';
import ShFilledButton from "@/components/common/sh-filled-button.vue";

import { mapActions } from 'vuex';
import { passwordChange, emailChange } from "@/services/api/user-api.service"
    export default {
        name: "security-settings",
        components: {
            'sh-single-line-text-input': ShSingleLineTextInput,
            'sh-filled-button' : ShFilledButton,
        },
        data() {
            return {
                password: null,
                newEmail: null,
                oldPassword: null,
                newPassword: null,
            }
        },
        methods: {
            ...mapActions({
                addAlert: "Alert/addAlert"
            }),
            updatePassword() {
                passwordChange({
                    Id: "45e7031e-ccf4-46a5-8927-6d73b9680f21",
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword
                })

                this.oldPassword = null;
                this.newPassword = null;

                this.addAlert({
                    label: this.$t('component.settings.security-panel.password-change'),
                    expireTime: 500,
                    type: 'success'
                })
            },
            updateEmail() {
                emailChange({
                    Id: "45e7031e-ccf4-46a5-8927-6d73b9680f21",
                    password: this.password,
                    newEmail: this.newEmail
                })

                this.password = null;
                this.email = null;

                
                this.addAlert({
                    label: this.$t('component.settings.security-panel.email-change'),
                    expireTime: 500,
                    type: 'success'
                })
            }
        }
    }
</script>
<style lang="scss">

</style>