<template>
    <div class="sh-floating-panel" :style="style" v-if="visible" >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "sh-floating-panel",
        props: {
            offsetX: {
                type: Number,
                required: false,
                default: null
            },
            offsetY: {
                type: Number,
                required: false,
                default: 0
            },
            zIndex: {
                type: Number,
                required: false,
                default: 100
            },
            clickable: {
                type: Boolean,
                default: false
            },
            show: {
                type: Boolean,
                default: false
            },
            flip: {
                type: Boolean,
                default: false
            },
            maxContent: {
                type: Boolean,
                default: false
            },
            instantFade: {
                type: Boolean,
                default: false
            }
        },
        data() {
          return {
            visible: false,
            isFading: false,
            opacity: 1,
            clicks: 0,
            style: ''
          }  
        },
        computed: {
            getOpacity() {
                return `opacity: ${this.opacity};`;
            },
            getWidth() {
                if (this.maxContent) {
                    return 'width: max-content;';
                } else {
                    return 'width: fit-content;';
                }
            },
        },
        methods: {
            enable() {
                if (this.isFading) {
                    this.isFading = false;
                    this.opacity = 1;
                    if (this.visible) {
                        this.$el.style['opacity'] = this.opacity;
                    }
                }
                this.visible = true
                this.setStyle();
            },
            disable() {
                this.clicks = 0;
                this.$emit('closed');
                if (this.instantFade) {
                    this.visible = false
                    return;
                }

                if (this.isFading) {
                    return;
                } else {
                    this.isFading = true;
                    setTimeout(this.lowerOpacity, 500)
                }
            },
            floatingStyle() {
                if (this.$el == null) {
                    return ``;
                }
                var styleString = `top: ${this.getOffset()}px; ${this.getWidth};z-index: ${this.zIndex};`;
                if (this.offsetX)
                    styleString += `right: ${this.offsetX}px;`

                return styleString
            },
            getOffset() {
                var height;
                var offset;
                var windowBottomHalf = window.screen.height/2;
                if ((
                    this.flip 
                        ? windowBottomHalf < this.$el.parentNode.getBoundingClientRect().y :
                        windowBottomHalf > this.$el.parentNode.getBoundingClientRect().y
                    )
                ) {
                    height = this.$el.parentNode.offsetHeight;
                    offset =  Number(height) + Number(this.offsetY)
                } else {
                    height = this.$el.offsetHeight;
                    offset =  (Number(height) + Number(this.offsetY)) * -1
                }
                return offset
            },
            lowerOpacity() {
                if (this.isFading) {
                    this.opacity -= .01;
                    if (this.opacity <= 0) {
                        this.visible = false;
                        this.opacity = 1;
                    } else {
                        if (this.visible) {
                            this.$el.style['opacity'] = this.opacity;
                        }
                        setTimeout(this.lowerOpacity, 2);
                    }
                }
            },
            setStyle() {
                this.$nextTick(() => {
                    this.style = this.floatingStyle();
                });
            },
            handleClick(event) {
                if (!this.visible) { return; }
                if (this.clicks <= 0) { this.clicks++; return;}
                if (event.target != this.$el && event.target != this.$el.parentNode && !this.$el.contains(event.target)) {
                    this.disable();
                }
            }
        },
        watch: {
            show(cur) {
                if (cur) { this.enable(); }
                else { this.disable() }
            }
        },
        created() {
            this.visible = this.show
        },
        mounted() {
            this.setStyle()
            if (this.clickable) {
                window.addEventListener('click',this.handleClick);
            }
        },
        unmounted() {
            if (this.clickable) {
                window.removeEventListener('click',this.handleClick);
            }
        }
    }
</script>

<style lang="scss">
    .sh-floating-panel {
        position: absolute;
        padding: 0.5rem;
        border-radius: 0.25rem;
        background-color: var(--panel);
    }
</style>