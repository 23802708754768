<template>
    <div class="sh-modal-wrapper" v-if="show">
        <div class="sh-custom-panel" :class="panelClass" v-if="useCustomPanel">
            <slot></slot>
        </div>
        <div class="sh-modal-panel" :class="panelClass" :style="getStyle" v-else>
            <sh-icon-button
                class="close-button"
                small 
                icon="icon-cross"
                @click="$emit('close')"
            />
            <slot></slot>
        </div>
    </div>
</template>

<script>
import ShIconButton from './sh-icon-button.vue';
    export default {
        name: "sh-modal-panel",
        components: {
            "sh-icon-button": ShIconButton
        },
        props: {
            panelClass: {
                type: String,
                default: null
            },
            width: {
                type: Number,
                default: 50
            },
            height: {
                type: Number,
                default: 50
            },
            show: {
                type: Boolean,
                default: false
            },
            useCustomPanel: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            getStyle() {
                return `width: ${this.width}%;height: ${this.height}%;`
            }
        },
    }
</script>

<style lang="scss">
.sh-modal-wrapper {
    backface-visibility: hidden;
    z-index: 1000;
    align-items: center;
    background-color: var(--modalWrapper);
    display: flex;
    position: fixed;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .sh-modal-panel {
        background-color: var(--panel);
        border-radius: .25rem;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        .close-button {
            position: absolute;
            top: 2%;
            right: 2%;
            background-color: var(--panelTransparent);
        }
    }    
    .sh-custom-panel {
        height: 100vh;
    }

}
</style>