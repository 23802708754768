<template>
    <div class="notification-settings">
        notification
    </div>
</template>
<script>
    export default {
        name: "notification-settings",
        components: {
        },
        props: {
        }
    }
</script>
<style lang="scss">

</style>