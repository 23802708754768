import Greentext from '@/components/text/greentext/sh-greentext.vue'
import UserMention from '@/components/text/user-mention/user-mention.vue'
import EmbedWrapper from '@/components/embed-wrapper/embed-wrapper.vue'
import reactStringReplace from "react-string-replace"
import { renderEmotes, renderExternalEmotes } from "./text/emojiParser.service"
import parseBbCode  from './text/bbcode.service'

import store from '@/store'

/* eslint-disable */
const findUserMentions = (text, mentionedUsers) => {

  var userRegex = /@(.[^\s]+)/g;
  const result = reactStringReplace(text,userRegex,function (_handle) {
    var user = mentionedUsers.find((u) => u.handle == _handle)

    return <UserMention
      user={user}
    />
  })
  return result
}
const renderGreentext = (text) => {
  var greentextRegex = /^>(.+)/gm;
  const result = reactStringReplace(text,greentextRegex,function (greentext) {
    greentext = ">" + greentext
    return <Greentext
      text={greentext}
    />
  })
  return result
}
const renderReverseGreentext = (text) => {
  var greentextRegex = /^<(.+)/gm;
  const result = reactStringReplace(text,greentextRegex,function (greentext) {
    greentext = "<" + greentext
    return <Greentext
      text={greentext}
      reverseGreentext
    />
  })
  return result
}

function isSiteUrl(url) {
  var metaUrl = new URL(url).host;
  var host = window.location.host
  if (metaUrl == host) {
    return true;
  } else {
    return false;
  }
}

const urlify = (text) => {
  var _url = null
  var urlRegex = /(https?:\/\/[^\s]+)/g;

  const result = reactStringReplace(text,urlRegex,function (_foundUrl) {
    _url = _foundUrl

    if (store.getters['Settings/getSettings'].hidePostEmbedLinks && isSiteUrl(_url)) {
      return ""
    }

    return <a target="_blank" href={_foundUrl}>{_foundUrl}</a>
  })
  return {
    text: result,
    url: _url
  }
  
    // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

function renderEmbed(metadata) {
  return <EmbedWrapper
    metadata={metadata}
  />
}

function parseMessage(text, options) {
    if(text == null)
      return
    if(text.length <= 0) {
      return
    }
    var emoteResult;
    if (options.external) {
      emoteResult = renderExternalEmotes(text, options.tags)
    } else {
      emoteResult = renderEmotes(text)
    }
    const finalResult = urlify(emoteResult)

    if(options.metadata) {
      finalResult.text.push(renderEmbed(options.metadata))
    }
    if (options.mentionedUsers) {
      finalResult.text = findUserMentions(finalResult.text, options.mentionedUsers)
    }
    finalResult.text = renderGreentext(finalResult.text)
    finalResult.text = renderReverseGreentext(finalResult.text)

    if(options.postType.toLowerCase() == 'markdown' || options.postType.toLowerCase() == 'bbcode') {
        finalResult.text = parseBbCode(finalResult.text)
    }

    return finalResult.text

}

// eslint-disable-next-line
const createUrl = (url) => {
  return <a 
    href={url}
    >{url}</a>

}

export {
  parseMessage,
  urlify
}
