<template>
    <div class="login-page">
        <login-panel 
            v-if="displayLogin" 
            @changePanel="displayLogin = !displayLogin"
        />
        <registration-panel 
            v-else 
            @changePanel="displayLogin = !displayLogin"
        />
    </div>
</template>

<script>
import LoginPanel from "@/components/login-panel/login-panel.vue";
import RegistrationPanel from "@/components/registration-panel/registration-panel.vue";

    export default {
        name: "LoginPage",
        props: {
            register: {
                type: Boolean,
                default: false
            } 
        },
        components: {
            "login-panel": LoginPanel,
            "registration-panel": RegistrationPanel
        },
        data() {
            return {
                displayLogin: true
            }
        },
        created() {
            if (this.register) {
                this.displayLogin = false
            }
        }
    }
</script>

<style>
.login-page {
    margin-left: auto;
    margin-right: auto !important;
}
</style>