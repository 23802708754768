<template>
    <div class="sh-media-thumbnail" :class="isModal ? 'modal' : displayImageHeightInThumbnail ? 'display-height' : 'display-width' " :style="thumbnailStyle" @click="handleClick">
        <div class="floating-buttons">
            <sh-icon-button
                v-if="description && !editing"
                ref="desc-button"
                class="transparent-button"
                small
                icon="icon-bubbles"
                :style="getButtonStyle"
                @click="showDescription = !showDescription"
                @mouseleave="showDescription = false"
            >
                <sh-floating-panel
                    class="transparent-panel"
                    maxContent
                    offsetX=25
                    offsetY=-30
                    :show="showDescription"
                >
                    {{ description }}
                </sh-floating-panel>
            </sh-icon-button>
            <sh-icon-button
                v-if="isNsfw && showNsfw && !editing"
                ref="close-nsfw"
                class="transparent-button"
                small
                icon="icon-cross"
                @click="showNsfw = false"
            />
            <sh-icon-button
                v-if="editing"
                class="transparent-button"
                small
                icon="icon-cross"
                @click="$emit('delete')"
            />
        </div>
        <img 
            v-if="isNsfw && !editing && !showNsfw && !isModal"
            ref="nsfw-banner"
            @click="showNsfw = true"
            :src="getNsfwImage"
        >
        <pinch-zoom
            ref="pinchZoomElement"
            class="pinch-zoom"
            v-else-if="getFileType == fileTypes.Image && isModal"
            @click="handleZoomClick"
        >
            <img 
                ref="pinch-img"
                :src="getFile"
            >
        </pinch-zoom>
        <img 
            v-else-if="getFileType == fileTypes.Image"
            @click="$emit('openModal')"
            :src="getFile"
        >
        <audio
            ref="media"
            v-else-if="getFileType == fileTypes.Audio" 
            controls
        >
            <source 
                :src="getFile" 
                :type="getMimeType"
            >
        </audio>
        <video
            ref="media"
            v-else-if="getFileType == fileTypes.Video" 
            controls
        >
            <source 
                :src="getFile" 
                :type="getMimeType"
            >
        </video>
        <div 
            v-if="editing && !isModal"
            class="editable-values"
            ref="editingButtons"
        >
            <sh-checkbox
                v-model="editedNsfw"
                @click="$emit('updateNsfw', editedNsfw)"
            >{{ $t('component.form.nsfw') }}</sh-checkbox>
            <sh-single-line-text-input
                class="description"
                v-model="editedDescription"
                :placeholder="$t('component.form.description')"
                @change="$emit('updateDescription', editedDescription)"
            />
        </div>
    </div>
</template>

<script>
import ShFloatingPanel from './sh-floating-panel.vue';
import ShIconButton from './sh-icon-button.vue';
import ShSingleLineTextInput from './sh-single-line-text-input.vue';
import ShCheckbox from './sh-checkbox.vue';

import PinchZoom from 'pinch-zoom-element'
import nsfwImage from '@/assets/nsfw_image.gif'
import { loadFullTempFile, loadFullFile, loadExternalFile } from '@/services/api/file-api.service';
import { fileTypes, getFileType} from '@/entities/FileTypes';
import { mapGetters } from 'vuex';
    export default {
        name: "sh-image-thumbnail",
        components: {
            'sh-icon-button' : ShIconButton,
            'sh-floating-panel' : ShFloatingPanel,
            'sh-single-line-text-input': ShSingleLineTextInput,
            'sh-checkbox': ShCheckbox
        },
        props: {
            url: {
                type: String,
                required: false
            },
            folder: {
                type: String,
                required: false
            },
            file: {
                type: String,
                required: false
            },
            description: {
                type: String,
                required: false
            },
            width: {
                type: Number,
                required: true
            },
            isNsfw: {
                type: Boolean,
                default: false
            },
            isModal: {
                type: Boolean,
                default: false
            },
            editing: {
                type: Boolean,
                default: false
            },
            pollEdit: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showNsfw: false,
                showDescription: false,
                fileTypes: fileTypes,
                thumbnailStyle: null,
                editedDescription: null,
                editedNsfw: false
            }
        },
        computed: {
            ...mapGetters({
                displayImageHeightInThumbnail: "Settings/getDisplayImageHeightInThumbnail"
            }),
            getButtonStyle() {
                if(this.isModal) {
                    return 'z-index: 3000;'
                } else {
                    return ''
                }
            },  
            getNsfwImage() {
                return nsfwImage;
            },
            getFileType() {
                return getFileType(this.file);
            },
            getMimeType() {
                var parts = this.file.split('.');
                var extension = parts[parts.length-1];
                if (extension == "mkv") {
                    extension = "mp4"
                }
                return `${this.getFileType}/${extension}`
            },
            getFile() {
                if (this.folder == "ExternalMedia") {
                    return loadExternalFile(this.url);
                } else if (this.editing || this.pollEdit) {
                    return loadFullTempFile(this.url);
                } else {
                    return loadFullFile(this.url);
                }
            },
            getHeight() {
                return this.$el.parentNode.offsetHeight;
            },
            getStyle() {
                if (this.pollEdit) { return `` }
                return `height: ${this.getHeight}px; width: ${this.width}%; max-height: ${this.getHeight}px;`
            },
            getUpdatedStyle() {
                return `height: ${this.$el.parentNode.offsetHeight}px; width: ${this.width}%; max-height: ${this.$el.parentNode.offsetHeight}px;`
            }
        },
        methods: {
            handleZoomClick(event) {
                if (event.srcElement == this.$refs['pinch-img']) {
                    return;
                } else {
                this.$emit('close');
                }
            },
            handleClick(event) {
                if (this.isNsfw) {
                    // we clicked the button to toggle off nsfw
                    if (this.$refs['nsfw-banner'] && !this.$refs['close-nsfw']) {
                        return;
                    }
                    // we clicked to uncover nsfw
                    if (!this.$refs['nsfw-banner'] && this.$refs['close-nsfw']) {
                        return;
                    }
                }
                if (this.description) {
                    if (this.$refs['desc-button']) {
                        if (this.$refs['desc-button'].$el.contains(event.srcElement)) {
                            return;
                        }
                    }
                }
                if (this.editing) {
                    if (this.$refs['editingButtons']) {
                        if (this.$refs['editingButtons'].contains(event.srcElement)) {
                            return;
                        }
                    }
                }
                if (event.srcElement == this.$refs['media']) {
                    return;
                }
                this.$emit('openModal');
            },
            setStyle() {
                this.$nextTick(() => {
                    this.thumbnailStyle = this.getUpdatedStyle;
                })
            },
            adjustPinchImage(image) {
                var pinch = this.$refs['pinchZoomElement'];
                if (image == null) {
                    image = this.$refs['pinch-img']
                }

                var scale = 1;
                if (image.naturalHeight > window.innerHeight) {
                    scale = (window.innerHeight/image.naturalHeight) - 0.05
                    // (1920/200) + 10.4
                }

                pinch.scaleTo(scale, {
                    // Transform origin. Can be a number, or string percent, eg "50%"  
                    originX: 0,
                    originY: 0,
                    // Should the transform origin be relative to the container, or content?
                    relativeTo: "container",
                    // Fire a 'change' event if values are different to current values
                    allowChangeEvent: false,
                });
            },
        },
        mounted() {
            this.thumbnailStyle = this.getStyle;
            if (this.isModal) {
                this.adjustPinchImage();
                this.$refs['pinch-img'].onload = () => this.adjustPinchImage(this.$refs['pinch-img']);
            }
        },
        created() {
            (() => PinchZoom) ();
        }
    }
</script>

<style lang="scss">
.sh-media-thumbnail {
    overflow: hidden;
    border-style: solid;
    border-width: thin;
    border-color: var(--thumbnailBorder);
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
    .pinch-zoom {
        width: 100vw;
        height: 100vh;
    }
    &.modal {
        background-color: none;
        border: none;
    }
    .floating-buttons {
        position: absolute;
        top: 2%;
        right: 2%;
        display: flex;
        column-gap: 0.2rem;
        z-index: 405;
        .transparent-button {
            background-color: var(--panelTransparent) !important;
        }
    }
    .transparent-panel {
        background-color: var(--panelTransparent) !important;
        max-width: 2000%;
    }
    .editable-values {
        position: absolute;
        bottom: 0.5rem;
        width: 90%;
        column-gap: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--panelTransparent);
        padding: 0.5rem;
        z-index: 400;
        .description {
            width: 100%;
        }
    }
    video {
        height: inherit;
        max-width: 100%;
        max-height: 100%;
    }
    img {
        max-width: 100%;
    }
    img, video, audio {
        z-index: 300;
    }
}
.display-height {
    img {
        max-height: 100%;
    }
}
</style>