<template>
    <div class="user-backgrounds">
        <input 
            id="upload-background"
            type="file"
            accept="image/x-ping, image/gif, image/jpeg, image/jpg"
            @change="uploadFile"
        />
        <label for="upload-background"> {{  $t('component.settings.user-panel.upload-background') }}</label>
        <h4> {{ $t('component.settings.user-panel.user-backgrounds')}}</h4>
        <sh-background-gallery
            v-if="backgrounds"
            :media="backgrounds"
            @delete="deleteBackground"
        />
    </div>
</template>
<script>
import ShBackgroundGallery from "@/components/common/sh-background-gallery"
import { uploadBackground, deleteBackground } from "@/services/api/user-customisation-api.service";
import { mapMutations } from "vuex";
    export default {
        name: "user-backgrounds",
        components: {
            "sh-background-gallery": ShBackgroundGallery
        },
        props: {
            backgrounds: {
                type: Object,
                required: false
            }
        },
        methods: {
            ...mapMutations({
                setUserCustomisation: 'User/setUserCustomisation'
            }),
            async uploadFile(event) {
                var file = event.target.files[0];
                var custom = await uploadBackground(file);
                this.setUserCustomisation(custom);
            },
            async deleteBackground(background) {
                var custom = await deleteBackground(background.name);
                this.setUserCustomisation(custom);
            }
        }

    }
</script>
<style lang="scss" scoped>
.user-settings {
    width: 100%;
    display: flex;
    column-gap: 1rem;
}
</style>