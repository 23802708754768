<template>
    <div class="sh-poll-option" :class="option.hasMedia ? 'media' : ''">
        <div class="option-wrapper" v-if="isEditing">
            <sh-media-thumbnail v-if="option.hasMedia"
                pollEdit
                :folder="option.media.folder"
                :file="option.media.url"
                :url="option.media.url"
                :isNsfw="option.media.isNsfw"
                :description="option.media.description"
            />
            <input
                v-else-if="isMediaPoll"
                type="file"
                @change="(e) => $emit('uploadPollMedia', e)"
            >
            <sh-single-line-text-input  
                v-else
                :placeholder="$t('component.form.poll-option', [index+1])"
                v-model="label"
                :modelValue="option.label"
                @change="$emit('changeLabel', label)"
            />

            <sh-icon-button
                v-if="index > 1"
                small
                rounded
                noBackground
                icon="icon-cross"
                @click="$emit('delete', index)"
            />
        </div>
        <div class="option-wrapper" v-else  @click="vote" :style="option.hasMedia ? optionStyle : ''" :class="option.hasMedia ? 'media' : ''"> 
            <sh-media-thumbnail v-if="option.hasMedia"
                :folder="option.media.folder"
                :file="option.media.url"
                :url="option.media.url"
                :isNsfw="option.media.isNsfw"
                :width="fileWidth"
                :description="option.media.description"
                isPoll
            />
            <div class="label">{{ option.label }}</div>
        </div>
        <div v-if="!isEditing" class="background-filler" :style="getWidth"></div>
    </div>
</template>
<script>
import ShSingleLineTextInput from './sh-single-line-text-input.vue';
import ShIconButton from './sh-icon-button.vue';
import ShMediaThumbnail from './sh-media-thumbnail.vue';
import { votePost } from '@/services/api/post-api.service';
    export default {
        name: "sh-poll-option",
        components: {
            "sh-single-line-text-input": ShSingleLineTextInput,
            "sh-icon-button": ShIconButton,
            "sh-media-thumbnail": ShMediaThumbnail,
        },
        props: {
            postUrl: {
                type: String,
                required: true
            },
            index: {
                type: Number,
                required: false
            },
            option: {
                type: Array,
                required: true
            },
            totalVotes: {
                type: Number,
                required: true
            },
            totalOptions: {
                type: Number,
                required: true
            },
            hasVoted: {
                type: Boolean,
                default: false
            },
            isEditing: {
                type: Boolean,
                default: false
            },
            isMediaPoll: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                label: null
            }
        },
        computed: {
            optionStyle() {
                return { 'padding-bottom': `${(75 / (this.totalOptions + 0.9))}%` }

            },
            fileWidth() {
                return 75 / (this.totalOptions + 0.9)
            },
            getWidth() {
                if(this.totalVotes > 0)
                    return `width: ${this.option.voters.length/this.totalVotes * 100}%;`
                return 'width: 0%;'
            },
        },
        methods: {
            async vote() {
                if (this.hasVoted) { return; }
                var post = await votePost(this.postUrl, this.option.number);

                this.$emit('updatePost', post);
            }
        }
    }
</script>
<style lang="scss" scoped>
.sh-poll-option {
    border-color: #939393;
    border-style: solid;
    border-width: thin;
    position: relative;
    &.media {
        border-style: none;
        &:hover {
            background-color: transparent;
        }
    }
    &:hover {
        background-color: var(--pollHover);
        cursor: pointer;
    }
    .option-wrapper {
        padding: 0.25rem;
        display: flex;
        &.media {
            height:0;
            &:hover {
                > div {
                    background-color: var(--pollHover);
                    cursor: pointer;
                }
            }
        }
        .label {
            z-index: 10;
        }
    }
    .background-filler {
        top: 0;
        left: 0;
        height: 100%;
        position: absolute;
        background-color: var(--pollVote);
    }
}
</style>