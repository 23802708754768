<template>
  <navigation-bar @showSettings="showSettings = true" @showForm="showForm = true"/>
  <settings-modal :show="showSettings" @close="showSettings = false"/>
  <form-modal :show="showForm" @close="showForm = false"/>
  <alert-modal/>
  <div class="taco">
    <user-panel class="user-panel"></user-panel>
    <div class="router-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
import UserPanel from '@/components/user-panel/user-panel.vue';
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue"
import SettingsModal from "@/components/settings-modal/settings-modal.vue"
import FormModal from "@/components/form-modal/form-modal.vue"
import AlertModal from "@/components/alert-modal/alert-modal.vue"
  export default {
    name: "App",
    components: {
      'navigation-bar': NavigationBar,
      'settings-modal': SettingsModal,
      'form-modal': FormModal,
      'alert-modal': AlertModal,
      'user-panel': UserPanel,
    },
    data() {
      return {
        showSettings: false,
        showMenu: false,
        showForm: false,
      }
    }
  }
</script>

<style lang="scss">
@import '@/assets/css/style.css';
body {
  background-color: var(--background);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text);
}
.taco {
  margin-top: 3rem;
}
.hidden {
  display: none;
}
.like {
  color: var(--likeIcon);
}
.repost {
  color: var(--repeatIcon);
}
a {
    font-weight: bold;
    color: var(--link);
    text-decoration: none;
    &.router-link-exact-active {
      color: var(--linkActive);
    }
  }
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: var(--text);

    &.router-link-exact-active {
      color: var(--linkActive);
    }
  }
}

.user-panel {
    width: 20vw;
    position: absolute;
    border-radius: .25rem;
    background-color: var(--panelHover);
    box-shadow: 0px 1px 6px 2px var(--shadow);
    @media(max-width: 1279px) {
        display: none;
    }
    @media(min-width: 1280px) {
        width: 25vw;
    }
    @media(min-width: 1366px) {
        width: 23vw;
        margin-right: 57rem;
        padding: 0.3rem 0.5rem;
    }
    @media(min-width: 1600px) {
        width: 27vw;
        margin-right: 70rem;
        padding: 0.3rem 0.5rem;
    }
    @media(min-width: 2560px) {
        margin-right: 64rem;
        width: 17vw;
        padding: 0.3rem 0.5rem;
    }
}

.router-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    column-gap: 3rem;
    max-height: 94vh;
    overflow-y: auto;
    > div {
      @media(max-width: 1366px) {
        margin-right: -10rem;
      }
    }
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #5e5c5c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #535151;
}

</style>
