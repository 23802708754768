<template>
    <div class="sh-media-gallery">
        <sh-media-modal
            :media="media"
            :displayedImage="displayedImage"
            :show="showModal"
            :editing="editing"
            @close="showModal = false"
        />
        <div 
            v-if="hasNsfw && !editing"
            class="nsfw-button" 
            @click="showAllNsfw = !showAllNsfw"
        > {{ getShowNsfwText }}</div>
        <div 
            v-for="(row, index) in rows"
            :key="index"
            :style="rowStyle(row)"
            class="gallery-row"
        >
            <sh-media-thumbnail
                v-for="m in row"
                :ref="m.url"
                :key="m.url"
                :folder="m.folder"
                :file="m.url"
                :url="m.url"
                :isNsfw="showAllNsfw ? false : m.isNsfw"
                :description="m.description"
                :width="fileWidth(row)"
                :editing="editing"
                @openModal="openModal(m)"
                @delete="$emit('delete',m)"
                @updateDescription="(e) => updateDescription(e,m)"
                @updateNsfw="(e) => updateNsfw(e,m)"
            >
            </sh-media-thumbnail>
        </div>
    </div>
</template>

<script>
import ShMediaThumbnail from "@/components/common/sh-media-thumbnail.vue";
import ShMediaModal from "@/components/common/sh-media-modal.vue";
    export default {
        name: "sh-media-gallery",
        components: {
            "sh-media-thumbnail": ShMediaThumbnail,
            "sh-media-modal": ShMediaModal,
        },
        data() {
            return {
                showAllNsfw: false,
                showModal: false,
                displayedImage: 0
            }
        },
        props: {
            media: {
                type: Array,
                required: true
            },
            rowLimit: {
                type: Number,
                required: false,

            },
            grid: {
                type: Boolean,
                default: false
            },
            maxPerRow: {
                type: Number,
                default: 3
            },
            editing: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hasNsfw() {
                return this.media.some((m) => m.isNsfw);
            },
            getShowNsfwText() {
                if (this.showAllNsfw) {
                    return this.$t('component.post.hide-all-nsfw');
                } else {
                    return this.$t('component.post.show-all-nsfw');
                }
            },
            getMaxPerRow() {
                if (this.editing) { return this.maxPerRow; }
                if (this.media.length > 4) { return this.maxPerRow; }
                else { return 2}
            },
            rows() {
                var rows = [];
                var curImage = 0;
                var completedRows = 0;
                var rowCount = Math.round(this.media.length / this.getMaxPerRow);
                while (completedRows < rowCount) {
                    rows.push(this.createRow(curImage));
                    completedRows++;
                    curImage += Number(this.getMaxPerRow);
                }

                return rows;
            }
        },
        methods: {
            openModal(media) {
                this.displayedImage = this.media.indexOf(media);
                this.showModal = true;
            },
            updateDescription(description, row) {
                this.$emit('updateDescription', { item: row, value: description });
            },
            updateNsfw(nsfw, row) {
                this.$emit('updateNsfw', { item: row, value: nsfw });
            },
            rowStyle(row) {
                return { 'padding-bottom': `${(75 / (row.length + 0.9))}%` }

            },
            fileWidth(row) {
                return 75 / (row.length + 0.9)
            },
            createRow(start) {
                var row = []
                var pushed = start;
                var done = 0;
                
                while (done < this.getMaxPerRow && pushed < this.media.length) {
                    row.push(this.media[pushed]);
                    done++;
                    pushed++;
                }
                return row;
            }
        },
        watch: {
            media: {
                deep: true,
                handler() {
                    this.media.forEach((media) => {
                        var reference = this.$refs[media.url]; 
                        if (reference && reference.length > 0) {
                            reference[0].setStyle()
                        }
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
.sh-media-gallery {
    width: 100%;
    .nsfw-button {
        font-size: xx-small;
        text-align: left;
        color: var(--link);
        user-select: none;
        margin-bottom: .1rem;
        &:hover {
            cursor: pointer;
        }
    }
    .gallery-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 0;
        column-gap: 1rem;
        margin-bottom: 0.3rem;
    }

}
</style>