<template>
    <div 
        class="sh-reaction"
        @mouseenter="startCountdown"
        @mouseleave="stopCountdown"
    >
        <sh-emoji
            :emoji="react.emoji"
        />
        <span class="react-count">
            <sh-floating-panel 
                :show="showReacts"
                @closed="showReacts = false"
                clickable
                instantFade
            >
                <sh-user-result class="dropdown-user-result" v-for="user in react.reactors" :key="user.url" :user="user"/>
            </sh-floating-panel>
            {{  react.reactors.length }}
        </span>
    </div>
</template>
<script>
import ShFloatingPanel from './sh-floating-panel.vue';
import ShUserResult from './sh-user-result.vue';
import ShEmoji from './sh-emoji.vue';
    export default {
        name: "sh-reaction",
        components: {
            "sh-floating-panel": ShFloatingPanel,
            "sh-user-result": ShUserResult,
            "sh-emoji": ShEmoji
        },
        props: {
            react: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                showReacts: false,
                isAllowed: false
            }
        },
        methods: {
            startCountdown() {
                setTimeout(this.showReactionUsers, 500);
                this.isAllowed = true;
            },
            stopCountdown() {
                this.isAllowed = false;
                this.showReacts = false;
            },
            showReactionUsers() {
                if (this.isAllowed) {
                    this.showReacts = true;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.sh-reaction {
    background-color: var(--react);
    display: flex;
    padding: 0.25rem;
    border-radius: 0.25rem;
    align-items: center;
    &:hover {
        background-color: var(--reactHover);
        cursor: pointer;
    }
    .react-count {
        position: relative;
    }
}
</style>