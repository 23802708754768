<template>
    <div class="sh-reactions">
        <sh-reaction
            v-for="react in getReactGroups"
            :key="react.name"
            :react="react"
            @click="handleReact(react.name)"
        />
    </div>
</template>
<script>
import ShReaction from './sh-reaction.vue';
import { reactPost,unReactPost } from "@/services/api/post-api.service";
import { isLoggedIn } from "@/services/permission-handler.service"
import { mapGetters } from "vuex";
    export default {
        name: "sh-reactions",
        props: {
            postUrl: {
                type: String,
                required: true
            },
            interactions: {
                type: Object,
                required: true
            }
        },
        components: {
            "sh-reaction": ShReaction
        },
        computed: {
            ...mapGetters({
                getUser: "User/getUrl"
            }),
            getReactions() {
                var reactions = this.interactions.filter((i) => i.type.toLowerCase() == 'react');
                return reactions;
            },
            getReactGroups() {
                var groups = {};
                var reactions = this.getReactions
                for (var i = 0; i < reactions.length; i++) {
                    if (groups[reactions[i].emoji.name]) {
                        groups[reactions[i].emoji.name].reactors.push(reactions[i].user);
                    } else {
                        groups[reactions[i].emoji.name] = {
                            name: reactions[i].emoji.name,
                            reactors: [ reactions[i].user ],
                            emoji: reactions[i].emoji
                        }
                    }
                }
                return groups;
            }
        },
        methods: {
            hasReacted(reaction) {
                var reactions = this.getReactions;

                return reactions.some((r) => r.emoji.name == reaction && r.user.url == this.getUser)
            },
            async handleReact(reaction) {
                if (isLoggedIn()) {
                    var response;
                    if (this.hasReacted(reaction)) { 
                        response = await unReactPost(this.postUrl, reaction);
                    } else {
                        response = await reactPost(this.postUrl, reaction);
                    }
                    this.$emit('updatePost', response);
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
.sh-reactions {
    display: flex;
    margin: 0.5rem 0;
    column-gap: 0.25rem;
    flex-flow: wrap;
}
</style>