
import BbCode from '@/components/text/bbcode/sh-bbcode.vue'
import reactStringReplace from "react-string-replace"

const renderItalics = (text) => {
    var greentextRegex = /\[i\](.*?)\[\/i\]/gm;
    const result = reactStringReplace(text,greentextRegex,function (replace) {
      return <BbCode
        text={replace}
        tag="italics"
      />
    })
    return result
  }
const renderBold = (text) => {
  var greentextRegex = /\[b\](.*?)\[\/b\]/gm;
  const result = reactStringReplace(text,greentextRegex,function (replace) {
    return <BbCode
      text={replace}
      tag="bold"
    />
  })
  return result
}
const renderStrikethrough = (text) => {
  var greentextRegex = /\[s\](.*?)\[\/s\]/gm;
  const result = reactStringReplace(text,greentextRegex,function (replace) {
    return <BbCode
      text={replace}
      tag="strikethrough"
    />
  })
  return result
}
const renderSpoiler = (text) => {
  var greentextRegex = /\[spoiler\](.*?)\[\/spoiler\]/gm;
  const result = reactStringReplace(text,greentextRegex,function (replace) {
    return <BbCode
      text={replace}
      tag="spoiler"
    />
  })
  return result
}
const renderSpoilerShort = (text) => {
  var greentextRegex = /\[sp\](.*?)\[\/sp\]/gm;
  const result = reactStringReplace(text,greentextRegex,function (replace) {
    return <BbCode
      text={replace}
      tag="spoiler"
    />
  })
  return result
}

const parseMessage = (text) => {
    text = renderItalics(text)
    text = renderBold(text)
    text = renderStrikethrough(text)
    text = renderSpoilerShort(text)
    text = renderSpoiler(text)

    return text
}


export {
    parseMessage
}

export default parseMessage