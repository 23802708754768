<template>
    <div>

    </div>
</template>
<script>
import { confirmUser } from "@/services/api/user-api.service"
    export default {
        name: "ConfirmationView",
        created() {
            var token = this.$route.params.id;

            confirmUser(token)

            this.$store.dispatch('Alert/addAlert', {
                label: this.$t('common.confirmed'),
                expireTime: 5000,
                type: 'success'
            })

            this.$router.push({ name: 'home'})
        }
    }
</script>