<template>
    <sh-emoji-picker class="emoji-view" show/>
</template>

<script>
import ShEmojiPicker from "@/components/common/sh-emoji-picker.vue"
    export default {
        name: "EmojiView",
        components: {
            "sh-emoji-picker": ShEmojiPicker
        }
    }
</script>
<style scoped>
.emoji-view {
    top: 0 !important;
    width: 40rem !important;
    max-height: max-content;
    position: relative;
    overflow-y: auto;
    background-color: var(--panelHover);
}
</style>