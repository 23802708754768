<template>
    <div class="notification-body">
        <post-body
            class="inner-body"
            :body="post.body"
            :metadata="post.metadata"
            :type="post.type"
            :mentionedUsers="post.mentionedUsers"
            :isExternal="post.isExternal"
            :externalTags="post.tagObjects"
                        
        />
        <sh-media-gallery
            v-if="post.hasMedia"
            :media="post.media"
        />
    </div>
</template>
<script>
import PostBody from "@/components/post-body/post-body.vue"
import ShMediaGallery from "@/components/common/sh-media-gallery.vue"
    export default {
        name: "notification-body",
        components: {
            "post-body": PostBody,
            "sh-media-gallery": ShMediaGallery,
        },
        props: {
            post: {
                type: Object,
                required: true
            }
        }
    }
</script>
<style lang="scss">
.notification-body {
    padding: 0.25rem;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 3px 2px var(--shadow);
    .body {
        padding: .5rem;
    }
}
</style>