<template>
    <div class="thread-view">
        <timeline-panel
            class="thread-panel"
            ref="timeline"
            name="threadView"
            :threadUrl="getThread"
            isThread
        ></timeline-panel>
    </div>
</template>

<script>
import TimelinePanel from '@/components/timeline-panel/timeline-panel.vue';
import { mapGetters } from 'vuex';
    export default {
        name: "TimelineView",
        components: {
            "timeline-panel": TimelinePanel,
        },
        computed: {
            ...mapGetters({
                getTimeline: 'Timeline/getPosts',
            }),
            getThread() {
                return this.$route.params.id;
            }
        },
        watch: {
            getThread() {
                if(!this.getTimeline('threadView').some((e) => e.url == this.getThread)) {
                    this.$nextTick(() => {
                        this.$refs['timeline'].fetchPosts();
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.thread-view { 
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    column-gap: 3rem;
    .thread-panel {
        background-color: var(--background);
        border-radius: 0.25rem;
        box-shadow: 0px 0px 9px 6px var(--shadow);
    }
}
</style>