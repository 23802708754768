<template>
    <div class="sh-alert" :class="alert.type">
        <div class="message">
            {{ alert.label }}
        </div>
        
        <sh-icon-button
            class="close-button"
            small
            noBackground
            @click="remove"
            icon="icon-cross"
        />
    </div>
</template>
<script>
import ShIconButton from './sh-icon-button.vue';

    export default {
        name: "sh-alert",
        components: {
            "sh-icon-button": ShIconButton
        },
        props: {
            alert: {
                type: Object,
                required: true
            }
        },
        methods: {
            remove() {
                this.$emit('remove');
            }
        },
        created() {
            var timeout = this.alert.expireTime ? this.alert.expireTime : 10000

            setTimeout(this.remove, timeout);
        }
    }
</script>
<style lang="scss">
.sh-alert {
    position: relative;
    z-index: 99999;
    margin-top: 1rem;
    min-width: 5vw;
    max-width: 15vw;
    display: flex;
    padding: 0.5rem;
    border-radius: 0.25rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 1px 10px 0px var(--shadow);
    &.error {
        background-color: var(--alertError);
    }
    &.warning {
        background-color: var(--alertWarning);
    }
    &.message {
        background-color: var(--alertMessage);
    }
    &.success {
        background-color: var(--alertSuccess);
    }
    .close-button {
        margin-left: auto;
    }
}
</style>