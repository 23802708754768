import { setHeaders, url } from './api.service'

const SHAKOU_SETTINGS = '/api/settings';

async function getSettings() {
    var headers = setHeaders();
    var response;
    try {
        response = await fetch(url(SHAKOU_SETTINGS), headers);
    } catch {
        return null;
    }

    return await response.json();
}

export { getSettings }