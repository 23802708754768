import store from "@/store";
import { getChats } from "./api/chat-api.service";

async function fetchChats() {
    var chats = await getChats();

    store.dispatch('Chats/setChats',chats);
}

function startFetchingInterval() {
    var existingInterval = store.getters['Chats/getInterval'];
    if (existingInterval) {
        return;
    }

    fetchChats();
    
    var interval = setInterval(
        fetchChats, 5000
    )

    store.dispatch('Chats/setInterval', interval);
}

function clearFetchingInterval() {
    var existingInterval = store.getters['Chats/getInterval'];

    if (existingInterval) {
        clearInterval(existingInterval);
        store.dispatch('Chats/setInterval', null);
    }
}

export {
    startFetchingInterval,
    clearFetchingInterval
}