const defaultState = {
    timelines: []
}

const _getters = {
    getTimeline: (state) => (timelineName) => {
        if (state.timelines[timelineName]) {
            return state.timelines[timelineName]
        } else {
            return null;
        }
    },
    getFetchedPosts: (state) => (timelineName) => {
        if (state.timelines[timelineName]) {
            return state.timelines[timelineName].fetchedPosts.length
        } else {
            return false;
        }
    },
    getPosts: (state) => (timelineName) => {
        if (state.timelines[timelineName]) {
            return state.timelines[timelineName].posts
        } else {
            return null;
        }
    },
    getPage: (state) => (timelineName) => {
        if (state.timelines[timelineName]) {
            return state.timelines[timelineName].page
        } else {
            return 0;
        }
    },
    getMorePosts: (state) => (timelineName) => {
        if (state.timelines[timelineName]) {
            return state.timelines[timelineName].morePosts
        } else {
            return false;
        }
    },
    getAutomaticallyUpdateTimeline: (state) => (timelineName) => {
        if (state.timelines[timelineName]) {
            return state.timelines[timelineName].automaticallyUpdate
        } else {
            return false;
        }
    },
    getInterval: (state) => (timelineName) => {
        if (state.timelines[timelineName]) {
            return state.timelines[timelineName].fetchPostInterval
        } else {
            return false;
        }
    },
    getLastFetchedId: (state) => (timelineName) => {
        if (state.timelines[timelineName]) {
            return state.timelines[timelineName].lastFetchedId
        } else {
            return false;
        }
    },
    getLastFetchedCreatedDate: (state) => (timelineName) => {
        if (state.timelines[timelineName]) {
            return state.timelines[timelineName].lastFetchedCreatedDate
        } else {
            return false;
        }
    },
    getType: (state) => (timelineName) => {
        if (state.timelines[timelineName]) {
            return state.timelines[timelineName].type
        } else {
            return false;
        }
    },
}

const _mutations = {
    toggleMute(state, toggleObject) {
        if (toggleObject.post == null) { return; }
        var index = state.timelines[toggleObject.timelineName].posts.findIndex((p) => p.url == toggleObject.post.url);
        state.timelines[toggleObject.timelineName].posts[index].isMuted = !state.timelines[toggleObject.timelineName].posts[index].isMuted;
    },
    toggleBookmark(state, toggleObject) {
        if (toggleObject.post == null) { return; }
        var index = state.timelines[toggleObject.timelineName].posts.findIndex((p) => p.url == toggleObject.post.url);
        state.timelines[toggleObject.timelineName].posts[index].isBookmarked = !state.timelines[toggleObject.timelineName].posts[index].isBookmarked;
    }
}

const _actions = {
    updatePostInAllTimelines({ state }, updateObject) {
        for (var key in state.timelines) {
            var index = state.timelines[key].posts.findIndex((p) => p.url == updateObject.url);
            state.timelines[key].posts[index] = updateObject;
        }
    },
    updatePost({ state, dispatch }, updateObject) {

        if (updateObject.post == null) { return; }
        var index = state.timelines[updateObject.timelineName].posts.findIndex((p) => p.url == updateObject.post.url);
        state.timelines[updateObject.timelineName].posts[index] = updateObject.post;

        if (!updateObject.updatedInNotification) {
            updateObject.post.updatedInTimeline = true
            dispatch("Notifications/updateNotification",updateObject.post,{ root: true});
        }
    },
    createTimeline({ state }, timelineObject) {
        state.timelines[timelineObject.timelineName] = {
            posts: timelineObject.posts,
            fetchedPosts: [],
            page: timelineObject.page,
            type: timelineObject.type,
            lastFetchedId: timelineObject.posts[0]?.id,
            lastFetchedCreatedDate: timelineObject.posts[0]?.createdDate,
            automaticallyUpdate: timelineObject.automaticallyUpdate,
            morePosts: true,
            fetchPostInterval: null,
        }
    },
    clearTimeline({ state }, name) {
        if (state.timelines[name]?.fetchPostInterval) {
            clearInterval(state.timelines[name].fetchPostInterval)
        }
        state.timelines[name] = null;
    },
    addPosts({ state, dispatch }, timelineObject) {
        // Timeline Exists
        if (state.timelines[timelineObject.timelineName]) {
            if (timelineObject.posts == null || timelineObject.posts.length <= 0) {
                state.timelines[timelineObject.timelineName].morePosts = false
            }
            state.timelines[timelineObject.timelineName].posts = state.timelines[timelineObject.timelineName].posts.concat(timelineObject.posts);
            state.timelines[timelineObject.timelineName].page = timelineObject.page;
        } else {
            dispatch('createTimeline',timelineObject);
        }
    },
    addFetchedPosts({ state, dispatch }, timelineObject) {
        var name = timelineObject.timelineName;
        var posts = timelineObject.posts;

        var timeline = state.timelines[name];
        if (!timeline) {
            return;
        }

        state.timelines[name].lastFetchedId = posts[0].id;
        state.timelines[name].lastFetchedCreatedDate = posts[0].createdDate;
        state.timelines[name].fetchedPosts = timeline.fetchedPosts.concat(posts);

        if (state.timelines[name].automaticallyUpdate) {
            dispatch('mergeFetchedPosts', name)
        }
    },
    mergeFetchedPosts({ state }, timelineName) {
        var timeline = state.timelines[timelineName];
        if (!timeline) {
            return;
        }
        if (timeline.fetchedPosts.length <= 0) {
            return;
        }

        state.timelines[timelineName].posts = timeline.posts.concat(timeline.fetchedPosts);
        state.timelines[timelineName].fetchedPosts = [];
        state.timelines[timelineName].posts.sort((a,b) => {
            return new Date(b.createdDate) - new Date(a.createdDate)
        });
    },
    setInterval({ state }, timelineObject) {
        state.timelines[timelineObject.timelineName].fetchPostInterval = timelineObject.interval;
    }
}

const timeline = {
    namespaced: true,
    state: defaultState,
    getters: _getters,
    mutations: _mutations,
    actions: _actions
}

export default timeline