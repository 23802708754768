<!-- eslint-disable no-unused-vars -->
<template>
    <div class="sh-dropdown-list-item" @click="onClick">
        <span 
            v-if="icon"
            :class="icon"
        ></span>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "sh-dropdown-list-item",
        props: {
            value: {
                required: true
            },
            icon: {
                type: String,
                required: false
            }
        },
        methods: {
            onClick() {
                this.$emit('clicked', this.value);
            }
        }
    }
</script>

<style lang="scss">
.sh-dropdown-list-item {
    padding: .2rem .5rem;
    display: flex;
    column-gap: 0.4rem;
    &:hover {
        background-color: var(--panelHover);
        cursor: pointer;
    }
}
</style>