<template>
    <div>
        <h5>This will be saved to the server (if you choose to do so), however currently there is no way to select from saved themes!</h5>
        <sh-single-line-text-input
            v-if="isUser"
            :placeholder="$t('page.settings.theme.name')"
            v-model="themeName"
        />
        <div class="section">
            <h5> {{ $t('page.settings.theme.common')}} </h5>
            <sh-color
                :colorObject="localTheme.text"
                :label="$t('theme.text')"
                @colorChange="updateColor($event,'text')"
                @alphaChange="updateAlpha($event,'text')"
            />
            <sh-color
                :colorObject="localTheme.link"
                :label="$t('theme.link')"
                @colorChange="updateColor($event,'link')"
                @alphaChange="updateAlpha($event,'link')"
            />
            <sh-color
                :colorObject="localTheme.linkActive"
                :label="$t('theme.linkActive')"
                @colorChange="updateColor($event,'linkActive')"
                @alphaChange="updateAlpha($event,'linkActive')"
            />
            <sh-color
                :colorObject="localTheme.linkVisited"
                :label="$t('theme.linkVisited')"
                @colorChange="updateColor($event,'linkVisited')"
                @alphaChange="updateAlpha($event,'linkVisited')"
            />
            <sh-color
                :colorObject="localTheme.activeIcon"
                :label="$t('theme.activeIcon')"
                @colorChange="updateColor($event,'activeIcon')"
                @alphaChange="updateAlpha($event,'activeIcon')"
            />
            <sh-color
                :colorObject="localTheme.disabled"
                :label="$t('theme.disabled')"
                @colorChange="updateColor($event,'disabled')"
                @alphaChange="updateAlpha($event,'disabled')"
            />
            <sh-color
                :colorObject="localTheme.shadow"
                :label="$t('theme.shadow')"
                @colorChange="updateColor($event,'shadow')"
                @alphaChange="updateAlpha($event,'shadow')"
            />
            <sh-color
                :colorObject="localTheme.modalWrapper"
                :label="$t('theme.modalWrapper')"
                @colorChange="updateColor($event,'modalWrapper')"
                @alphaChange="updateAlpha($event,'modalWrapper')"
            />
            <sh-color
                :colorObject="localTheme.button"
                :label="$t('theme.button')"
                @colorChange="updateColor($event,'button')"
                @alphaChange="updateAlpha($event,'button')"
            />
            <sh-color
                :colorObject="localTheme.buttonHover"
                :label="$t('theme.buttonHover')"
                @colorChange="updateColor($event,'buttonHover')"
                @alphaChange="updateAlpha($event,'buttonHover')"
            />
            <sh-color
                :colorObject="localTheme.buttonText"
                :label="$t('theme.buttonText')"
                @colorChange="updateColor($event,'buttonText')"
                @alphaChange="updateAlpha($event,'buttonText')"
            />
            <sh-color
                :colorObject="localTheme.buttonIcon"
                :label="$t('theme.buttonIcon')"
                @colorChange="updateColor($event,'buttonIcon')"
                @alphaChange="updateAlpha($event,'buttonIcon')"
            />
        </div>
        <div class="section">
            <h5> {{ $t('page.settings.theme.alerts')}} </h5>
            <sh-color
                :colorObject="localTheme.alertError"
                :label="$t('theme.alertError')"
                @colorChange="updateColor($event,'alertError')"
                @alphaChange="updateAlpha($event,'alertError')"
            />
            <sh-color
                :colorObject="localTheme.alertWarning"
                :label="$t('theme.alertWarning')"
                @colorChange="updateColor($event,'alertWarning')"
                @alphaChange="updateAlpha($event,'alertWarning')"
            />
            <sh-color
                :colorObject="localTheme.alertMessage"
                :label="$t('theme.alertMessage')"
                @colorChange="updateColor($event,'alertMessage')"
                @alphaChange="updateAlpha($event,'alertMessage')"
            />
            <sh-color
                :colorObject="localTheme.alertMessage"
                :label="$t('theme.alertSuccess')"
                @colorChange="updateColor($event,'alertSuccess')"
                @alphaChange="updateAlpha($event,'alertSuccess')"
            />
        </div>
        <div class="section">
            <h5> {{ $t('page.settings.theme.panels')}} </h5>
            <sh-color
                :colorObject="localTheme.background"
                :label="$t('theme.background')"
                @colorChange="updateColor($event,'background')"
                @alphaChange="updateAlpha($event,'background')"
            />
            <sh-color
                :colorObject="localTheme.panel"
                :label="$t('theme.panel')"
                @colorChange="updateColor($event,'panel')"
                @alphaChange="updateAlpha($event,'panel')"
            />
            <sh-color
                :colorObject="localTheme.panelHover"
                :label="$t('theme.panelHover')"
                @colorChange="updateColor($event,'panelHover')"
                @alphaChange="updateAlpha($event,'panelHover')"
            />
            <sh-color
                :colorObject="localTheme.panelTransparent"
                :label="$t('theme.panelTransparent')"
                @colorChange="updateColor($event,'panelTransparent')"
                @alphaChange="updateAlpha($event,'panelTransparent')"
            />
            <sh-color
                :colorObject="localTheme.navbarColour"
                :label="$t('theme.navbarColour')"
                @colorChange="updateColor($event,'navbarColour')"
                @alphaChange="updateAlpha($event,'navbarColour')"
            />
        </div>
        <div class="section">
            <h5> {{ $t('page.settings.theme.posts')}} </h5>
            <sh-color
                :colorObject="localTheme.thumbnailBorder"
                :label="$t('theme.thumbnailBorder')"
                @colorChange="updateColor($event,'thumbnailBorder')"
                @alphaChange="updateAlpha($event,'thumbnailBorder')"
            />
            <sh-color
                :colorObject="localTheme.postBorder"
                :label="$t('theme.postBorder')"
                @colorChange="updateColor($event,'postBorder')"
                @alphaChange="updateAlpha($event,'postBorder')"
            />
            <sh-color
                :colorObject="localTheme.mutedPostBackground"
                :label="$t('theme.mutedPostBackground')"
                @colorChange="updateColor($event,'mutedPostBackground')"
                @alphaChange="updateAlpha($event,'mutedPostBackground')"
            />
            <sh-color
                :colorObject="localTheme.highlightedPost"
                :label="$t('theme.highlightedPost')"
                @colorChange="updateColor($event,'highlightedPost')"
                @alphaChange="updateAlpha($event,'highlightedPost')"
            />
            <sh-color
                :colorObject="localTheme.likeIcon"
                :label="$t('theme.likeIcon')"
                @colorChange="updateColor($event,'likeIcon')"
                @alphaChange="updateAlpha($event,'likeIcon')"
            />
            <sh-color
                :colorObject="localTheme.react"
                :label="$t('theme.react')"
                @colorChange="updateColor($event,'react')"
                @alphaChange="updateAlpha($event,'react')"
            />
            <sh-color
                :colorObject="localTheme.reactHover"
                :label="$t('theme.reactHover')"
                @colorChange="updateColor($event,'reactHover')"
                @alphaChange="updateAlpha($event,'reactHover')"
            />
            <sh-color
                :colorObject="localTheme.thumbnailBorder"
                :label="$t('theme.thumbnailBorder')"
                @colorChange="updateColor($event,'thumbnailBorder')"
                @alphaChange="updateAlpha($event,'thumbnailBorder')"
            />
            <sh-color
                :colorObject="localTheme.likeIcon"
                :label="$t('theme.likeIcon')"
                @colorChange="updateColor($event,'likeIcon')"
                @alphaChange="updateAlpha($event,'likeIcon')"
            />
            <sh-color
                :colorObject="localTheme.greentext"
                :label="$t('theme.greentext')"
                @colorChange="updateColor($event,'greentext')"
                @alphaChange="updateAlpha($event,'greentext')"
            />
            <sh-color
                :colorObject="localTheme.reverseGreentext"
                :label="$t('theme.reverseGreentext')"
                @colorChange="updateColor($event,'reverseGreentext')"
                @alphaChange="updateAlpha($event,'reverseGreentext')"
            />
        </div>
        <div class="section">
            <h5> {{ $t('page.settings.theme.notification')}} </h5>
            <sh-color
                :colorObject="localTheme.unseenNotification"
                :label="$t('theme.unseenNotification')"
                @colorChange="updateColor($event,'unseenNotification')"
                @alphaChange="updateAlpha($event,'unseenNotification')"
            />
            <sh-color
                :colorObject="localTheme.notificationCover"
                :label="$t('theme.notificationCover')"
                @colorChange="updateColor($event,'notificationCover')"
                @alphaChange="updateAlpha($event,'notificationCover')"
            />
            <sh-color
                :colorObject="localTheme.notificationResponseCover"
                :label="$t('theme.notificationResponseCover')"
                @colorChange="updateColor($event,'notificationResponseCover')"
                @alphaChange="updateAlpha($event,'notificationResponseCover')"
            />
        </div>

        <div class="theme-buttons">
            <sh-filled-button @click="saveTheme(localTheme)">{{  $t('page.settings.theme.save') }}</sh-filled-button>
            <sh-filled-button @click="saveThemeToServer" v-if="isUser">{{  $t('page.settings.theme.save-to-server') }}</sh-filled-button>
            <sh-filled-button @click="resetTheme">{{  $t('page.settings.theme.reset') }}</sh-filled-button>
        </div>
    </div>
</template>
<script>
import ShColor from '@/components/common/sh-color.vue';
import ShSingleLineTextInput from '@/components/common/sh-single-line-text-input.vue';
import ShFilledButton from "@/components/common/sh-filled-button.vue";

import { uploadTheme } from '@/services/api/user-customisation-api.service';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { simpleThemeRender } from "@/services/theme-handler.service"
import DefaultTheme from '@/themes/defaultTheme'
    export default {
        name: "theme-settings",
        components: {
            "sh-color": ShColor,
            'sh-single-line-text-input': ShSingleLineTextInput,
            'sh-filled-button' : ShFilledButton,
        },
        props: {
        },
        data() {
            return {
                localTheme: null,
                themeName: null
            }
        },
        computed: {
            ...mapGetters({
                getDefaultTheme: 'Theme/getDefaultTheme',
                getTheme: 'Theme/getTheme',
                isUser: 'User/getUser'
            })
        },
        methods: {
            ...mapMutations({
                setTheme: 'Theme/setTheme',
                setUserCustomisation: 'User/setUserCustomisation'
            }),
            ...mapActions({
                saveTheme: 'Theme/saveThemeService',
                resetTheme: 'Theme/default',
                addAlert: "Alert/addAlert"
            }),
            updateColor(newValue,key) {
                this.localTheme[key].color = newValue
            },
            updateAlpha(newValue,key) {
                this.localTheme[key].alpha = newValue
            },
            async saveThemeToServer() {
                var jsonString = JSON.stringify(this.localTheme);
                if (this.themeName == null || this.themeName == "") { 
                    this.addAlert({
                        label: this.$t('error.no-theme-name'),
                        expireTime: 5000,
                        type: 'error'
                    })
                    return; 
                }
                var filename = this.themeName + ".json";

                const jsonFile = new File([jsonString], filename, { 
                    type: "text/plain"
                });

                var response = await uploadTheme(jsonFile);
                this.setUserCustomisation(response);
                this.addAlert({
                    label: this.$t('page.settings.theme.saved-to-server'),
                    expireTime: 5000,
                    type: 'error'
                })
            }
        },
        created() {
            var theme = this.getTheme;
            if (theme) {
                this.localTheme = structuredClone(theme);
            } else {
                this.localTheme = structuredClone(DefaultTheme);
            }
        },
        watch: {
            localTheme: {
                handler() {
                    this.setTheme(this.theme)
                    simpleThemeRender(this.localTheme)
                },
                deep: true
            }
        }
    }
</script>
<style lang="scss">
.theme-buttons {
    margin: 1rem;
    display: flex;
    column-gap: 3rem;
    justify-content: center;
}
.section {
    display: inline-grid;
}
</style>