<template>
    <div class="user-panel" v-if="isUser">
        <user-info-panel
            class="info"
            :user="getUser"
        />
        <form-panel 
        />
        <div class="link-menu" :class="displayMenuList ? 'dropdown-open' : ''">
            <router-link  
                class="link-item"
                v-for="item in menuItems"
                :key="item.value"
                :to="{name: `${item.value}` }"
                :title="item.label"
            >
                <div 
                    v-if="item.value == 'chat' && unreadCount > 0"
                    class="chat-icon-badge">
                </div>
                <sh-icon-button
                    :icon="item.icon"
                    customSize="3"
                    fontSize="xx-large"
                    small
                    noBackground
                />
            </router-link>
            
            <sh-icon-button
                    @click="displayMenuList = !displayMenuList"
                    class="expand-list-menu"
                    :icon="getListIcon"
                    customSize="3"
                    fontSize="xx-large"
                    small
                    noBackground
                />
        </div>
        <link-menu-list class="menu-dropdown-list" v-if="displayMenuList" />
        <notification-panel

        />
    </div>
</template>

<script>
import FormPanel from "@/components/form-panel/form-panel.vue"
import UserInfoPanel from "@/components/user-info-panel/user-info-panel.vue";
import LinkMenuList from "@/components/link-menu-list/link-menu-list.vue";
import NotificationPanel from "@/components/notification-panel/notification-panel.vue";
import ShIconButton from "@/components/common/sh-icon-button.vue";
import { mapGetters } from "vuex";
export default {
    name: "user-panel",
    components: {
        "form-panel": FormPanel,
        "user-info-panel": UserInfoPanel,
        "notification-panel": NotificationPanel,
        "sh-icon-button": ShIconButton,
        "link-menu-list": LinkMenuList,
    },
    computed: {
        ...mapGetters({
            isUser: "User/getUrl",
            getUser: "User/getUser",
            unreadCount: "Chats/getUnreadMessageCount"
        }),
        getListIcon() {
            if (this.displayMenuList) {
                return 'icon-keyboard_arrow_up'
            } else {
                return 'icon-keyboard_arrow_down'
            }
        }
    },
    data() {
        return {
            displayMenuList: false,
            menuItems: [
                { value: "home", label: this.$t('component.user-panel.home'), icon: 'icon-home'},
                { value: "notification", label: this.$t('component.user-panel.notifications'), icon: 'icon-bell'},
                { value: "chat", label: this.$t('component.user-panel.chat'), icon: 'icon-bubbles'},
            ]
        }
    }
}
</script>

<style lang="scss">
.user-panel {
    height: 93vh;
    overflow-y:auto;
    .info {
        margin-bottom: .5rem;
    }
    .link-menu {
        display: flex;
        column-gap: 2rem;
        justify-content: center;
        border-color: var(--inputOutline);
        border-style: solid;
        border-width: thin;
        border-radius: 0.25rem;
        position: relative;
        .link-item {
            display: grid;
            position: relative;
            .chat-icon-badge {
                position: absolute;
                height: 1rem;
                width: 1rem;
                background-color: var(--unseenNotification);
                right: 0;
                z-index: 100;
                border-radius: 5rem;
            }
        }
        .expand-list-menu {
            position: absolute;
            right: 2rem;
        }
        &.dropdown-open {
            border-style: solid;
            border-width: thin;
            border-radius: 0.25rem;
            border-bottom: none;
            box-shadow: none;        
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .menu-dropdown-list {
        border-color: var(--inputOutline);
        border-style: solid;
        border-width: thin;
        border-radius: 0.25rem;
        border-top: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
    &::-webkit-scrollbar {
        display: none;
    }
}
</style>