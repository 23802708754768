/* eslint-disable */

import Emoji from '@/components/common/sh-emoji'
import store from '@/store'
import reactStringReplace from "react-string-replace"

function renderEmotes(message) {
  var emojiRegex = /:(.*?):/g;
  var emotes = store.getters['Emojis/getAllEmotes']

  if (emotes.length <= 0) { return message; }
  if (message == null) { return null;}


  var matches = message.matchAll(emojiRegex);
  if (matches == null) { return message;}

  for(var match of matches) {
    const found = emotes.find((emote) => emote.name == match[1]);
    if (found) {
      message = reactStringReplace(message, match[0], function() {
        return <Emoji 
            emoji={found}
        />;
      })
    }
  }

  return message;
}

function renderExternalEmotes(message, tags) {
  var emojiRegex = /:(.*?):/g;
  if (tags == null) { return message; }

  var externalEmotes = tags.filter(e => e.type == "Emoji");

  if (externalEmotes.length <= 0) { return message; }

  var matches = message.matchAll(emojiRegex);
  if (matches == null) { return message;}
  
  for(var match of matches) {
  
    const found = externalEmotes.find((emote) => emote.name == match[0]);
    if (found) {
      message = reactStringReplace(message, match[0], function() {
        return <Emoji 
            emoji={found}
            isExternal
        />;
      })
    }
  }

  return message
}
/*
const renderEmotes = (message, emotes) => {
    if(emotes == null) {
      emotes = store.getters['Emojis/getAllEmotes']
    }
    if(message == null) {
      return null
    }
    const buffer = []
    let messageText = ''
  
    for (let i = 0; i < message.length; i++) {
        const char = message[i]
        if (char === ':') {
          const next = message.slice(i + 1)
          let found = false
          for (const emoji of emotes) {
            if (next.slice(0, emoji.name.length + 1) === (emoji.name + ':')) {
              found = emoji
              break
            }
          }
          if (found) {
            buffer.push(messageText)
            messageText = ''
            buffer.push(createEmote(found))
            i += found.name.length + 1
          } else {
            messageText += char
          }
        } else {
          messageText += char
        }
      }
      if (messageText) buffer.push(messageText)
      
    return buffer
}
*/
export {
  renderEmotes,
  renderExternalEmotes
} 