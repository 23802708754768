<template>
    <div>
        <input
            type="color"
            v-model="color"
        >
        <input
            class="number"
            type="number"
            step=".1"
            min="0"
            max="1"
            v-model="alpha"
        >
        <label>{{ label }}</label>
    </div>
</template>
<script>

export default {
    name: "sh-color",
    props: {
        colorObject: {
            type: Object,
            required: true
        },
        label: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            color: '#000000',
            alpha: 1,
        }
    },
    computed: {
    },
    methods: {
        setValues() {
            this.color = this.colorObject.color
            this.alpha = this.colorObject.alpha
        }
    },
    created() {
        this.setValues()
    },
    watch: {
        color: {
            handler() {
                this.$emit('colorChange',this.color)
            },
            deep: true
        },
        alpha: {
            handler() {
                this.$emit('alphaChange',this.alpha)
            },
            deep: true
        }
    }
}
</script>
<style scoped>
.number {
    width:2rem;
}
</style>