import { url, handleError } from './api.service'
import store from '@/store';

const SHAKOU_UPLOAD_PROFILE_PICTURE = '/api/user-upload/profile-picture';
const SHAKOU_UPLOAD_BACKGROUND = '/api/user-upload/background';
const SHAKOU_UPLOAD_THEME = '/api/user-upload/theme';
const SHAKOU_UPLOAD_MUSIC = '/api/user-upload/music';

const SHAKOU_DELETE_BACKGROUND = (file) => `/api/user-upload/background/${file}`
const SHAKOU_DELETE_THEME = (file) => `/api/user-upload/theme/${file}`
const SHAKOU_DELETE_MUSIC = (file) => `/api/user-upload/music/${file}`

async function uploadProfilePicture(fileData) {
    const formData = new FormData();
    formData.append('File', fileData);
    formData.append('Name', "NewProfilePicture");
    formData.append('Folder', "LiterallyDoesntMatter");

    var response = await fetch(url(SHAKOU_UPLOAD_PROFILE_PICTURE), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : 'Bearer ' + store.state.Authentication.token)
        },
        body: formData
    })

    if (response.status != 200) {
        handleError(response, uploadProfilePicture, fileData);
        return null;
    }

    return await response.json();
}

async function uploadBackground(fileData) {
    const formData = new FormData();
    formData.append('File', fileData);
    formData.append('Name', "NewProfilePicture");
    formData.append('Folder', "LiterallyDoesntMatter");

    var response = await fetch(url(SHAKOU_UPLOAD_BACKGROUND), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : 'Bearer ' + store.state.Authentication.token)
        },
        body: formData
    })

    if (response.status != 200) {
        handleError(response, uploadBackground, fileData);
        return null;
    }

    return await response.json();
}

async function uploadTheme(fileData) {
    const formData = new FormData();
    formData.append('File', fileData);
    formData.append('Name', "NewProfilePicture");
    formData.append('Folder', "LiterallyDoesntMatter");

    var response = await fetch(url(SHAKOU_UPLOAD_THEME), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : 'Bearer ' + store.state.Authentication.token)
        },
        body: formData
    })

    if (response.status != 200) {
        handleError(response, uploadTheme, fileData);
        return null;
    }

    return await response.json();
}

async function uploadMusic(fileData) {
    const formData = new FormData();
    formData.append('File', fileData);
    formData.append('Name', "NewProfilePicture");
    formData.append('Folder', "LiterallyDoesntMatter");

    var response = await fetch(url(SHAKOU_UPLOAD_MUSIC), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : 'Bearer ' + store.state.Authentication.token)
        },
        body: formData
    })

    if (response.status != 200) {
        handleError(response, uploadMusic, fileData);
        return null;
    }

    return await response.json();
}

async function deleteBackground(file) {
    var response = await fetch(url(SHAKOU_DELETE_BACKGROUND(file)), {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : 'Bearer ' + store.state.Authentication.token)
        }
    })
    if (response.status != 200) {
        handleError(response, deleteBackground, file);
        return null;
    }

    return await response.json();
}

async function deleteTheme(file) {
    var response = await fetch(url(SHAKOU_DELETE_THEME(file)), {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : 'Bearer ' + store.state.Authentication.token)
        }
    })
    if (response.status != 200) {
        handleError(response, deleteBackground, file);
        return null;
    }

    return await response.json();
}

async function deleteMusic(file) {
    var response = await fetch(url(SHAKOU_DELETE_MUSIC(file)), {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : 'Bearer ' + store.state.Authentication.token)
        }
    })
    if (response.status != 200) {
        handleError(response, deleteBackground, file);
        return null;
    }

    return await response.json();
}

export {
    uploadProfilePicture,
    uploadBackground,
    uploadMusic,
    uploadTheme,
    deleteBackground,
    deleteMusic,
    deleteTheme
}
