<template>
    <div 
        :class="getHolderClass"
        :title="emoji.name"
        @mouseenter="isHovering = true"
        @mouseleave="isHovering = false"
    >
        <img 
            :class="getClass"
            :src="getComputedEmoji"
            @click="selectedEmoji"    
        >
        <slot></slot>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { SHAKOU_EMOJI_GET_FILE } from '@/services/api/emoji-api.service';
import { loadExternalFile } from '@/services/api/file-api.service';
import { url } from '@/services/api/api.service';
export default {
    name:"sh-emoji",
    props: {
        emoji: {
            type: String,
            required: true
        },
        users: {
            type: Object,
            required: false
        },
        isReaction: {
            type: Boolean,
            default: false
        },
        isExternal: {
            type: Boolean,
            default: false
        }
    },
    components: {
    },
    data() {
        return {
            source: null,
            isHovering: false
        }
    },
    computed: {
        ...mapGetters({
            isUser: 'User/getUser'
        }),
        getComputedEmoji() {
            if (this.isExternal) {
                return loadExternalFile(this.emoji.id);
            } else {
                return url(SHAKOU_EMOJI_GET_FILE(this.emoji.group, this.emoji.name))
            }
        },
        getHolderClass() {
            if(this.isReaction) {
                if(this.hasReacted) {
                    return 'selected'
                }
                return
            } 
            return "Inline"
        },
        getClass() {
            if(this.isReaction)
                return "Reaction"
            return "Emoji"
        },
        hasReacted() {
            if(!this.isUser)
                return false
            for(let i = 0; i < this.users.length; i++) {
                if(this.users[i].url == this.isUser.url)
                    return true
            }
            return false
        }
    },
    methods: {
        selectedEmoji() {
            if(this.isReaction)
                this.$emit('selectedEmoji',this.emoji)
        }
    }
}
</script>
<style lang="scss" scoped>
.Inline {
    display: inline-block;
}
.Emoji {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    background-size: cover;
    display: inline-flex;
}
.Reaction {
    width: 2.5rem;
    height: 2.5rem;
    background-size: cover;
    object-fit: cover;
    display: inline-flex;
}
.post-react {
    background-color: var(--panel);
    border-radius: 0.3rem;
    box-shadow: 0px 0px 2px 2px  var(--shadow);
    column-gap: 0.4rem;
    display: flex;
    padding: 0.2rem;
    position: relative;
}
.selected {
    background-color: var(--selectedEmote);
}
.reactions {
    left: 6rem;
}
</style>