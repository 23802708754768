<template>
    <img class="sh-profile-picture" :style="getStyle" :src="image">
</template>

<script>
import { loadExternalFile, loadProfilePicture, loadProfilePictureWithName } from "@/services/api/file-api.service"
    export default {
        name: "sh-profile-picture",
        props: {
            src: {
                type: String,
                required: true
            },
            filename: {
                type: String,
                required: true
            },
            width: {
                type: Number,
                default: 4
            },
            rounding: {
                type: Number,
                default: .25
            },
            rounded: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            image() {
                var urlRegex = /(https?:\/\/[^\s]+)/g;

                if (urlRegex.test(this.filename)) {
                    if (this.$store.getters['SiteSettings/getSettings'].useMediaProxy) {
                        return loadExternalFile(this.filename);
                    } else {
                        return this.filename;
                    }
                }
                else if (this.filename) {
                    return loadProfilePictureWithName(this.src, this.filename);
                } else {
                    return loadProfilePicture(this.src);
                }
            },
            getStyle() {
                var styleString = "";

                if (this.rounded) {
                    styleString += `border-radius: ${this.rounding}rem;`;
                }
                styleString += `width: ${this.width}rem; height: ${this.width}rem;`

                return styleString
            }
        }
    }
</script>

<style lang="scss">
.sh-profile-picture {
    height: inherit;
}
</style>