<template>
    <div class="relation-settings">
        <h4>{{ $t('common.followers') }}</h4>
        <sh-user-relation
            v-for="follower in followers"
            :key="follower.user.url"
            follower
            :relation="follower"
            @accept="confirm(follower.user.url, true)"
            @deny="confirm(follower.user.url, false)"
        />
    </div>
</template>
<script>
import ShUserRelation from '@/components/common/sh-user-relation.vue';
import { getOwnFollowers, confirmFollow } from '@/services/api/user-api.service';
    export default {
        name: "relation-settings",
        components: {
            "sh-user-relation": ShUserRelation
        },
        data() {
            return {
                followers: []
            }
        },
        methods: {
            async getFollowers() {
                this.followers = await getOwnFollowers();
            },
            confirm(url, state) {
                confirmFollow({
                    userUrl: url,
                    accept: state
                });

                let index = this.followers.findIndex(r => r.user.url == url);
                if (!state) {
                    this.followers.splice(index, 1);
                } else {
                    this.followers[index].isActive = true;
                }
            }
        },
        async mounted() {
            await this.getFollowers();
        }
    }
</script>
<style lang="scss">
.relation-settings {
    display: grid;
    row-gap: .5rem;
}
</style>