<template>
    <div class="sh-poll" :class="isEditing ? 'editing' : ''">
        <div 
            v-if="isEditing"
            class="editing-buttons"
        >
            <sh-icon-button
                small
                rounded
                :title="$t('component.form.add-option')"
                v-if="getPermission('Admin')"
                @click="isMediaPoll = !isMediaPoll"
                icon="icon-file-picture"
            />
            <sh-icon-button
                small
                rounded
                :title="$t('component.form.add-option')"
                @click="$emit('addOption')"
                icon="icon-plus"
            />
        </div>
        <sh-poll-option
            v-for="(option, index) in poll"
            :key="option.number"
            :postUrl="postUrl"
            :index="index"
            :option="option"
            :isEditing="isEditing"
            :isMediaPoll="isMediaPoll"
            :hasVoted="getHasVoted"
            :totalVotes="totalVotes"
            :totalOptions="poll.length"
            @uploadPollMedia="(e) => $emit('uploadPollMedia', { 
                event: e,
                index: index
            })"
            @updatePost="(e) => $emit('updatePost',e)"
            @changeLabel="(e) => $emit('changeLabel', {
                index: index,
                label: e
            })"
            @delete="(e) => $emit('delete',e)"
        />
        <div class="votes" v-if="!isEditing">
            {{ $t('component.poll.total-votes', [totalVotes])}}
        </div>
    </div>
</template>
<script>
import ShPollOption from "@/components/common/sh-poll-option.vue";
import ShIconButton from "@/components/common/sh-icon-button.vue";
import { mapGetters } from "vuex";
    export default {
        name: "sh-poll",
        components: {
            "sh-poll-option": ShPollOption,
            "sh-icon-button": ShIconButton
        },
        props: {
            postUrl: {
                type: String,
                required: false
            },
            poll: {
                type: Array,
                required: true
            },
            hasVoted: {
                type: Boolean,
                default: false
            },
            isEditing: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isMediaPoll: false
            }
        },
        computed: {
            ...mapGetters({
                isUser: "User/getUrl",
                getPermission: "Authentication/getPermission"
            }),
            getHasVoted() {
                if (this.isUser) {
                    return this.hasVoted;
                } else {
                    return true;
                }
            },
            totalVotes() {
                var votes = 0;
                if (this.isEditing) { return votes; }
                
                this.poll.forEach((e) => {
                    votes += e.voters.length
                })

                return votes;
            }
        },
        methods: {

        }
    }
</script>
<style lang="scss" scoped>
.sh-poll {
    &.editing {
        width: 98%;
    }
    border-style: solid;
    border-width: thin;
    border-color: var(--thumbnailBorder);
    border-radius: 0.25rem;
    padding: 0.5rem;
    display: grid;
    row-gap: 0.5rem;
    .votes {
        display: flex;
    }
    .editing-buttons {
        margin-bottom: 0.5rem;
        display: flex;
        column-gap: 1rem;
    }
}
</style>