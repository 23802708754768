<template>
    <div class="embed-wrapper">
        <video-embed v-if="isVideo" :url="metadata.url" />
        <post-embed v-else-if="isPost" :metadata="metadata" />
        <embed-card v-else-if="!getSettings.disableLinkEmbeds" :metadata="metadata" />
    </div>
</template>

<script>
/* eslint-disable */
    import EmbedCard from "@/components/embed-card/embed-card.vue"
    import VideoEmbed from "@/components/video-embed/video-embed.vue"
    import PostEmbed from "@/components/post-embed/post-embed.vue"
    import { mapGetters } from "vuex"
    export default {
        name: "embed-wrapper",
        props: {
            metadata: {
                type: Object,
                required: true
            }
        },
        components: {
            "embed-card": EmbedCard,
            "video-embed": VideoEmbed,
            "post-embed": PostEmbed,
        },
        computed: {
            ...mapGetters({
                getSettings: 'Settings/getSettings',
                getSiteSettings : 'SiteSettings/getSettings'
            }),
            isVideo() {
                if (!this.getSiteSettings.videoEmbedding || this.getSettings.disableVideoEmbeds || this.getSettings.disableLinkEmbeds) { return false; }

                const ytRegex = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/g;
                const invidiousRegex = /http(?:s?):\/\/invidious\.*/g;
                if(invidiousRegex.test(this.metadata.url) || ytRegex.test(this.metadata.url)) {
                    return true;
                }
                return false;
            },
            isPost() {
                var metaUrl = new URL(this.metadata.url);
                var host = window.location.host

                if (metaUrl.host == host && metaUrl.pathname.includes("/post")) {
                    return true;
                }

                return false;
            }
        }
    }
</script>

<style lang="scss">
    .embed-wrapper {
    }
</style>