<template>
    <sh-modal-panel class="sh-media-modal" :show="show" useCustomPanel @click="handleClick">
        <sh-icon-button
            class="close-button"
            small 
            customSize=4.5
            fontSize="xx-large"
            noBackground
            icon="icon-cross"
            @click="$emit('close')"
        />
        <sh-media-thumbnail
            ref="imageArea"
            :folder="getDisplayedImage.folder"
            :file="getDisplayedImage.url"
            :url="getDisplayedImage.url"
            :isNsfw="getDisplayedImage.isNsfw"
            :description="getDisplayedImage.description"
            :editing="editing"
            @close="$emit('close')"
            isModal
        />
    </sh-modal-panel>
</template>

<script>
import ShModalPanel from './sh-modal-panel.vue';
import ShIconButton from './sh-icon-button.vue';
import ShMediaThumbnail from './sh-media-thumbnail.vue';
    export default {
        name: "sh-media-modal",
        components: {
            "sh-modal-panel": ShModalPanel,
            "sh-icon-button": ShIconButton,
            "sh-media-thumbnail": ShMediaThumbnail
        },
        props: {
            displayedImage: {
                type: Number,
                default: 0
            },
            media: {
                type: Object,
                required: true
            },
            show: {
                type: Boolean,
                default: false
            },
            editing: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                curImage: 0
            }
        },
        computed: {
            getDisplayedImage() {
                return this.media[this.curImage];
            }
        },
        methods: {
            handleClick(event) {
                if (!this.show) {
                    return;
                }
                if (this.$refs['imageArea'].$el.contains(event.srcElement)) {
                    return;
                } else {
                    this.$emit('close');
                }
            },
            handleKeyEvent(event) {
                event.preventDefault();

                switch(event.key) {
                    case 'ArrowLeft': this.decreaseImageCount(); break;
                    case 'ArrowRight': this.increaseImageCount(); break;
                    default: break;
                }
            },
            decreaseImageCount() {
                if (this.curImage == 0) {
                    this.curImage = (this.media.length-1)
                } else {
                    this.curImage--;
                }
            },
            increaseImageCount() {
                if (this.curImage == (this.media.length-1)) {
                    this.curImage = 0
                } else {
                    this.curImage++;
                }
            }
        },
        beforeUnmount() {
            window.removeEventListener('keydown', this.handleKeyEvent);
        },
        watch: {
            show(newV) {
                if (newV) {
                    this.curImage = this.displayedImage
                    window.addEventListener('keydown', this.handleKeyEvent)
                } else {
                    window.removeEventListener('keydown', this.handleKeyEvent);
                }
            }
        }
    }
</script>

<style lang="scss">
.sh-media-modal {
    .close-button {
        z-index: 3000;
        position:absolute;
        top: 5%;
        right: 5%;
        background-color: var(--panelTransparent);
    }
}
</style>