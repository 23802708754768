import { markRead } from "@/services/api/chat-api.service";

const _state = {
    chats: [],
    lastMessages: [],
    fetchChatInterval: null,
    unreadMessageCount: 0
}

const _getters = {
    getChats(state) { return state.chats; },
    getInterval(state) { return state.fetchChatInterval; },
    getUnreadMessageCount(state) { return state.unreadMessageCount; }
}

const _actions = {
    setChats({ state, dispatch }, _chats) {
        if (_chats == null) { 
            state.chats = []; 
            return; 
        }
        if (_chats.length <= 0) { return; }
        state.chats = _chats;

        var lastMessages = [];
        _chats.forEach((e) => {
            lastMessages.push(e);
        })

        if (lastMessages.length > 0) {
            dispatch('setLastMessage', lastMessages);
        }
    }, 
    setLastMessage({ state, dispatch }, messages) {
        var newMessages = [];
        messages.forEach((e) => {
            if (!e.lastMessage) { return; }
            if (!state.lastMessages.some((c) => c.id == e.lastMessage.id) && !e.lastMessage.isSeen) {
                newMessages.push(e.lastMessage);
            }
        });
        if (newMessages.length > 0) {
            state.unreadMessageCount += newMessages.length;
            state.lastMessages = [...new Set([...state.lastMessages, ...newMessages])];
            dispatch('Favicon/add', state.unreadMessageCount,{ root: true});
        }
    },
    setInterval({ state }, interval) {
        if (state.fetchChatInterval) {
            clearInterval(state.fetchChatInterval);
        }
        state.fetchChatInterval = interval;
    },
    markRead({ state, dispatch }, chatId) {
        markRead(chatId);
        var chat = state.chats.find((c) => c.id == chatId);
        var sliceIndex = state.lastMessages.findIndex((m) => m.id == chat.lastMessage.id);
        if (sliceIndex >= 0) {
            /// WHY??!?!?!?!?!!?!?
            // Why the fuck do I have to call it to console first for it to stick????
            console.log(state.lastMessages.slice(sliceIndex, 1))
            console.log(state.lastMessages.splice(sliceIndex, 1))
            state.lastMessages = state.lastMessages.slice(sliceIndex, 1);
            state.unreadMessageCount = state.lastMessages.length;
            dispatch('Favicon/remove', 1,{ root: true});
        }
    },
    clearChats({ state, getters, dispatch }) {
        dispatch('Favicon/remove', getters.getUnreadMessageCount,{ root: true});
        clearInterval(state.fetchChatInterval);
        state.fetchChatInterval = null;
        state.lastMessages = [];
        state.chats = [];
        state.unreadMessageCount = 0;
    }
}

const chats = {
    namespaced: true,
    state: _state,
    getters: _getters,
    actions: _actions
}

export default chats;