<template>
    <div class="muted-post">
        <span 
            v-if="muteReason"
        > {{  $t('component.muted-post.mute-reason', [muteReason]) }}</span>
        <span 
            v-else
        > {{  $t('component.muted-post.muted') }}</span>
        <sh-icon-button
            small
            noBackground
            class="toggle-mute"
            icon="icon-eye" 
            @click="$emit('showMutedPost')" 
        />
    </div>
</template>

<script>
import ShIconButton from "@/components/common/sh-icon-button.vue";
    export default {
        name: "muted-post",
        props: {
            muteReason: {
                type: String,
                required: false,
                default: null
            }
        },
        components: {
            "sh-icon-button": ShIconButton
        }
    }
</script>

<style lang="scss">
    .muted-post {
        align-items: center;
        display: flex;
        padding: 0.5rem;
        border-color: var(--mutedPostBorder);
        background-color: var(--mutedPostBackground);
        border-style: solid;
        border-width: thin;
        border-radius: 0.25rem;

        .toggle-mute {
            margin-left: auto;
        }
    }
</style>