import { url } from './api.service'
import store from '@/store'

const SHAKOU_FILE = (folder,name) =>`/api/file/${folder}/${name}`
const SHAKOU_FULL_FILE = (fullPath) =>`/api/file/${fullPath}`
const SHAKOU_TEMPORARY_FILE = (folder,name) =>`/api/file/${folder}/${name}?temporary=true`
const SHAKOU_FULL_TEMPORARY_FILE = (fullPath) =>`/api/file/${fullPath}?temporary=true`
const SHAKOU_FILE_PROFILE_PICTURE = (url) =>`/api/file/user/${url}`
const SHAKOU_FILE_PROFILE_PICTURE_WITH_NAME = (url,file) =>`/api/file/user/${url}/${file}`
const SHAKOU_FILE_MUSIC = (url,name) =>`/api/file/user/music/${url}/${name}`
const SHAKOU_FILE_BACKGROUND = (user, name) =>`/api/file/user/background/${user}/${name}`
const SHAKOU_FILE_THEME = (url,name) =>`/api/file/user/theme/${url}/${name}`


function getFile(folder, name) {
    return fetch(url(SHAKOU_FILE(folder,name)))
    .then((response) => {
        if(response.status != 200) {
            return;
        }
        return response;
    })
}

function encodeUrl(_image) {
    const _url = _image.split('/')
    const file = encodeURIComponent(_url[1])
    const finalUrl = _url[0] + '/' + file
    return finalUrl
}

function loadTheme(_user, _file) {
    return url(SHAKOU_FILE_THEME(_user,encodeUrl(_file)));
}
function loadBackground(_user, _file) {
    return url(SHAKOU_FILE_BACKGROUND(_user,encodeURIComponent(_file)));
}
function loadMusic(_user, _file) {
    return url(SHAKOU_FILE_MUSIC(_user,encodeUrl(_file)));
}
function loadProfilePicture(_user) {
    return url(SHAKOU_FILE_PROFILE_PICTURE(_user));
}
function loadProfilePictureWithName(_user, _file) {
    return url(SHAKOU_FILE_PROFILE_PICTURE_WITH_NAME(_user, encodeURIComponent(_file)));
}
function loadTempFile(folder, image) {
    return url(SHAKOU_TEMPORARY_FILE(folder, encodeURIComponent(image),''));
}
function loadFile(folder, image) {
    return url(SHAKOU_FILE(folder, encodeURIComponent(image),''));
}

function loadFullTempFile(fullPath) {
    return url(SHAKOU_FULL_TEMPORARY_FILE(encodeUrl(fullPath)));
}
function loadFullFile(fullPath) {
    return url(SHAKOU_FULL_FILE(encodeUrl(fullPath)));
}

function loadExternalFile(fullPath) {
    var proxyUrl = store.getters['SiteSettings/getSettings'].mediaProxy;

    return `${proxyUrl}?url=${fullPath}`;
}

function loadNsfwImage() {
    return url(SHAKOU_FILE('5709853e-198e-4532-8949-bc363fb94669', '67134559_p0.png'));
}

export { getFile, loadTempFile, loadFile, loadMusic, loadBackground, loadTheme, loadProfilePicture, loadProfilePictureWithName, loadFullFile, loadFullTempFile, loadNsfwImage, loadExternalFile }