<template>
    <div class="profile-upload" 
        @mouseenter="showProfilePictureUpload = true" 
        @mouseleave="showProfilePictureUpload = false" 
        @click="$refs['profilePictureUpload'].click()"
    >
        <sh-profile-picture
            class="profile-picture"
            width="6"
            :src="user.url"
            :filename="user.profilePicture"
        />
        <div class="profile-upload-overlay" v-if="showProfilePictureUpload">
            <span class="icon icon-upload3"></span>
        </div>
        <input 
            ref="profilePictureUpload"
            class="hidden"
            type="file"
            accept="image/x-ping, image/gif, image/jpeg, image/jpg"
            @change="uploadProfilePicture"
        >
    </div>
</template>
<script>
import ShProfilePicture from "@/components/common/sh-profile-picture.vue"
import { mapActions } from 'vuex'
import { uploadProfilePicture } from '@/services/api/user-customisation-api.service.js'
    export default {
        name: "profile-picture",
        components: {
            "sh-profile-picture": ShProfilePicture
        },
        props: {
            user: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                showProfilePictureUpload: false
            }
        },
        methods: {
            ...mapActions({
                setUser: 'User/setUser'
            }),
            async uploadProfilePicture(event) {
                var file = event.target.files[0];
                var user = await uploadProfilePicture(file);
                this.setUser(user);
            }
        }
    }
</script>
<style lang="scss" scoped>
.profile-upload {
    --borderRadius: .25rem;
    position: relative;
    height: 6rem;
    width: 6rem;
    .profile-picture {
        border-radius: var(--borderRadius);
    }
    .profile-upload-overlay {
        position:absolute;
        border-radius: var(--borderRadius);
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--panelTransparent);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon {
            font-size:x-large;
        }
    }
}
</style>