<template>
    <div class="alert-modal" >
        <sh-alert
            v-for="(alert, index) in getAlerts"
            :key="alert.label"
            :alert="alert"
            @remove="removeAlert(index)"
        />
    </div>
</template>
<script>
import ShAlert from "@/components/common/sh-alert.vue"
import { mapGetters, mapActions } from 'vuex'

    export default {
        name: "alert-modal",
        components: {
            "sh-alert": ShAlert,
        },
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters({
                getAlerts: 'Alert/getAlerts'
            })
        },
        methods: {
            ...mapActions({
                removeAlert: "Alert/removeAlert"
            })
        },
    }
</script>
<style lang="scss">
.alert-modal {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
</style>