import { createRouter, createWebHistory } from "vue-router";
import { validateToken } from "@/services/api/user-api.service";

import TimelineView from "@/views/TimelineView.vue";
import ThreadView from "@/views/ThreadView.vue";
import UserView from "@/views/UserView.vue";
import SearchView from "@/views/SearchView.vue";
import LoginView from "@/views/LoginView.vue";
import ConfirmationView from "@/views/ConfirmationView.vue";
import BookmarkView from "@/views/BookmarkView.vue";
import NotificationView from "@/views/NotificationView.vue";
import MessageView from "@/views/MessageView.vue";
import ChatView from "@/views/ChatView.vue";
import EmojiView from "@/views/EmojiView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: TimelineView
    },
    {
        path: "/public",
        name: "public",
        component: TimelineView,
        props: {
            type: "public"
        }
    },
    {
        path: "/federated",
        name: "federated",
        component: TimelineView,
        props: {
            type: "federated"
        }
    },
    {
        path: "/home",
        name: "home-tl",
        component: TimelineView,
        props: {
            type: "home"
        },
        meta: {
            isUser: true
        }
    },
    {
        path: "/chat",
        name: "chat",
        component: ChatView,
        meta: {
            isUser: true
        }
    },
    {
        path: "/chat/:id",
        name: "chat-message",
        component: MessageView,
        meta: {
            isUser: true
        }
    },
    {
        path: "/login",
        name: "login",
        component: LoginView
    },
    {
        path: "/register",
        name: "register",
        component: LoginView,
        props: { 
            register: true
        }
    },
    {
        path: "/user/:id",
        name: "user",
        component: UserView
    },
    {
        path: "/user/:id/posts",
        name: "user-posts",
        component: UserView,
        props: {
            currentTab: "posts"
        }
    },
    {
        path: "/user/:id/media",
        name: "user-media",
        component: UserView,
        props: {
            currentTab: "media"
        }
    },
    {
        path: "/user/:id/likes",
        name: "user-likes",
        component: UserView,
        props: {
            currentTab: "likes"
        }
    },
    {
        path: "/user/:id/followers",
        name: "user-followers",
        component: UserView,
        props: {
            currentTab: "followers"
        }
    },
    {
        path: "/user/:id/following",
        name: "user-following",
        component: UserView,
        props: {
            currentTab: "following"
        }
    },
    {
        path: "/confirm/:id",
        name: "confirmation",
        component: ConfirmationView
    },
    {
        path: "/post/:id",
        name: "thread",
        component: ThreadView
    },
    {
        path: "/bookmark",
        name: "bookmark",
        component: BookmarkView,
        meta: {
            isUser: true
        }
    },
    {
        path: "/notifications",
        name: "notification",
        component: NotificationView,
        meta: {
            isUser: true
        }
    },
    {
        path: "/search",
        name: "search",
        component: SearchView
    },
    {
        path: "/emojis",
        name: "emojis",
        component: EmojiView
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const authRequired = to.meta.isAdmin || to.meta.isUser;
    const loggedIn = await validateToken();

    if (authRequired && !loggedIn) {
        next('/login');
    }
    
    next();
})

export default router;
