<template>
    <div class="muted-notification">

    </div>
</template>
<script>
    export default {
        name: "muted-notification",
        props: {
            notification: {
                type: Object,
                required: true
            }
        }
    }
</script>
<style lang="scss">
.muted-notification { 

}
</style>