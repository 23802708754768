import { setHeaders, url } from "./api.service"

const SHAKOU_SEARCH = (query,page,size) => `/api/search?query=${query}&page=${page}&size=${size}`
const SHAKOU_USER_SEARCH = (query) => `/api/search/user?query=${query}&size=5`

async function Search(query, creator = null, mentioned = null, beforeDate = null, afterDate = null, page = 0, size = 20) {
    var headers = setHeaders();

    var baseUrl = SHAKOU_SEARCH(query, page, size);

    if (creator) {
        baseUrl += `&creator=${creator}`;
    }
    
    if (mentioned) {
        baseUrl += `&mentioned=${mentioned}`;
    }

    if (beforeDate) {
        baseUrl += `&beforeDate=${beforeDate}`;
    }

    if (afterDate) {
        baseUrl += `&afterDate=${afterDate}`;
    }

    baseUrl = encodeURI(baseUrl);

    var response = await fetch(url(baseUrl), headers);

    return await response.json();
}

async function SearchUser(query) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_USER_SEARCH(query)), headers);

    return await response.json();
}

export {
    Search,
    SearchUser
}