<template>
    <div class="registration-panel">
        <div class="body">
            <sh-single-line-text-input :placeholder="$t('common.username')"  v-model="registrationData.username" />
            <sh-single-line-text-input :placeholder="$t('common.handle')" v-model="registrationData.handle" />
            <sh-single-line-text-input :placeholder="$t('common.email')" v-model="registrationData.email" />
            <sh-single-line-text-input :placeholder="$t('common.confirm-email')" v-model="registrationData.confirmEmail" />
            <sh-single-line-text-input :placeholder="$t('common.password')" v-model="registrationData.password" password />
            <sh-single-line-text-input :placeholder="$t('common.confirm-password')" v-model="registrationData.confirmPassword" password />
        </div>
        <div class="footer">
            <sh-checkbox v-model="readTos">{{ $t('component.register.accept-tos')}}</sh-checkbox>
            <sh-checkbox v-model="acceptPrivacy">{{ $t('component.register.accept-privacy')}}</sh-checkbox>
            <sh-filled-button @click="submit" padding=".2rem 2" borderRadius=".25">{{ $t('common.register')}}</sh-filled-button>
            <sh-filled-button @click="$emit('changePanel')" padding=".2rem .4" borderRadius=".25">{{ $t('common.login')}}</sh-filled-button>
        </div>
    </div>
</template>

<script>
import ShSingleLineTextInput from "@/components/common/sh-single-line-text-input.vue";
import ShFilledButton from "@/components/common/sh-filled-button.vue";
import ShCheckbox from "@/components/common/sh-checkbox.vue";

import { registerUser } from "@/services/api/user-api.service";
import { mapActions } from "vuex";
    export default {
        name: "registration-panel",
        components: {
            "sh-single-line-text-input": ShSingleLineTextInput,
            "sh-filled-button": ShFilledButton,
            "sh-checkbox": ShCheckbox
        },
        data() {
            return {
                readTos: false,
                acceptPrivacy: false,
                registrationData: {
                    handle: null,
                    username: null,
                    email: null,
                    confirmEmail: null,
                    confirmPassword: null,
                    password: null
                }
            }
        },
        computed: {
            invalidHandle() {
                var invalid = false;
                var handle = this.registrationData.handle;
                var regex = /[!@#$%^&*()]/g;
                invalid = regex.test(handle);
                
                if (handle.includes(" ")) {
                    invalid = true;
                }

                return invalid;
            },
        },
        methods: {
            ...mapActions({
                addAlert: "Alert/addAlert"
            }),
            validateInput() {
                var valid = true;
                if (!this.registrationData.handle) {
                    this.addAlert({
                        label: this.$t('error.missing-handle'),
                        expireTime: 1000,
                        type: 'error'
                    })
                    valid = false;
                }
                if (this.invalidHandle) {
                    this.addAlert({
                        label: this.$t('error.invalid-handle'),
                        expireTime: 1000,
                        type: 'error'
                    })
                    valid = false;
                }
                if (!this.registrationData.username) {
                    this.addAlert({
                        label: this.$t('error.missing-username'),
                        expireTime: 1000,
                        type: 'error'
                    })
                    valid = false;
                }
                if (!this.registrationData.email) {
                    this.addAlert({
                        label: this.$t('error.missing-email'),
                        expireTime: 1000,
                        type: 'error'
                    })
                    valid = false;
                }
                if (!this.registrationData.password) {
                    this.addAlert({
                        label: this.$t('error.missing-password'),
                        expireTime: 1000,
                        type: 'error'
                    })
                    valid = false;
                }
                if (!this.registrationData.confirmPassword) {
                    this.addAlert({
                        label: this.$t('error.missing-confirm-password'),
                        expireTime: 1000,
                        type: 'error'
                    })
                    valid = false;
                }
                if (!this.registrationData.confirmEmail) {
                    this.addAlert({
                        label: this.$t('error.missing-confirm-email'),
                        expireTime: 1000,
                        type: 'error'
                    })
                    valid = false;
                }
                if (this.registrationData.confirmPassword != this.registrationData.password) {
                    this.addAlert({
                        label: this.$t('error.password-mismatch'),
                        expireTime: 1000,
                        type: 'error'
                    })
                    valid = false;
                }
                if (this.registrationData.confirmEmail != this.registrationData.email) {
                    this.addAlert({
                        label: this.$t('error.email-mismatch'),
                        expireTime: 1000,
                        type: 'error'
                    })
                    valid = false;
                }
                if (!this.readTos) {
                    this.addAlert({
                        label: this.$t('error.missing-tos'),
                        expireTime: 1000,
                        type: 'error'
                    })
                    valid = false;
                }
                if (!this.acceptPrivacy) {
                    this.addAlert({
                        label: this.$t('error.missing-privacy'),
                        expireTime: 1000,
                        type: 'error'
                    })
                    valid = false;
                }

                return valid;
            },
            submit() {
                if (!this.validateInput()) {
                    return;
                }

                registerUser(this.registrationData)
                .then((state) => {
                    if (state) {
                        this.addAlert({
                            label: this.$t('component.register.success'),
                            expireTime: 5000,
                            type: 'success'
                        })
                        this.$router.push({ name: 'home'})
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
.registration-panel {
    display: inline-grid;
    margin-right: auto;
    margin-left: auto;
    justify-items: center;
    row-gap: 1rem;
    width: fit-content;
    background-color: var(--background);
    padding: 3rem;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 1px 1px var(--shadow);
    .body, .footer {
        display: grid;
        justify-items: center;
        row-gap:.5rem;
    }
}
</style>