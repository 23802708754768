<template>
    <div class="sh-background-thumbnail" :style="thumbnailStyle"  @click="handleClick">
        <div class="floating-buttons">
            <sh-icon-button
                class="transparent-button"
                small
                ref="deleteButton"
                icon="icon-cross"
                @click="$emit('delete')"
            />
        </div>
        <img 
            :src="getFile"
        >
    </div>
</template>

<script>
import ShIconButton from './sh-icon-button.vue';

import { loadBackground } from '@/services/api/file-api.service';
    export default {
        name: "sh-image-thumbnail",
        components: {
            'sh-icon-button' : ShIconButton,
        },
        props: {
            url: {
                type: String,
                required: true
            },
            file: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                thumbnailStyle: null,
            }
        },
        computed: {
            getFile() {
                return loadBackground(this.url, this.file);
            },
            getHeight() {
                return this.$el.parentNode.offsetHeight;
            },
            getStyle() {
                return `height: ${this.getHeight}px; width: ${this.width}%; max-height: ${this.getHeight}px;`
            }
        },
        methods: {
            handleClick(event) {
                if (this.$refs['deleteButton'].$el.contains(event.srcElement)) {
                    return;
                }
                this.$emit('setBackground');
            },
        },
        mounted() {
            this.thumbnailStyle = this.getStyle;
        },
    }
</script>

<style lang="scss">
.sh-background-thumbnail {
    overflow: hidden;
    border-style: solid;
    border-width: thin;
    border-color: var(--thumbnailBorder);
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
    &.modal {
        background-color: none;
        border: none;
    }
    .floating-buttons {
        position: absolute;
        top: 2%;
        right: 2%;
        display: flex;
        column-gap: 0.2rem;
        z-index: 405;
        .transparent-button {
            background-color: var(--panelTransparent) !important;
        }
    }
    .transparent-panel {
        background-color: var(--panelTransparent) !important;
    }
    .editable-values {
        position: absolute;
        column-gap: 1rem;
        bottom: 0;
        width: 90%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        z-index: 400;
    }
    video {
        height: inherit;
        max-width: 100%;
        max-height: 100%;
    }
    img {
        max-height: 200%;
        max-width: 100%;
    }
    img, video, audio {
        z-index: 300;
    }
}
</style>