<template>
    <div class="interacted-post">
        <div class="interaction-message">
            <span v-html="getMessage"></span>
        </div>
        <default-post
                    :post="getPost"
                    @openThread="openThread"
                    @updatePost="(e) => $emit('updatePost',e)"
                    @toggleMute="$emit('toggleMute')"
                    @toggleBookmark="$emit('toggleBookmark')"
        />
    </div>
</template>
<script>
import DefaultPost from "@/components/default-post/default-post.vue"
    export default {
        name: "interacted-post",
        components: {
            "default-post": DefaultPost,
        },
        props: {
            post: {
                type: Object,
                required: true
            }
        },
        computed: { 
            formatUser() {
                return `<a href="/user/${this.post.interactor.url}">@${this.post.interactor.handle}</a>`
            },
            getMessage() {
                return this.$t('component.interacted-post.repost', [this.formatUser])
            },
            getPost() {
                var post = this.post;

                post.CreatedDate = post.OriginalCreatedDate;

                return post;
            }
        }
    }
</script>
<style lang="scss">
.interaction-message {
    text-align: left;
    margin-bottom: 0.5rem;
}
</style>