import i18n from "@/i18n"
import { userPermissions } from "@/services/permission-handler.service";

const timelines = [
    { label: i18n.global.t('timeline.public'), value: "public" },
    { label: i18n.global.t('timeline.federated'), value: "federated" },
    { label: i18n.global.t('timeline.home'), value: "home", permission: userPermissions.isAuthenticated },
    { label: i18n.global.t('timeline.bookmarks'), value: "bookmarks", permission: userPermissions.isAuthenticated },
]

export default timelines;