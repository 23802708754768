<template>
    <div class="search-view">
       <search-panel
            @search="updateQuery"
       />
       <div class="result-holder">
            <div class="not-found"
                v-if="isLoaded && notFound"
            >
                {{  $t('page.search.not-found') }}
            </div>
            <timeline-panel
                v-else-if="isLoaded"
                isSearch
                @fetchMore="loadMore"
                name="searchResults"
            >
            </timeline-panel>
       </div>
    </div>
</template>

<script>
import SearchPanel from "@/components/search-panel/search-panel.vue"
import TimelinePanel from "@/components/timeline-panel/timeline-panel.vue"
import { Search } from "@/services/api/search-api.service"
import { mapActions, mapGetters } from "vuex"
    export default {
        name: "SearchView",
        components: {
            "search-panel": SearchPanel,
            "timeline-panel": TimelinePanel
        },
        data() {
            return {
                notFound: false,
                isLoaded: false,
                query: null,
                oldQuery: null,
                creator: null,
                mentioned: null,
                beforeDate: null,
                afterDate: null,
                page: 0,
            }
        },
        computed: {
            ...mapGetters({
                getMorePosts: 'Timeline/getMorePosts',
            })
        },
        methods: {
            ...mapActions({
                clearTimeline: 'Timeline/clearTimeline',
                addPosts: 'Timeline/addPosts'
            }),
            updateQuery(data) {
                this.query = data.query
                this.creator = data.creator
                this.mentioned = data.mentioned
                this.beforeDate = data.beforeDate
                this.afterDate = data.afterDate
                if (data.clearTimeline) {
                    this.clearTimeline("searchResults");
                }
                this.search();
            },
            setLocalData() {
                this.oldQuery = this.$route.query.q;
                this.query = this.$route.query.q;
                this.creator = this.$route.query.creator;
                this.mentioned = this.$route.query.mentioned;
                this.beforeDate = this.$route.query.beforeDate;
                this.afterDate = this.$route.query.afterDate;
            },
            async search() {
                if (this.query == null) { return; }
                if (this.oldQuery != this.query) {
                    this.clearTimeline("searchResults");
                    this.isLoaded = false;
                }

                var foundPosts = await Search(this.query, this.creator, this.mentioned, this.beforeDate, this.afterDate, this.page);
                if (foundPosts.length <= 0 && !this.isLoaded) {
                        this.notFound = true;
                } else if (foundPosts.length > 0) {
                    this.notFound = false;
                }

                this.addPosts({
                    timelineName: "searchResults",
                    posts: foundPosts,
                    page: this.page
                });
                this.isLoaded = true;
            },
            loadMore() {
                if (!this.getMorePosts("searchResults")) {
                    return;
                }
                this.page++;
                this.search();
            }
        },
        created() {
            this.clearTimeline("searchResults");
            this.setLocalData()
            this.search();
        }
    }
</script>

<style lang="scss" scoped>
.result-holder {
    margin-top: 1rem;
    .not-found {
        background-color: var(--background);
        border-radius: 0.25rem;
        padding: 0.5rem;
        box-shadow: 0px 2px 1px 1px var(--shadow);
    }
}

</style>