<template>
    <div class="info-settings">
        info
    </div>
</template>
<script>
    export default {
        name: "info-settings",
        components: {
        },
        props: {
        }
    }
</script>
<style lang="scss">

</style>