<template>
    <div class="embed-card">
        <div 
            v-if="isDisabled"
            class="embed-body" 
            @click="openUrl"
        >
            <img 
                class="embed-favicon"
                :src="metadata.favicon"
            >
            <div class="embed-details">
                <div class="embed-title mini"> {{ metadata.site }}</div>
                <div class="embed-title"> {{ metadata.title }}</div>
            </div>
        </div>
        <div class="embed-body" v-else>
            <img 
                v-if="metadata.image"
                class="embed-image"
                :src="metadata.image"
                @click="openUrl"
            >
            <div class="embed-details">
                <div class="embed-title" @click="openUrl"> {{ metadata.title }}</div>
                <div class="embed-description"> {{ metadata.description }} </div>
            </div>
        </div>
        <div 
            class="embed-toggle"
            :class="getToggle"   
            @click="isDisabled = !isDisabled" 
        ></div>
    </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from 'vuex'

export default {
    name: "embed-card",
    props: {
        metadata: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isDisabled: false
        }
    },
    computed: {
        ...mapGetters({
            getSettings: 'Settings/getSettings'
        }),
        getToggle() {
            if(this.isDisabled)
                return 'icon-keyboard_arrow_down'
            else
                return 'icon-keyboard_arrow_up'
        },
        getImage() {
            const url = new URL(this.metadata.image)
            return url
        },
        getFavicon() {
            const url = new URL(this.metadata.favicon)
            return url
        }
    },
    created() {
        this.isDisabled = this.getSettings.disableLinkEmbeds
    }
}
</script>
<style scoped lang="scss">
.embed-card {
    position: relative;
    margin-top: .5rem;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 1px 1px var(--shadow);
    .embed-body {
        display: flex;
        .embed-image {
            max-width: 10rem;
            max-height: 10rem;
            border-top-left-radius: .25rem;
            border-bottom-left-radius: .25rem;
            margin-right: 1rem;
        }
        .embed-detail {
            .embed-title {
                margin-bottom: .2rem;
                color: var(--link);
                .mini {
                    font-size: small;
                }
            }
            .embed-title:hover, .embed-image:hover, .embed-favicon:hover {
                cursor: pointer;
            }
        }
    }
    .embed-toggle {
        position: absolute;
        font-size: x-large;
        top: 0;
        right: 0; 
    }
    .embed-toggle:hover {
        cursor: pointer;
    }
    
    .embed-favicon {
        max-width: 3rem;
        max-height: 2rem;
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
        margin-right: 1rem;
    }
}
</style>