import { simpleThemeRender } from '@/services/theme-handler.service'
import DefaultTheme from '@/themes/defaultTheme'
const defaultState = {
    defaultTheme: DefaultTheme,
    theme: null,
    oldTheme: {
        version: 1,
        name: "New Theme",
        isPublic: true,
    },
    background: null,
    styleObj: null,
    isChanged: false,
}

const _getters = {
    getDefaultTheme(state) { return state.defaultTheme},
    getTheme(state)        { return state.theme },
    getOldTheme(state)  { return state.oldTheme },
    getStyleObj(state)     { return state.styleObj },
    getBackground(state)   { return state.background },
    getIsChanged(state)    { return state.isChanged }
}
const _mutations = {
    setStyleObj(state, _obj)     { state.styleObj = _obj },
    setDefaultTheme(state, _theme) { state.defaultTheme = _theme},
    setTheme(state, _theme) {
        state.theme = structuredClone(_theme)
    },
    setOldTheme(state,_theme) {
        state.oldTheme = structuredClone(_theme)
    },
    setBackground(state, _bg)      { state.background = _bg },
    setIsChanged(state, _b)        { state.isChanged = _b },
}
const _actions = {
    saveThemeService({commit, dispatch},obj) {
        commit('setTheme',obj)
        dispatch('saveTheme')
    },
    saveTheme({ getters }) {
        localStorage.setItem("theme", JSON.stringify(getters.getTheme))
    },
    default(context) {
        context.commit('setTheme',context.getters.getDefaultTheme)
        localStorage.removeItem("theme")
        simpleThemeRender();
    },
    newWorkInProgress(context) {
        if(context.getters.getTheme != null)
            context.commit('setOldTheme',context.getters.getTheme)
        else 
            context.commit('setOldTheme',context.getters.getDefaultTheme)
    },
    restart(context) {
        context.commit('setTheme',context.getters.getOldTheme)
    }
}
const instance = {
    namespaced: true,
    state: defaultState,
    getters: _getters,
    mutations: _mutations,
    actions: _actions
}

export default instance;