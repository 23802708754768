<template>
    <div class="general-settings">
        <sh-dropdown-list
            :defaultValue="getUserLanguage"
            :listItems="getLanguages"
            @clicked="setLanguage"
        />
        <sh-checkbox
            id="displayImageHeightInThumbnail" 
            v-model="displayImageHeightInThumbnail"
            @click="setDisplayImageHeightInThumbnail(!displayImageHeightInThumbnail)"
        >{{ $t('component.settings.general-panel.display-by-image-width')}}</sh-checkbox>
        <sh-checkbox
            id="enableProfilePictureMentions" 
            v-model="enableProfilePictureMentions"
            @click="setEnableProfilePictureMentions(!enableProfilePictureMentions)"
        >{{ $t('component.settings.general-panel.enable-profile-picture-mention')}}</sh-checkbox>
        <sh-checkbox
            id="automaticallyGetOlderPosts" 
            v-model="automaticallyGetOlderPosts"
            @click="setAutomaticallyGetOlderPosts(!automaticallyGetOlderPosts)"
        >{{ $t('component.settings.general-panel.automatically-get-older-posts')}}</sh-checkbox>
        <sh-checkbox
            id="hidePostEmbedLinks" 
            v-model="hidePostEmbedLinks"
            @click="setHidePostEmbedLinks(!hidePostEmbedLinks)"
        >{{ $t('component.settings.general-panel.hide-post-embed-link')}}</sh-checkbox>
        <sh-checkbox
            id="disableLinkEmbeds" 
            v-model="disableLinkEmbeds"
            @click="setDisableLinkEmbeds(!disableLinkEmbeds)"
        >{{ $t('component.settings.general-panel.disable-link-embeds')}}</sh-checkbox>
        <sh-checkbox
            id="disableVideoEmbeds" 
            v-model="disableVideoEmbeds"
            @click="setDisableVideoEmbeds(!disableVideoEmbeds)"
        >{{ $t('component.settings.general-panel.disable-video-embeds')}}</sh-checkbox>
    </div>
</template>
<script>
import ShCheckbox from "@/components/common/sh-checkbox.vue"
import ShDropdownList from "@/components/common/sh-dropdown-list.vue"
import languages from "@/entities/Languages"
import { mapGetters, mapActions } from "vuex"
    export default {
        name: "general-settings",
        components: {
            "sh-checkbox": ShCheckbox,
            "sh-dropdown-list": ShDropdownList
        },
        computed: {
            ...mapGetters({
                getUserLanguage: 'Settings/getLanguage',
                displayImageHeightInThumbnail: "Settings/getDisplayImageHeightInThumbnail",
                enableProfilePictureMentions: "Settings/getEnableProfilePictureMentions",
                automaticallyGetOlderPosts: "Settings/getAutomaticallyGetOlderPosts",
                hidePostEmbedLinks: "Settings/getHidePostEmbedLinks",
                disableVideoEmbeds: "Settings/getDisableVideoEmbeds",
                disableLinkEmbeds: "Settings/getDisableLinkEmbeds",
            }),
            getLanguages() {
                return languages;
            }
        },
        methods: {
            ...mapActions({
                setLanguage: "Settings/setLanguage",
                setDisplayImageHeightInThumbnail: "Settings/setDisplayImageHeightInThumbnail",
                setEnableProfilePictureMentions: "Settings/setEnableProfilePictureMentions",
                setAutomaticallyGetOlderPosts: "Settings/setAutomaticallyGetOlderPosts",
                setHidePostEmbedLinks: "Settings/setHidePostEmbedLinks",
                setDisableLinkEmbeds: "Settings/setDisableLinkEmbeds",
                setDisableVideoEmbeds: "Settings/setDisableVideoEmbeds",
            }),
        },
        data() {
            return {
                test: true
            }
        }
    }
</script>
<style lang="scss">

</style>