<template>
    <router-link
        :to="{
            name: 'chat-message', 
            params: {
                id: chat.id
            }
        }"
        class="chat-item"
    >
        <div v-if="chat.icon">
        </div>
        <sh-profile-picture
            v-else
            :src="getLastUser.url"
            :filename="getLastUser.profilePicture"
            :title="getLastUser.handle"
            rounded
            width=2
        />
        <div class="chat-body">
            <div class="chat-title">{{  getChatName }}</div>
            <div 
                v-if="chat.lastMessage"
                class="last-message"
            >
                {{ getLastMessage }}
            </div>
        </div>
        <span v-if="chat.lastMessage" class="timestamp" ref="timestamp" :datetime="getDate"></span>
    </router-link>
</template>

<script>
import { render, cancel } from "timeago.js"
import ShProfilePicture from "@/components/common/sh-profile-picture.vue"
    export default {
        name: "chat-item",
        components: {
            "sh-profile-picture" : ShProfilePicture
        },
        props: {
            chat: {
                type: Object,
                required: true
            }
        },
        computed: {
            getDate() {
                return new Date(`${this.chat.lastMessage.createdDate}Z`)
            },
            getChatName() {
                if (this.chat.name) {
                    return this.chat.name
                } else {
                    return this.getLastUser.handle;
                }
            },
            getLastUser() {
                if (this.chat.lastMessage) {
                    return this.chat.lastMessage.poster;
                } else {
                    return this.chat.owner;
                }
            },
            getLastMessage() {
                return `@${this.getLastUser.handle} - ${this.chat.lastMessage.body}`
            }
        },
        methods: {
            startRender() {
                if (this.chat.lastMessage) {
                    render(this.$refs['timestamp'], this.getLanguage)
                }
            },
            cancelRender() {
                if (this.chat.lastMessage) {
                    cancel(this.$refs['timestamp'].$el)
                }
            }
        },
        mounted() {
            if (this.chat.lastMessage) {
                this.startRender();
            }
        },
        beforeUnmount() {
            this.cancelRender();
        },
    }
</script>
<style scoped lang="scss">
.chat-item {
    display: flex;
    column-gap: 0.5rem;
    margin: 0.5rem;
    padding: 0.5rem;
    border-style: solid;
    border-width: thin;
    border-radius: 0.25rem;
    .chat-body {
        display: block;
    }
    .chat-title {
        display: flex;
    }
    .last-message {
        font-size: x-small;
    }
    .timestamp {
        margin-left: auto;
    }
    &:hover {
        background-color: var(--panel);
        cursor: pointer;
    }
}
</style>