<template>
    <div class="notification-panel">
        <div class="header">
            <span> {{ $t('component.notification.header')}}</span>
            <div class="unread-count" v-if="getUnreadNotifications"> {{ getUnreadNotificationCount }}</div>
            <sh-filled-button
                v-if="getUnreadNotifications"
                class="read-button"
                @click="markNotificationsRead"
            >
                {{ $t('component.notification.mark-read')}}
            </sh-filled-button>
        </div>
        <div class="notification-holder">
            <notification-wrapper
                v-for="notification in getNotifications"
                :key="notification.id"
                :notification="notification"
            />
        </div>
        <sh-filled-button
            class="load-button"
            @click="loadMoreNotifications"
        >
            {{ getLoadMoreLabel }}
        </sh-filled-button>
    </div>
</template>
<script>
import ShFilledButton from "@/components/common/sh-filled-button.vue"
import NotificationWrapper from "@/components/notification-wrapper/notification-wrapper.vue"
import { mapGetters, mapActions } from 'vuex';
import { getNotifications } from "@/services/api/notification-api.service";
    export default {
        name: "notification-panel",
        components: {
            "notification-wrapper": NotificationWrapper,
            "sh-filled-button": ShFilledButton,
        },
        data() {
            return {
                page: 1,
                canLoadMore: true
            }
        },
        computed: {
            ...mapGetters({
                getNotifications: 'Notifications/getNotifications',
                getUnreadNotifications: 'Notifications/getUnreadNotifications',
                getUnreadNotificationCount: 'Notifications/getUnreadNotificationCount',
            }),
            getLoadMoreLabel() {
                if (this.canLoadMore) {
                    return this.$t('component.notification.load-more-notifications')
                } else {
                    return this.$t('component.notification.no-more-notifications')
                }
            }
        },
        methods: {
            ...mapActions({
                markNotificationsRead: 'Notifications/markNotificationsRead',
                addNotifications: 'Notifications/addNotifications'
            }),
            async loadMoreNotifications() {
                if (this.canLoadMore) {
                    var results = await getNotifications(this.page);
                    if (results.length <= 0) { this.canLoadMore = false; }
                    this.page++;
                    this.addNotifications({
                        notifications: results,
                        newNotifications: false,
                        initialFetch: false
                    });
                }

            }
        }
    }
</script>
<style  lang="scss" scoped>
.notification-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-top: 1rem;
    .notification-holder {
        overflow-y: auto;
    }
    .header {
        align-items: center;
        display: flex;
        padding: 0.5rem;
        border-color: var(--inputOutline);
        border-style: solid;
        border-width: thin;
        border-radius: 0.25rem;
        .unread-count {    
            margin-left: 1rem;
            background-color: var(--unseenNotification);
            padding: 0.5rem;
            height: 0.5rem;
            display: flex;
            border-radius: 5rem;
            justify-content: center;
            align-items: center;
        }
        .read-button {
            margin-left: auto;
        }
    }
}
</style>