import { getSelf } from "@/services/api/user-api.service";
import { loadTheme } from "@/startup";

const defaultState = {
    id: null,
    url: null,
    user: null,
}

const _getters = {
    getId(state) { return state.id; },
    getUrl(state) { return state.url; },
    getUser(state)  { return state.user; },
    getBackgrounds(state) { return state.user?.customisation?.backgrounds; },
    getMusic(state) { return state.user?.customisation?.music; },
    getThemes(state) { return state.user?.customisation?.themes; }
}

const _mutations = {
    setId(state, _id) { state.id = _id; },
    setUrl(state, _url) { state.url = _url; },
    setUserCustomisation(state ,_custom) { state.user.customisation = _custom; }
}

const _actions = {
    async fetchUser({ dispatch }) {
        dispatch("setUser",  await getSelf());
    },
    setUser({ state }, userDto) {
        if (userDto == null) { return; }
        state.id = userDto.id;
        state.url = userDto.url;
        state.user = userDto;

        loadTheme()
    },
    clearUser({ state }) {
        state.id = null;
        state.url = null;
    }
}

const user = {
    namespaced: true,
    state: defaultState,
    getters: _getters,
    mutations: _mutations,
    actions: _actions
}

export default user;