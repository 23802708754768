<template>
    <div class="spoilered-post">
        <span 
            v-if="spoilerReason"
        > {{  $t('component.spoilered-post.spoiler-reason', [spoilerReason]) }}</span>
        <span 
            v-else
        > {{  $t('component.spoilered-post.spoilered') }}</span>
        <sh-icon-button
            small
            noBackground
            class="toggle-spoiler"
            icon="icon-eye" 
            @click="$emit('showSpoileredPost')" 
        />
    </div>
</template>

<script>
import ShIconButton from "@/components/common/sh-icon-button.vue";
    export default {
        name: "spoilered-post",
        props: {
            spoilerReason: {
                type: String,
                required: false,
                default: null
            }
        },
        components: {
            "sh-icon-button": ShIconButton
        }
    }
</script>

<style lang="scss">
    .spoilered-post {
        align-items: center;
        display: flex;
        padding: 0.5rem;
        border-color: var(--mutedPostBorder);
        background-color: var(--mutedPostBackground);
        border-style: solid;
        border-width: thin;
        border-radius: 0.25rem;

        .toggle-spoiler {
            margin-left: auto;
        }
    }
</style>