import { url, setHeaders, handleError } from './api.service'
import checkForFilteredWords from '@/services/text/filtered-words.service'

const SHAKOU_PUBLIC_TIMELINE = (pg, size) => `/api/timeline/public?pg=${pg}&size=${size}`
const SHAKOU_FEDERATED_TIMELINE = (pg, size) => `/api/timeline/federated?pg=${pg}&size=${size}`
const SHAKOU_HOME_TIMELINE = (pg, size) => `/api/timeline/home?pg=${pg}&size=${size}`
const SHAKOU_BOOKMARKS_TIMELINE = (pg, size) => `/api/timeline/bookmarks?pg=${pg}&size=${size}`
const SHAKOU_USER_TIMELINE = (user, hasMedia = false, pg = 0, size = 20) => `/api/timeline/user/${user}?pg=${pg}&size=${size}&hasMedia=${hasMedia}`
const SHAKOU_USER_LIKES_TIMELINE = (user, hasMedia = false, pg = 0, size = 20) => `/api/timeline/user/${user}/likes?pg=${pg}&size=${size}&hasMedia=${hasMedia}`

const SHAKOU_PUBLIC_TIMELINE_SINCE = (id) => `/api/timeline/public?since=${id}`
const SHAKOU_FEDERATED_TIMELINE_SINCE = (id) => `/api/timeline/federated?since=${id}`
const SHAKOU_HOME_TIMELINE_SINCE = (id) => `/api/timeline/home?since=${id}`

async function getPublicTimeline(pg = 0, size = 20) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_PUBLIC_TIMELINE(pg,size)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return checkForFilteredWords(await response.json());
}

async function getFederatedTimeline(pg = 0, size = 20) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_FEDERATED_TIMELINE(pg,size)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return checkForFilteredWords(await response.json());
}

async function getHomeTimeline(pg = 0, size = 20) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_HOME_TIMELINE(pg,size)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return checkForFilteredWords(await response.json());
}

async function getBookmarks(pg = 0, size = 20) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_BOOKMARKS_TIMELINE(pg,size)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return await response.json();
}

async function getUserTimeline(userUrl, hasMedia = false, pg = 0, size = 20) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_USER_TIMELINE(userUrl, hasMedia, pg, size)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return checkForFilteredWords(await response.json());
}

async function getUserLikesTimeline(userUrl, hasMedia = false, pg = 0, size = 20) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_USER_LIKES_TIMELINE(userUrl, hasMedia, pg, size)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return checkForFilteredWords(await response.json());
}


async function getPublicTimelineSince(id) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_PUBLIC_TIMELINE_SINCE(id)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return checkForFilteredWords(await response.json());
}

async function getFederatedTimelineSince(id) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_FEDERATED_TIMELINE_SINCE(id)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return checkForFilteredWords(await response.json());
}

async function getHomeTimelineSince(id) {
    var headers = setHeaders();

    var response = await fetch(url(SHAKOU_HOME_TIMELINE_SINCE(id)), headers);

    if (response.status != 200) {
        handleError("Fuck");
        return;
    }

    return checkForFilteredWords(await response.json());
}

export {
    getPublicTimeline,
    getFederatedTimeline,
    getHomeTimeline,
    getBookmarks,
    getUserTimeline,
    getUserLikesTimeline,
    getFederatedTimelineSince,
    getHomeTimelineSince,
    getPublicTimelineSince
}