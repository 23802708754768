import { markNotificationsRead } from "@/services/api/notification-api.service";

const _state = {
    notifications: [],
    fetchNotificationInterval: null,
    loadMorePage: 1,
    lastFetchedId: 0
}

const _getters = {
    getNotifications(state) { return state.notifications; },
    getInterval(state) { return state.fetchNotificationInterval; },
    getLastFetchedId(state) { return state.lastFetchedId },
    getUnreadNotifications(state) { return state.notifications.some((n) => n.isSeen == false) },
    getUnreadNotificationCount(state) { 
        var t = state.notifications.filter((n) => n.isSeen == false);
        return t.length;
    }
}
const _actions = {
    addNotifications({ state, getters, dispatch }, notificationObject) {
        var notificationsToAdd = 0;
        var initialLength = state.notifications.length;

        state.notifications = [...new Set([...state.notifications, ...notificationObject.notifications])];

        state.notifications.sort((a,b) => {
            return new Date(b.createdDate) - new Date(a.createdDate)
        });

        state.lastFetchedId = state.notifications[0].id
        
        // User opened site
        if (notificationObject.initialFetch) {
            notificationsToAdd = getters.getUnreadNotificationCount;
        // Site automatically fetching notifications
        } else if (notificationObject.newNotifications) {
            notificationsToAdd = (state.notifications.length - initialLength);
        // User loading notifications
        } else {
            var unreadNotifications = notificationObject.notifications.filter((n) => n.isSeen == false)
            notificationsToAdd = unreadNotifications.length;
        }
        dispatch('Favicon/add', notificationsToAdd,{ root: true});
    },
    updateNotification({ state, dispatch }, updateObject) {
        state.notifications.forEach((notif) => {
            if (notif.post) {
                if (notif.post.url == updateObject.url) {
                    notif.post = updateObject

                    if (!updateObject.updatedInTimeline) {
                        updateObject.updatedInNotification = true
                        dispatch("Timeline/updatePostInAllTimelines",updateObject,{ root: true});
                    }
                }
            }
        })
    },
    markNotificationsRead({ state, getters, dispatch }) {
        dispatch('Favicon/remove', getters.getUnreadNotificationCount,{ root: true});
        markNotificationsRead();
        state.notifications.forEach((notif) => {
            notif.isSeen = true
        })
    },
    clearNotifications({ state, getters, dispatch }) {
        dispatch('Favicon/remove', getters.getUnreadNotificationCount,{ root: true});
        if (state.fetchNotificationInterval) {
            clearInterval(state.fetchNotificationInterval);
        }
        state.notifications = [];
        state.loadMorePage = 1;
    },
    setInterval({ state }, interval) {
        if (state.fetchNotificationInterval) {
            clearInterval(state.fetchNotificationInterval);
        }
        state.fetchNotificationInterval = interval;
    },
}

const notification = {
    namespaced: true,
    state: _state,
    getters: _getters,
    actions: _actions
}

export default notification;