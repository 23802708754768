<template>
    <div class="sh-emoji-result" :class="selectedItem == emoji.name ? 'selected' : ''">
        <sh-emoji
            :emoji="emoji"
            @click="$emit('clicked')"
        />
        <span> {{  emoji.name }}</span>
    </div>
</template>
<script>
import ShEmoji from './sh-emoji.vue';
    export default {
        name: "sh-emoji-result",
        components: {
            "sh-emoji": ShEmoji
        },
        props: {
            emoji: {
                type: Object,
                required: false
            },
            selectedItem: {
                type: String,
                required: false
            }
        }
    }
</script>

<style lang="scss" scoped>
.sh-emoji-result {
    width: 100%;
    display: flex;
    column-gap: 1rem;
    background-color: var(--panelHover);
    align-items: center;
    &:hover {
        background-color: var(--panelTransparent);
        cursor: pointer;
    }

    &.selected {
        background-color: var(--panelTransparent);
    }
}
</style>