import { userPermissions } from "@/services/permission-handler.service"

const visibilities = [
    { value: "Public", icon: "icon-sphere" },
    { value: "Unlisted", icon: "icon-unlocked" },
    { value: "Locked", icon: "icon-locked" },
    { value: "Local", icon: "icon-home", permission: userPermissions.canLocalPost },
]

export default visibilities
