<template>
    <div class="embed-wrapper">
        <iframe
            class="video-embed"
            v-if="!isDisabled"
            :src="getUrl"
        >
        </iframe>
        <div 
            class="embed-toggle"
            :class="getToggle"   
            @click="isDisabled = !isDisabled" 
        ></div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: "video-embed",
    props: {
        url: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isDisabled: false
        }
    },
    computed: {
        ...mapGetters({
            getSiteSettings: 'SiteSettings/getSettings'
        }),
        getUrl() {
            var url = `${this.getSiteSettings.embedProxy}/${this.getSiteSettings.embedProxyWatchEndpoint}/`
            var watch = new URL(this.url).searchParams.get("v");
            
            // eslint-disable-next-line
            const ytRegex = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/g;
            if (ytRegex.test(this.url)) {
                if(watch == null) {
                    watch = new URL(this.url).pathname
                    watch = watch.replace('/','')
                }
                url += watch;
            } 
            else {
                if(watch == null) {
                    watch = new URL(this.url).pathname
                    watch = watch.replace('/','') + watch
                }
                url += watch;
            }
            return url;
        },
        getToggle() {
            if(this.isDisabled)
                return 'icon-keyboard_arrow_up'
            else
                return 'icon-keyboard_arrow_down'
        }
    }
}
</script>
<style scoped>
.embed-wrapper {
    position: relative;
}
.embed-toggle {
    position: absolute;
    font-size: x-large;
    top: 0;
    right: 0; 
}
.embed-toggle:hover {
    cursor: pointer;
}
.video-embed {
  width: 95%;
  height: 20rem;
}
</style>