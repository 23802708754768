import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/i18n"
import startup from "@/startup/index.js";

const app = createApp(App)
    .use(store)
    .use(router)
    .use(i18n)

startup().then(() => {
    app.mount("#app")
    document.title = 'Varis.Social'
})


