<template>
    <div class="home-page">
        <timeline-panel
            name="defaultTimeline"
            ref="timeline"
            :timeline="localType"
            @switchTimelines="switchTimelines"
        ></timeline-panel>
    </div>
</template>

<script>
import TimelinePanel from '@/components/timeline-panel/timeline-panel.vue';

    export default {
        name: "TimelineView",
        components: {
            "timeline-panel": TimelinePanel,
        },
        props: {
            type: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                localType: "public"
            }
        },
        computed: {
            getDefaultTimeline: "Settings/getDefaultTimeline"
        },
        methods: {
            switchTimelines(value) {
                this.localType = value
                this.$refs['timeline'].switchTimelines();
            }
        },
        created() {
            if (this.type) {
                this.localType = this.type
            } 
            else if (this.getDefaultTimeline) {
                if (this.getDefaultTimeline != "home") {
                    this.localType = this.getDefaultTimeline;
                } else if (this.getDefaultTimeline == "home" && this.isUser) {
                    this.localType = this.getDefaultTimeline;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>