<template>
    <div class="user-tabs">
        <div 
            v-for="tab in tabs"
            class="tab"
            :class="isActive(tab)"
            :key="tab.value"
            @click="changeTab(tab)"
        >{{ tab.label }}</div>
    </div>
</template>
<script>
    export default {
        name: "user-tabs",
        props: {
            isPrivate: {
                type: Boolean,
                default: false
            },
            statistics: {
                type: Object,
                required: true
            },
            activeTab: {
                type: String,
                default: "posts"
            }
        },
        data() {
            return {
                tabs: [
                    { label: this.$t('page.user.posts'), value: 'posts', active: this.statistics.postCount > 0 ? true : false },
                    { label: this.$t('page.user.media'), value: 'media', active: this.statistics.postCount > 0 ? true : false },
                    { label: this.$t('page.user.likes'), value: 'likes', active: !this.isPrivate },
                    { label: this.$t('common.following'), value: 'following', active: this.statistics.followCount > 0 ? true : false },
                    { label: this.$t('common.followers'), value: 'followers', active: this.statistics.followerCount > 0 ? true : false },
                ]
            }
        },
        methods: {
            isActive(tab) {
                if (this.activeTab == tab.value) {
                    return "active"
                } else if (!tab.active) {
                    return "inactive"
                } else {
                    return ""
                }
            },
            changeTab(tab) {
                if (tab.active) {
                    this.$emit('changeTab',tab.value)
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
.user-tabs {
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    .tab {
        padding: 0.25rem 1rem;
        background-color: var(--background);
        box-shadow: 0px 5px 6px 2px var(--shadow);
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        &:hover {
            background-color: var(--panelHover);
            cursor: pointer;
        }
        &.active {
            background-color: var(--panelHover);
            border-style: solid;
            border-color: var(--thumbnailBorder);
            border-width: thin;
            border-bottom: none;
        }
        &.inactive {
            background-color: black;
            &:hover {
                background-color: black;
                cursor: default;
            }
        }
    }
}

</style>