<template>
    <div class="user-view" v-if="isLoaded">
        <user-info-panel
            class="header"
            :user="user"
            isUserPage
        />
        <user-tabs
            @changeTab="setActiveTab"
            :isPrivate="isPrivate"
            :statistics="user.statistics"
            :activeTab="activeTab"
        />
        <timeline-panel
            v-if="isPostTab"
            name="userTimeline"
            timeline="user"
            :userUrl="getId"
            :hasMedia="getHasMedia"
            :isLikes="isLikes"
            ref="timeline"
        />
        <div class="relationships" v-else>
            <sh-user-result
                v-for="user in getRelationships"
                :key="user.url"
                :user="user"
                @clicked="changeUser(user.url)"
            />
        </div>
    </div>
</template>
<script>
import TimelinePanel from '@/components/timeline-panel/timeline-panel.vue';
import UserInfoPanel from '@/components/user-info-panel/user-info-panel.vue';
import UserTabs from '@/components/user-tabs/user-tabs.vue';
import ShUserResult from '@/components/common/sh-user-result.vue';
import { getUser, getUserByHandle, getFollowers, getFollowing } from '@/services/api/user-api.service';
import { mapGetters } from 'vuex';
    export default {
        name: "UserView",
        components: {
            "user-info-panel": UserInfoPanel,
            "timeline-panel": TimelinePanel,
            "user-tabs": UserTabs,
            "sh-user-result": ShUserResult,
        },
        props: {
            currentTab: { 
                type: String,
                default: 'posts'
            }
        },
        data() {
            return {
                isLoaded: false,
                user: null,
                activeTab: 'posts',
                followers: [],
                following: []
            }
        },
        computed: {
            ...mapGetters({
                getUser: 'User/getUrl'
            }),
            getRelationships() {
                if (this.activeTab == "followers") {
                    return this.followers;
                } else {
                    return this.following;
                }
            },
            isPostTab() {
                if (this.activeTab == "media" || this.activeTab == "posts" || this.activeTab == "likes" ) {
                    return true ;
                } else {
                    return false;
                }
            },
            getHasMedia() {
                if (this.activeTab == "media") {
                    return true;
                } else {
                    return false;
                }
            },
            isLikes() {
                if (this.activeTab == "likes") {
                    return true;
                } else {
                    return false;
                }
            },
            isPrivate() {
                if (this.getUser == this.getId) {
                    return false;
                } else {
                    return this.user.isPrivate
                }
            },
            getId() {
                if (this.user == null) {
                    return this.$route.params.id;
                } else {
                    return this.user.url;
                }
            },
            getRoute() {
                return this.$route.params.id;
            }
        },
        methods: {
            setActiveTab(value) {
                if (this.activeTab == value) { return; }
                this.activeTab = value;

                if (this.activeTab == "media" || this.activeTab == "posts" || this.activeTab == "likes") {
                    this.fetchPosts();
                } else if (this.activeTab == "followers") {
                    this.getFollowers()
                } else if (this.activeTab == "following") {
                    this.getFollowing()
                }
            },
            fetchPosts() {
                this.$nextTick(() => this.$refs['timeline'].fetchPosts(true));
            },
            changeUser(value) {
                this.$router.push({
                    name: 'user',
                    params: {
                        id: value
                    }
                })
            },
            async getFollowers() {
                if (this.followers.length > 0) { return; }

                this.followers = await getFollowers(this.user.url);
            },
            async getFollowing() {
                if (this.following.length > 0) { return; }

                this.following = await getFollowing(this.user.url);
            },
            async loadPage() {
                this.followers = [];
                this.following = [];
                this.isLoaded = false;
                this.activeTab = this.currentTab;
                var url = this.getRoute;
                if (url) {
                    this.user = await getUserByHandle(url);
                    if (this.user == null) {
                        this.user = await getUser(url);
                    }
                }
                if (this.user != null) {
                    this.isLoaded = true;
                }
            }
        },
        async mounted() {
            await this.loadPage();
        },
        watch: {
            getRoute() {
                this.loadPage();
            }
        }
    }
</script>
<style lang="scss" scoped>
.user-view {
    .header {
        background-color: var(--background);
        border-radius: .5rem;
        width: 40rem;
        padding: 1rem;
        margin-bottom: 1rem;
        box-shadow: 0px 2px 3px 2px var(--shadow);
    }
    .relationships {
        border-bottom-width: thin;
        border-bottom-style: solid;
        background-color: var(--panelHover);
        box-shadow: 0px 9px 6px 2px var(--shadow);
        display: grid;
    }
}

</style>