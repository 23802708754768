<template>
    <div class="post-header">
        <div class="left">
            <sh-profile-picture
                :src="poster.url"
                :filename="poster.profilePicture"
                width=4.5
                rounding=.5
                rounded
                @click="showUser = !showUser"
            >
            </sh-profile-picture>
            <sh-floating-panel 
                :show="showUser" 
                instantFade
                clickable
                offsetY="-30"
                zIndex="500"
                @closed="showUser = false"
            >
                <user-info-panel
                    :user="poster"
                    isFloating
                    @close="showUser = false"
                />
            </sh-floating-panel>
        </div>
        <div class="middle">
            <div class="top">
                <post-title :body="poster.username"  :isExternal="isExternal" :externalTags="poster.tagObjects" spanClass="username"/>
                <router-link :to="{name: 'user', params: { id: poster.url } }"><span class="handle">@{{ poster.handle }}</span></router-link>
            </div>
            <div class="middle">
                <span class="reply"  v-if="reply">
                    <span class="icon-forward" @mouseenter="showReply = true"  @mouseleave="showReply = false">{{ $t('component.post.reply-to') }}</span>
                    <router-link :to="{name: 'user', params: { id: getRepliedUserId } }">
                            {{ getRepliedUser }}
                    </router-link>
                    <sh-floating-panel :show="showReply" maxContent @mouseover="showReply = true" @mouseleave="showReply = false" zIndex="400">
                        <post-wrapper
                            :post="replyPost"
                            :postUrl="reply.url"
                            @setReplyPost="setReplyPost"
                        />
                    </sh-floating-panel>
                </span>
            </div>
            <div class="bottom">
                <span class="edited" v-if="isEdited">{{  $t('component.post.last-edited', [this.getEditedDate]) }} </span>
                <post-title v-if="title"  :body="this.title" :isExternal="isExternal" :externalTags="externalTags" spanClass="title"/>
            </div>
        </div>
        <div class="right">
            <router-link :to="{name: 'thread', params: { id: postUrl }}"><span ref="timestamp" class="timestamp" :datetime="getDate"></span></router-link>
            <span class="visibility" :class="getIcon"></span>
            <span v-if="isFederated" class="icon-connection"></span>
        </div>
    </div>
</template>

<script>
import visibilities from "@/entities/Visibilities.js"
import { render, cancel, format } from "timeago.js"
import ShProfilePicture from "@/components/common/sh-profile-picture.vue";
import ShFloatingPanel from "@/components/common/sh-floating-panel.vue";
import UserInfoPanel from "@/components/user-info-panel/user-info-panel.vue";
import PostTitle from "@/components/post-title/post-title.vue";

import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";

    export default {
        name: "post-header",
        components: {
            "sh-profile-picture": ShProfilePicture,
            "sh-floating-panel": ShFloatingPanel,
            "user-info-panel": UserInfoPanel,
            "post-title": PostTitle,
            "post-wrapper": defineAsyncComponent(() => import("@/components/post-wrapper/post-wrapper.vue"))
        },
        props: {
            poster: {
                type: Object,
                required: true
            },
            postUrl: {
                type: String,
                required: true
            },
            createdDate: {
                type: String,
                required: true
            },
            reply: {
                type: Object,
                required: false
            },
            title: {
                type: String,
                required: false
            },
            visibility: {
                type: String,
                required: true
            },
            isEdited: {
                type: Boolean,
                default: false
            },
            isFederated: {
                type: Boolean,
                default: false
            },
            isExternal: {
                type: Boolean,
                default: false
            },
            editedDate: {
                type: String,
                required: true
            },
            externalTags: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                showUser: false,
                showReply: false,
                replyPost: null,
                editedTime: ""
            }
        },
        computed: {
            ...mapGetters({
                getLanguage: 'Settings/getLanguage'
            }),
            getEditedDate() {
                return format(`${this.editedDate}Z`, this.geetLanguage);
            },
            getDate() {
                return `${this.createdDate}Z`;
            },
            getRepliedUserId() {
                return this.reply.poster.url
            },
            getRepliedUser() {
                var username = this.reply.poster.handle
                return `@${username}`
            },
            getIcon() {
                var icon = visibilities.find(icon => icon.value.toLowerCase() == this.visibility.toLowerCase())
                if (icon == null) {
                    return 'icon-blocked'
                }
                return icon.icon;
            }
        },
        methods: {
            setReplyPost(post) {
                this.replyPost = post;
            },
            startRender() {
                render(this.$refs['timestamp'], this.getLanguage)
            },
            cancelRender() {
                cancel(this.$refs['timestamp'].$el)
            }
        },
        mounted() {
            this.startRender();
        },
        beforeUnmount() {
            this.cancelRender();
        },
        watch: {
            getLanguage() {  
                this.cancelRender();
                this.startRender();
            }
        }
    }
</script>

<style lang="scss">
    .post-header {
        --columnGap: 1rem;
        
        display: flex;
        column-gap: var(--columnGap);
        width: 100%;

        .left {
            position: relative;
        }
        .middle {
            text-align: left;

            .top {
                display: flex;
                column-gap: var(--columnGap);
                overflow-x: hidden;
                width: 22rem;
                .username {
                    overflow-x: hidden;
                    text-wrap: nowrap;
                }
            }

            .reply {
                font-size: small;
                position: relative;
                display: flex;
                column-gap: 0.4rem;
                &:hover {
                    cursor: pointer;
                }
            }

            .bottom {
                width: 100%;
                .edited {
                    font-size: x-small;
                }
            }
        }

        .right {
            column-gap: var(--columnGap);
            display: flex;
            margin-left: auto;
        }
    }
</style>