import store from '@/store/index.js'

const SHAKOU_REFRESH = "/api/user/refresh";

async function refreshOrThrowError(response, callbackFunction, params, message) {
    // If the error is 401 (authentication error) attempt a refresh and call the function
    // Otherwise display error message
    if (response.status == 401) {
        var refreshStatus = await refresh();
        if (refreshStatus) {
            callbackFunction(params);
        }
    }
    store.dispatch("Alert/addAlert", {
        label: message,
        expireTime: 5000,
        type: 'error'
    });
}

// eslint-disable-next-line
async function handleError(response, message, params) {
    if (response.status == 401) {
        var refreshStatus = await refresh();
            if (refreshStatus) {
                return;
            }
    }

    if (message != null) {
        store.dispatch("Alert/addAlert", {
            label: message,
            expireTime: 5000,
            type: 'error'
        });
    }
}

async function refresh() {
    var response = await fetch(url(SHAKOU_REFRESH), refreshHeaders());

    if (response.status != 200) {
        return false;
    }

    var data = await response.json();
    store.dispatch('Authentication/setTokens', data);

    return true;
}

function url(endpoint) {
    if(process.env.NODE_ENV == 'development') {
        return `${process.env.VUE_APP_API_URL}${endpoint}`
    } else {
        return `${endpoint}`
    }
}

function setHeaders (_method = "GET", _body, stringify = true) {
    if (_body) {
        if (stringify) {
            _body = JSON.stringify(_body);
        }    
    }

    return {
        method: _method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': (store.state.Authentication.token == null ? null : `Bearer ${store.state.Authentication.token}`)
        },
        body: _body
    }
}

function refreshHeaders () {
    var refresh = localStorage.getItem('refreshToken');
    if (refresh == null) {
        return;
    }
    return {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Sh-Tacos': refresh
        },
    }
}
export {
    refreshOrThrowError,
    refresh,
    handleError,
    url,
    setHeaders,
    refreshHeaders
}