<template>
    <div class="default-notification">
        <div class="unseen-notice" v-if="!notification.isSeen"></div>
        <sh-profile-picture
            :src="notification.initiator.url"
            :filename="notification.initiator.profilePicture"
            width="2"
            rounded
        />
        <div class="notification-right">
            <div class="notification-top">
                <span class="message" v-html="getMessage"></span>
                <router-link 
                    class="timestamp"
                    v-if="hasPost"
                    :to="{name: 'thread', params: { id: notification.post?.url }}"
                >
                    <span ref="timestamp"  :datetime="getDate"></span>
                </router-link>
                <span v-else class="timestamp" ref="timestamp"  :datetime="getDate"></span>
                <span class="icon" :class="arrowIcon" @click="isExpanded = !isExpanded"></span>
            </div>
            <div class="bottom">
                <div class="body-wrapper" :class="isExpanded ? 'expanded' : ''">
                    <div class="body-cover" :class="getBodyCoverClass"></div>
                    <notification-body
                        v-if="notification.post"
                        :post="notification.post"
                        
                    />

                </div>                    
                <sh-reactions
                    v-if="isReply"
                    :postUrl="notification.post.url"
                    :interactions="notification.post.interactions"
                    @updatePost="updateNotifications"
                />
                <post-footer
                    v-if="isReply"
                    :posterUrl="notification.post.poster.url"
                    :postUrl="notification.post.url"
                    :hasMedia="notification.post.hasMedia"
                    :mentions="notification.post.mentionedUsers"
                    :reply="notification.post.reply"
                    :replies="notification.post.replies"
                    :interactions="notification.post.interactions"
                    :isMuted="notification.post.isMuted"
                    :isBookmarked="notification.post.isBookmarked"
                    :isThread="isThread"
                    @toggleReplyForm="toggleReplyForm"
                    @updatePost="updateNotifications"
                    @downloadImages="downloadImages"
                    @openThread="$emit('openThread')"
                    @toggleMute="$emit('toggleMute')"
                    @toggleBookmark="$emit('toggleBookmark')"
                />
                <form-panel
                    v-if="replyFormVisible"
                    @submitted="toggleReplyForm"
                    :replyUrl="notification.post.url"
                    :replyHandles="gatherReplyHandles"
                />
            </div>
        </div>
    </div>
</template>
<script>
import ShProfilePicture from "@/components/common/sh-profile-picture.vue"
import FormPanel from "@/components/form-panel/form-panel.vue"
import NotificationBody from "@/components/notification-body/notification-body.vue"
import PostFooter from "@/components/post-footer/post-footer.vue"
import ShReactions from "@/components/common/sh-reactions.vue";

import { SHAKOU_EMOJI_GET_FILE } from '@/services/api/emoji-api.service';
import { url } from '@/services/api/api.service';
import { render, cancel } from "timeago.js"
import { mapGetters, mapActions } from "vuex";
    export default {
        name: "default-notification",
        components: {
            "sh-profile-picture": ShProfilePicture,
            "notification-body": NotificationBody,
            "form-panel": FormPanel,
            "post-footer": PostFooter,
            "sh-reactions": ShReactions,
        },
        props: {
            notification: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                isExpanded : false,
                replyFormVisible: false
            }
        },
        computed: {
            ...mapGetters({
                getLanguage: 'Settings/getLanguage',
                isUser: 'User/getUrl',
            }),
            arrowIcon() {
                if(this.isExpanded) {
                    return 'icon-keyboard_arrow_up'
                } else {
                    return 'icon-keyboard_arrow_down'
                }
            },
            formatInteractorName() {
                return `<a href="/user/${this.notification.initiator.url}">@${this.notification.initiator.handle}</a>`
            },
            formatEmoji() {
                return `<img class="notification-emoji" title="${this.notification.emoji.name}" src="${url(SHAKOU_EMOJI_GET_FILE(this.notification.emoji.group, this.notification.emoji.name))}">`
            },
            getDate() {
                return new Date(`${this.notification.createdDate}Z`)
            },
            getMessage() {
                switch(this.notification.type.toLowerCase()) {
                    case "like": return this.$t('component.notification.type.like', [this.formatInteractorName]);
                    case "repost": return this.$t('component.notification.type.repost', [this.formatInteractorName]);
                    case "react": return this.$t('component.notification.type.react', [this.formatInteractorName, this.formatEmoji]);
                    case "mention": return this.$t('component.notification.type.mention', [this.formatInteractorName]);
                    case "post": return this.$t('component.notification.type.post', [this.formatInteractorName]);
                    case "response": return this.$t('component.notification.type.response', [this.formatInteractorName]);
                    case "follow": return this.$t('component.notification.type.follow', [this.formatInteractorName]);
                    case "block": return this.$t('component.notification.type.block', [this.formatInteractorName]);
                    default: return this.$t('component.notificaton.type.undefined', [this.formatInteractorName])
                }
            },
            hasPost() {
                switch(this.notification.type.toLowerCase()) {
                    case "like":
                    case "repost":
                    case "react":
                    case "mention":
                    case "post":
                    case "response":
                        return true;
                    case "follow":
                    case "block":
                    default: 
                        return false;
                }
            },
            isReply() {
                if (this.notification.type.toLowerCase() == 'mention' || this.notification.type.toLowerCase() == 'post') {
                    return true;
                } else {
                    return false;
                }
            },
            isThread() {
                if (this.notification.post.reply || this.notification.post.replies.length > 0) {
                    return true;
                } else {
                    return false;
                }
            },
            gatherReplyHandles() {
                var handles = []
                if (this.notification.post.poster.url != this.isUser) {
                    handles.push(this.notification.post.poster.handle);
                }

                if (this.notification.post.mentionedUsers) {
                    this.notification.post.mentionedUsers.forEach((e) => {
                        if (e.url != this.isUser) {
                            handles.push(e.handle);
                        }
                    });
                }

                return handles;
            },
            getBodyCoverClass() {
                var wrapperClass = ""
                
                var notificationType = this.notification.type.toLowerCase();
                if (notificationType == "mention" || notificationType == "post"  || notificationType == "response") {
                    wrapperClass += "non-user-post"
                }

                return wrapperClass;
            }
        },
        methods: {
            ...mapActions({
                updateNotifications: 'Notifications/updateNotification'
            }),
            toggleReplyForm() {
                this.replyFormVisible = !this.replyFormVisible
            },
            startRender() {
                render(this.$refs['timestamp'], 'shortform')
            },
            cancelRender() {
                cancel(this.$refs['timestamp'])
            }
        },
        mounted() {
            this.startRender();
        },
        beforeUnmount() {
            this.cancelRender();
        },
    }
</script>
<style lang="scss">
img.notification-emoji {
    width: 2rem !important;
}
.default-notification { 
    position: relative;
    display: flex;
    column-gap: 1rem;
    border-color: var(--postBorder);
    border-style: solid;
    border-width: thin;
    border-radius: .25rem;
    padding: .5rem;
    
    .unseen-notice {
        position: absolute; 
        border-bottom: 30px solid transparent;
        border-right: 10px solid transparent;
        border-left: 30px solid var(--unseenNotification);
        border-top-left-radius: 0.2rem;
        top: 0;
        left: 0;
    }
    .notification-right {
        width: 100%;
        display: grid;
        row-gap: 0.5rem;
        .notification-top {
            font-size: smaller;
            display: flex;
            column-gap: 1rem;
            .timestamp {
                margin-left: auto;
            }
            .icon {
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .bottom {
            .body-wrapper {
                position: relative;
                max-height: 5rem;
                margin-bottom: .5rem;
                overflow: hidden;
                .body-cover {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: var(--notificationCover);
                    &.non-user-post {
                        background: linear-gradient(transparent,var(--notificationResponseCover));
                    }
                }
                &.expanded {
                    max-height: 100vw;
                    background: none;
                    .body-cover {
                        height: 0;
                    }
                }
            }
        }
    }
}
</style>