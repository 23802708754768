<template>
    <div class="navigation-bar" :class="getClass">
        <router-link 
            class="favicon-link"
            :to="{ name: 'home' }"
        >
            <sh-filled-button
                class="favicon-button"
                small
                noBackground
                padding="0"
                customSize="2"
                borderRadius="2"
                :image="getFavicon"
            >
            </sh-filled-button>
        </router-link> 
        <div class="buttons">
            <sh-single-line-text-input
                v-if="isSearching"
                :placeholder="$t('common.search')"
                v-model="searchQuery"
            />
            <sh-icon-button
                small
                shadow
                @click="toggleSearch"
                icon="icon-search"
            />
            <sh-icon-button
                small
                shadow
                @click="$emit('showSettings')"
                icon="icon-cog"
            />
            <sh-icon-button
                v-if="getPermission('Admin')"
                small
                shadow
                @click="$emit('showSettings')"
                icon="icon-gauge"
            />
            <sh-icon-button
                v-if="isUser"
                small
                shadow
                @click="logout"
                icon="icon-exit"
            />
            <router-link 
                v-else
                :to="{ name: 'login' }"
            >
                <sh-icon-button
                    small
                    shadow
                    icon="icon-enter"
                />
            </router-link> 
        </div>
        <mobile-navigation-bar/>
    </div>
</template>

<script>
import ShIconButton from '../common/sh-icon-button.vue'
import ShFilledButton from '../common/sh-filled-button.vue'
import ShSingleLineTextInput from '../common/sh-single-line-text-input.vue'
import MobileNavigationBar from '../mobile-navigation-bar/mobile-navigation-bar.vue'
import favicon from "@/assets/logo.png"
import { logoutUser } from '@/services/api/user-api.service'
import { mapGetters, mapActions } from 'vuex'
    export default {
        name: "navigation-bar",
        components: {
            'sh-icon-button': ShIconButton,
            'sh-filled-button': ShFilledButton,
            'sh-single-line-text-input': ShSingleLineTextInput,
            "mobile-navigation-bar": MobileNavigationBar
        },  
        data() {
            return {
                isFixed: false,
                isSearching: false,
                searchQuery: null
            }
        },
        computed: {
            ...mapGetters({
                isUser: 'User/getUrl',
                getPermission: 'Authentication/getPermission'
            }),
            getClass() {
                if (this.isFixed) {
                    return 'fixed'
                } else {
                    return ''
                }
            },
            getFavicon() {
                return favicon;
            }
        },
        methods:{
            ...mapActions({
                clearTokens: 'Authentication/clearTokens'
            }),
            logout() {
                logoutUser(),
                this.clearTokens()
            },
            handleKeyPress(e) {
                if (e.key == "Enter") {
                    if (this.searchQuery != null) {
                        this.$router.push({
                            name: 'search',
                            query: {
                                q: this.searchQuery
                            }
                        });
                    }
                    this.toggleSearch();
                }
            },
            toggleSearch() {
                this.isSearching = !this.isSearching;

                if (this.isSearching) {
                    window.addEventListener('keydown', this.handleKeyPress);
                } else {
                    window.removeEventListener('keydown', this.handleKeyPress);
                    this.searchQuery = null;
                }
            }
        }
    }
</script>

<style lang="scss">
.navigation-bar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    align-items: center;
    display: flex;
    column-gap: .2rem;
    justify-content: center;
    padding: .5rem;
    background-color: var(--navbarColour);
    box-shadow: 0px 0px 11px 1px var(--shadow);
    height: 1.5rem;
    .favicon-link {
        z-index: 20;
    }
    .favicon-button {
        box-shadow: 0px 0px 7px 3px var(--shadow);
        &:hover {
            box-shadow: 0px 0px 7px 3px var(--buttonHover);
        }
    }
    .buttons {
        align-items: center;
        column-gap: 0.3rem;
        display: flex;
        position: absolute;

        right: 20vw;
        @media(max-width: 1279px) {
            display: none;
        }
    }
    .mobile-buttons {
        @media(min-width: 1279px) {
            display: none;
        }
    }
}
</style>